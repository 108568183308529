import EditIcon from "@mui/icons-material/Edit";
import HideImageIcon from "@mui/icons-material/HideImage";
import { TablePagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px", // Adjust the maximum width as needed
    width: "100%",
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");

export default function OneUser() {
  let params = useParams();
  let id = params.id;
  const [data, setData] = useState("");
  const [payment, setPayment] = useState([]);
  const [callHistory, setCallHistory] = useState([]);
  const getUsers = async () => {
    axios

      .get(api + `/findOneUser/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log("Result", res.data);
        setData(res?.data?.getOneUser);
        setPayment(res?.data?.getOneUser?.payment_history);
        setCallHistory(res?.data?.getOneUser?.userCallhistorys);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //CallHistory

  const [callpage, setcallPage] = useState(0);
  const [callrowsPerPage, setcallRowsPerPage] = useState(5);
  const handlecallChangePage = (event, newPage) => {
    setcallPage(newPage);
  };

  const handlecallChangeRowsPerPage = (event) => {
    setcallRowsPerPage(event.target.value);
    setcallPage(0);
  };

  //Followers

  const [followpage, setfollowPage] = useState(0);
  const [followrowsPerPage, setfollowRowsPerPage] = useState(5);
  const handlefollowChangePage = (event, newPage) => {
    setfollowPage(newPage);
  };

  const handlefollowChangeRowsPerPage = (event) => {
    setfollowRowsPerPage(event.target.value);
    setfollowPage(0);
  };

  //Following

  const [followingpage, setfollowingPage] = useState(0);
  const [followingrowsPerPage, setfollowingRowsPerPage] = useState(5);

  const handlefollowingChangePage = (event, newPage) => {
    setfollowingPage(newPage);
  };

  const handlefollowingChangeRowsPerPage = (event) => {
    setfollowingRowsPerPage(event.target.value);
    setfollowingPage(0);
  };

  //blocked

  const [blockpage, setBlockPage] = useState(0);
  const [blockrowsPerPage, setBlockRowsPerPage] = useState(5);
  const handleBlockChangePage = (event, newPage) => {
    setBlockPage(newPage);
  };

  const handleBlockChangeRowsPerPage = (event) => {
    setBlockRowsPerPage(event.target.value);
    setBlockPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
  const [editImageIsOpen, setImageIsOpen] = useState(false);
  const handleOpenModal = () => {
    setEditUserModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setEditUserModalIsOpen(false);
  };

  const handleChangeImageIsOpen = () => {
    setImageIsOpen(true);
  };

  const handleCloseImageIsOpen = () => {
    setImageIsOpen(false);
  };

  const [formData, setFormData] = useState({
    fullName: data?.fullName,
    gender: data?.gender,
    dateOfBirth: data?.dateOfBirth,
    phone: data?.phone,
    age: data?.age,
    city: data?.city,
  });

  const [newImage, setNewImage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    axios

      .put(
        api + `/updateUserData/${id}`,
        {
          fullName: formData.fullName,
          dateOfBirth: formData.dateOfBirth,
          age: formData.age,
          gender: formData.gender,
          phone: formData.phone,
          city: formData.city,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.log(res.data);
        setEditUserModalIsOpen(false);
        getUsers();
        toast.success("User Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("User Not Updated");
      });
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("image", newImage);

    axios
      .put(api + `/adminUpdateUserPic/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data", // Set the content type
        },
      })
      .then((res) => {
        console.log(res.data);
        setImageIsOpen(false);
        getUsers();
        toast.success("User Image Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("User Image Not Updated");
      });
  };
  const handleRemoveImage = (e) => {
    e.preventDefault();

    axios
      .put(
        api + `/admin/adminRemoveUserPic/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setImageIsOpen(false);
        getUsers();
        toast.success("User Image Removed Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("User Image Not Removed");
      });
  };

  const indianCities = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Surat",
    "Bhopal",
    "Indore",
    "Thane",
    "Coimbatore",
    "Kochi",
    "Vadodara",
    "Visakhapatnam",
    "Chandigarh",
    "Patna",
    "Ludhiana",
    "Agra",
    "Varanasi",
    "Meerut",
    "Rajkot",
    "Jamshedpur",
    "Amritsar",
    "Allahabad",
  ];

  return (
    <LayOut>
      <div className="header">
        <Header title="User Details" />
        <div
          style={{
            cursor: "pointer",
            width: "95%",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "30px",
          }}
        >
          <EditIcon
            onClick={handleOpenModal}
            style={{
              fontSize: "30px",

              padding: "5px",
            }}
          />

          <HideImageIcon
            onClick={handleChangeImageIsOpen}
            style={{
              fontSize: "30px",

              padding: "5px",
            }}
          />
        </div>
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex">
            <img src={data?.imageUrl} className="oneUserImg" />
            <div className="tableBox">
              <table className="oneUserTable">
                <tr>
                  <th>Id</th>
                  <td>{data?._id}</td>
                </tr>
                <tr>
                  <th>User Id</th>
                  <td>{data?.userId}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{data?.fullName}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{data?.gender}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{data?.dateOfBirth?.substring(0, 10)}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{data?.phone}</td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>{data?.age}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{data?.city}</td>
                </tr>
              </table>

              <table className="oneUserTable">
                <tr>
                  <th>Status</th>
                  <td>{data?.status}</td>
                </tr>
                <tr>
                  <th>SpentCoins</th>
                  <td>{data?.spentCoins}</td>
                </tr>
                <tr>
                  <th>Total Balance</th>
                  <td>{data?.total_coins}</td>
                </tr>
                <tr>
                  <th>Block</th>
                  <td>{data?.block?.length || "0"}</td>
                </tr>
                <tr>
                  <th>Followers</th>
                  <td>{data?.followers?.length || "0"}</td>
                </tr>
                <tr>
                  <th>Following</th>
                  <td>{data?.followings?.length || "0"}</td>
                </tr>
                <tr>
                  <th>Likes</th>
                  <td>{data?.likes?.length || "0"}</td>
                </tr>
              </table>
            </div>
          </div>

          <h4 className="paymentHead">Payment History</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>TxnId</th>
                <th>ResCode</th>
                <th>TxnRef</th>
                <th>Price</th>
                <th>Coins</th>
                <th>Status</th>
                <th>Date/Time</th>
              </tr>
            </thead>
            <tbody>
              {payment
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  const serialNumber = index + 1 + page * rowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td title={item?.txnId}>
                        {item?.txnId?.substring(0, 5) + "..."}
                      </td>
                      <td>{item?.resCode}</td>
                      <td title={item?.txnRef}>
                        {item?.txnRef?.substring(0, 5) + "..."}
                      </td>
                      <td>{item?.price}</td>
                      <td>{item?.coins}</td>
                      <td>{item?.status}</td>
                      <td>{item?.time?.split("T")[0]}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={payment.length} // Use the correct state variable
            rowsPerPage={rowsPerPage} // Use the correct state variable
            page={page} // Use the correct state variable
            onPageChange={handleChangePage} // Use the correct function
            onRowsPerPageChange={handleChangeRowsPerPage} // Use the correct function
          />

          <h4 className="paymentHead">User Call History</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>ID</th>
                <th>Video Coins</th>
                <th>Sticker Coins</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {callHistory
                ?.slice(
                  callpage * callrowsPerPage,
                  callpage * callrowsPerPage + callrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber = index + 1 + callpage * callrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.targetId?.FirstName}</td>
                      <td>{item?.targetId?._id}</td>
                      <td>{item?.targetId?.videoUserCoins}</td>
                      <td>{item?.targetId?.stickerCoins}</td>
                      <td>{item?.targetId?.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={callHistory?.length}
              rowsPerPage={callrowsPerPage}
              page={callpage}
              onPageChange={handlecallChangePage}
              onRowsPerPageChange={handlecallChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Followers List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.followers
                ?.slice(
                  followpage * followrowsPerPage,
                  followpage * followrowsPerPage + followrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber =
                    index + 1 + followpage * followrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.FirstName + " " + item.LastName}</td>
                      <td>{item?._id}</td>
                      <td>{item?.email}</td>
                      <td>{item?.number}</td>
                      <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item?.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={data?.followers?.length}
              rowsPerPage={followrowsPerPage}
              page={followpage}
              onPageChange={handlefollowChangePage}
              onRowsPerPageChange={handlefollowChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Following List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.followings
                ?.slice(
                  followingpage * followingrowsPerPage,
                  followingpage * followingrowsPerPage + followingrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber =
                    index + 1 + followingpage * followingrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item.FirstName + " " + item.LastName}</td>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      <td>{item.number}</td>
                      <td>{item.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20]}
            component="div"
            count={data?.followings?.length || 0}
            rowsPerPage={followingrowsPerPage}
            page={followingpage}
            onPageChange={handlefollowingChangePage}
            onRowsPerPageChange={handlefollowingChangeRowsPerPage}
          />

          <h4 className="paymentHead">Blocked List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.block
                ?.slice(
                  blockpage * blockrowsPerPage,
                  blockpage * blockrowsPerPage + blockrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber = index + 1 + blockpage * blockrowsPerPage;

                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item.FirstName + " " + item.LastName}</td>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      <td>{item.number}</td>
                      <td>{item.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={data?.block?.length}
              rowsPerPage={blockrowsPerPage}
              page={blockpage}
              onPageChange={handleBlockChangePage}
              onRowsPerPageChange={handleBlockChangeRowsPerPage}
            />
          </div>
        </div>
      </div>

      <Modal
        isOpen={editUserModalIsOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <button
          className="colseModal"
          onClick={() => setEditUserModalIsOpen(false)}
        >
          X
        </button>
        <div className="modalHeader">
          <h2>Edit User</h2>
        </div>

        <div className="modalBody">
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullName" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <select
                className="form-control"
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={(e) =>
                  setFormData({ ...formData, gender: e.target.value })
                }
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={(e) =>
                  setFormData({ ...formData, dateOfBirth: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                maxLength="10"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="age" className="form-label">
                Age
              </label>
              <input
                type="number"
                className="form-control"
                id="age"
                name="age"
                value={formData.age}
                onChange={(e) =>
                  setFormData({ ...formData, age: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <select
                className="form-control"
                id="city"
                name="city"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              >
                <option value="">Select City</option>
                {indianCities.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>

            <button type="submit" className="form-button">
              Submit
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={editImageIsOpen}
        onRequestClose={handleCloseImageIsOpen}
        style={customStyles}
      >
        <button className="colseModal" onClick={() => setImageIsOpen(false)}>
          X
        </button>
        <div className="modalHeader">
          <h2>Edit User</h2>
        </div>

        <div className="modalBody">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="image"
            onChange={(e) => setNewImage(e.target.files[0])}
          />
        </div>
        <button
          onClick={handleImageSubmit}
          type="submit"
          className="form-button"
          style={{
            marginRight: "20px",
          }}
        >
          Submit
        </button>
        <button
          onClick={handleRemoveImage}
          type="submit"
          className="form-button"
        >
          Remove Image
        </button>
      </Modal>
    </LayOut>
  );
}
