import axios from "axios";
import { City, Country, State } from "country-state-city";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import PasswordInput from "../components/PasswordInput";
import Spinner from "../components/Spinner";
import api from "../components/api";

// Import Interfaces`

const AddLeader = () => {
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [stateCode, setStateCode] = useState("");

  const [data, setData] = useState({
    leaderName: "",
    mobileNumber: "",
    gender: "",
    email: "",
    idProof: "",
    groupName: "",
    country: "",
    city: "",
    state: "",
    pin: "",
    password: "",
    conformPassword: "",
  });

  const re = /^[0-9\b]+$/;
  const handleLeaderName = (e) => {
    setData({ ...data, leaderName: e.target.value });
  };
  const handleMobile = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters and limit the length to 10 characters

    value = value.replace(/\D/g, "").slice(0, 10);

    // Update the state with the sanitized value

    setData({ ...data, mobileNumber: value });
  };

  const handleGender = (e) => {
    setData({ ...data, gender: e.target.value });
  };
  const handleEmail = (e) => {
    setData({ ...data, email: e.target.value });
  };
  const handleIdproof = (e) => {
    let value = e.target.value;

    // Remove non-numeric characters and limit the length to 16 characters
    value = value.replace(/\D/g, "").slice(0, 16);

    // Update the state with the sanitized value
    setData({ ...data, idProof: value });
  };

  const handleGroupName = (e) => {
    setData({ ...data, groupName: e.target.value });
  };
  const handleCountry = (e) => {
    const country = JSON.parse(e.target.value);
    setData({ ...data, country: country.name });
    setCountryCode(country.isoCode);
  };

  const handleState = (e) => {
    const state = JSON.parse(e.target.value);
    setData({ ...data, state: state.name });
    setStateCode(state.isoCode);
  };

  const handleCity = (e) => {
    const city = JSON.parse(e.target.value);
    setData({ ...data, city: city.name });
  };
  const handlePin = (e) => {
    if (e.target.value === "" || re.test(e.target.value)) {
      const inputValue = e.target.value.slice(0, 6); // Splice the value to keep only the first digit
      setData({ ...data, pin: inputValue });
    }
  };
  const handlePassword = (e) => {
    setData({ ...data, password: e.target.value });
  };

  const handleConfirmPassword = (e) => {
    setData({ ...data, conformPassword: e.target.value });
  };

  const handleAddLeader = () => {
    if (data.leaderName === "") {
      toast.error("Please Enter Leader Name");
      return;
    }
    if (data.mobileNumber === "") {
      toast.error("Please Enter Mobile Number");
      return;
    }

    if (data.mobileNumber.length < 10) {
      toast.error("Please Enter Valid Mobile Number");
      return;
    }

    if (data.mobileNumber.length > 10) {
      toast.error("Please Enter Valid Mobile Number");
      return;
    }

    if (data.conformPassword === "") {
      toast.error("Please Enter Confirm Password");
      return;
    }

    if (data.password === "") {
      toast.error("Please Enter Password");
      return;
    }

    if (data.password !== data.conformPassword) {
      toast.error("Password and Confirm Password Not Match");
      return;
    }

    setLoad(true);
    axios
      .post(api + "/admin/addleader", data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        toast.success("Leader Added Successfully");
        setTimeout(() => {
          navigate("/leaderList");
        }, [4000]);
        setLoad(false);
      })
      .catch((e) => {
        console.log("e", e);
        toast.error(e.response.data);
        setLoad(false);
      });
  };

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Add Leader" />
      </div>
      <div className="addbannermain">
        <div style={{ display: "flex", marginBottom: "20px" }}>
          <div style={{ width: "50%" }}>
            <div className="addinput " style={{ width: "80%" }}>
              Leader Name
              <input
                className="addinputtext"
                id="nameid"
                type="text"
                onChange={handleLeaderName}
                value={data.leaderName}
              />
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              Mobile Number
              <input
                className="addinputtext"
                id="nameid"
                type="number"
                maxLength="10"
                onChange={handleMobile}
                value={data.mobileNumber}
              />
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              Gender
              <select
                className="addinputtext"
                onChange={handleGender}
                value={data.gender}
              >
                <option selected>Select Gender</option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              Email
              <input
                className="addinputtext"
                id="nameid"
                type="email"
                value={data.email}
                onChange={handleEmail}
              />
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              IdProof
              <input
                className="addinputtext"
                id="nameid"
                type="number"
                value={data.idProof}
                onChange={handleIdproof}
              />
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              Pin
              <input
                className="addinputtext"
                id="nameid"
                type="text"
                onChange={handlePin}
                value={data.pin}
              />
            </div>
          </div>

          <div style={{ width: "50%" }}>
            <div className="addinput " style={{ width: "80%" }}>
              Group Name
              <input
                className="addinputtext"
                id="nameid"
                type="text"
                onChange={handleGroupName}
              />
            </div>

            <div className="addinput " style={{ width: "80%" }}>
              Country
              <select className="addinputtext" onChange={handleCountry}>
                <option>Select Country</option>
                {Country.getAllCountries().map((country) => {
                  return (
                    <option value={JSON.stringify(country)}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="addinput " style={{ width: "80%" }}>
              State
              <select className="addinputtext" onChange={handleState}>
                <option>Select State</option>
                {countryCode ? (
                  State.getStatesOfCountry(`${countryCode}`).map((state) => {
                    return (
                      <option value={JSON.stringify(state)}>
                        {state.name}
                      </option>
                    );
                  })
                ) : (
                  <option disabled>first select country </option>
                )}
              </select>
            </div>

            <div className="addinput " style={{ width: "80%" }}>
              City
              <select className="addinputtext" onChange={handleCity}>
                <option>Select City</option>
                {stateCode ? (
                  City.getCitiesOfState(`${countryCode}`, `${stateCode}`).map(
                    (city) => {
                      return (
                        <option value={JSON.stringify(city)}>
                          {city.name}
                        </option>
                      );
                    }
                  )
                ) : (
                  <option disabled>first select state </option>
                )}
              </select>
            </div>
            <div className="addinput " style={{ width: "80%" }}>
              Password
              <PasswordInput
                onChangeFun={handlePassword}
                value={data.password}
              />
            </div>

            <div className="addinput " style={{ width: "80%" }}>
              Confirm Password
              <PasswordInput
                onChangeFun={handleConfirmPassword}
                value={data.conformPassword}
              />
            </div>
          </div>
        </div>
        <button
          className="submitbutton"
          type="submit"
          value="Submit"
          onClick={handleAddLeader}
          style={{ marginLeft: "2.5%" }}
        >
          SAVE
        </button>
      </div>
      <ToastContainer />
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default AddLeader;
