import React, { useState } from "react";
import LayOut from "../components/Layout";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../components/api";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";

const Addemoji = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [load, setLoad] = useState(false);
  const handleName = (e) => {
    setName(e.target.value);
  };

  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  let navigate = useNavigate();

  //AddEmojiApi---------------------------------------------------------------//
  const handleAddEmoji = () => {
    setLoad(true);
    axios
      .post(
        api + `/admin/addEmoji`,
        { name: name, coins: price },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        setTimeout(() => {
          navigate(`/uploadEmojiImage/${res.data.newEmoji._id}`);
        }, 3000);
      })
      .catch((e) => {
        setLoad(false);
        toast.error("Emoji Not Added");
        console.log("error", e);
      });
  };

  const handleCancelBtn = () => {
    setName("");
    setPrice("");
  };

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Add Emoji" />
      </div>
      <div className="addbannermain">
        <div className="addinput">
          Emoji Name
          <input
            className="addinputtext smallInput"
            type="text"
            value={name}
            onChange={handleName}
          />
        </div>

        <div className="addinput">
          Emoji Price
          <input
            className="addinputtext smallInput"
            type="number"
            value={price}
            onChange={handlePrice}
          />
        </div>
        <div className="savecancelbutton">
          <button
            className="accptBtn"
            type="submit"
            value="Submit"
            onClick={() => handleAddEmoji()}
          >
            SAVE
          </button>
          <button
            className="rejectBtn"
            type="submit"
            value="Submit"
            onClick={() => handleCancelBtn()}
          >
            CANCEL
          </button>
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </LayOut>
  );
};

export default Addemoji;
