import { TablePagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

export default function UserFeedback() {
  let params = useParams();
  let id = params.id;

  const [data, setData] = useState([]);
  const [imageurl, setImageurl] = useState("");
  const [imageModal, setImageModal] = useState(false);

  const handleImage = (val) => {
    setImageModal(true);
    setImageurl(val);
  };

  const getUsers = async () => {
    try {
      const response = await axios.get(api + `/getAllUserFeedback`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      // setData(response.data.result);
      const filterdata = response.data.result.filter(
        (item) => item.userId?._id
      );
      setData(filterdata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  console.log("data", data);

  return (
    <LayOut>
      <div className="header">
        <Header title="User Feedback" />
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex"></div>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>User Id</th>
                <th>Full Name</th>
                <th>Email</th>
                <th>Description</th>
                <th>Feedback Image</th>
                <th>Gender</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {data
                ?.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((item, index) => {
                  const serialNumber = index + 1 + page * rowsPerPage;
                  return (
                    <tr key={item?._id}>
                      <td>{serialNumber}</td>
                      <td title={item?.userId?._id}>
                        {item?.userId?._id?.substring(0, 8) + "..."}
                      </td>
                      <td>{item?.userId?.fullName}</td>
                      <td> {item?.userId?.email}</td>
                      <td>{item?.name}</td>
                      <td onClick={() => handleImage(item?.image)}>
                        <i className="fa-solid fa-eye"></i>
                      </td>
                      <td>{item?.userId?.gender}</td>
                      <td>{item?.createdAt?.substring(0, 10)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign Userpagination">
            <TablePagination
              rowsPerPageOptions={[2, 5, 10, 20]}
              component="div"
              rowsPerPage={rowsPerPage}
              page={page}
              count={data.length}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>
      </div>
      <Modal isOpen={imageModal} style={customStyles}>
        <button className="colseModal" onClick={() => setImageModal(false)}>
          X
        </button>
        <img src={imageurl} className="bannerImage" alt="ReportImage"></img>
        {console.log("imageurl", imageurl)}
      </Modal>
    </LayOut>
  );
}
