import { TablePagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import api from "../components/api";

const Hostearnings = () => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState([]);
  const [stickerBalance, setStickerBalance] = useState([]);

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/getAcceptHostUserBalance", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setPosts(res.data.hostuserBalance);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  const getStickerBalance = async () => {
    setLoad(true);
    await axios
      .get(api + "/getAllStickrbalance", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setStickerBalance(res.data.stickerBalance);
        console.log(res.data);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  useEffect(() => {
    getPost();
    getStickerBalance();
  }, []);

  const hostEarnTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LayOut>
      <div className="header">
        <Header title="Host Earnings" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={hostEarnTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <input
              type="search"
              className="SearchInput"
              placeholder="Search By Name or User Id..."
              onChange={handleSearchValue}
            ></input>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={hostEarnTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Host ID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Charge/min</th>
                <th>Sticker</th>
                <th>Video Coins</th>
                <th>Total Earnings </th>
              </tr>
            </thead>
            <tbody>
              {posts
                ?.filter((item) => {
                  let Name = item.FirstName + " " + item.LastName;

                  if (!searchValue) {
                    return item;
                  } else if (
                    Name.toLowerCase().includes(searchValue.toLowerCase())
                  ) {
                    return item;
                  } else if (item.userId.includes(searchValue)) {
                    return item;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  {
                    console.log(item);
                  }
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{item?._id}</td>
                      <td>{item?.FirstName + " " + item?.LastName}</td>
                      <td>{item?.number}</td>
                      <td>{item?.email}</td>
                      <td>{item?.hostuser_fees}</td>
                      <td>{item?.stickerCoins}</td>
                      <td>{item?.videoUserCoins}</td>
                      <td>{item?.host_balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default Hostearnings;
