import EditIcon from "@mui/icons-material/Edit";
import HideImageIcon from "@mui/icons-material/HideImage";
import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import api from "../components/api";

import { TablePagination } from "@mui/material";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px", // Adjust the maximum width as needed
    width: "100%",
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  },
};

Modal.setAppElement("#root");

export default function HostAction() {
  let params = useParams();
  let id = params.id;

  const [host, setHost] = useState("");

  const getHost = async () => {
    axios

      .get(api + `/findOneHostrequest/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log("Host", res.data);
        setHost(res.data.getOneHost);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getHost();
  }, []);

  const [editUserModalIsOpen, setEditUserModalIsOpen] = useState(false);
  const [editImageIsOpen, setImageIsOpen] = useState(false);

  const handleOpenModal = () => {
    setEditUserModalIsOpen(true);
  };

  const handleCloseModal = () => {
    setEditUserModalIsOpen(false);
  };

  const handleChangeImageIsOpen = () => {
    setImageIsOpen(true);
  };

  const handleCloseImageIsOpen = () => {
    setImageIsOpen(false);
  };

  const [newImage, setNewImage] = useState("");

  const [formData, setFormData] = useState({
    FirstName: host?.FirstName,
    LastName: host?.LastName,
    Gender: host?.Gender,
    dateOfBirth: host?.dateOfBirth,
    phone: host?.number,
    age: host?.age,
    city: host?.city,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios

      .put(
        api + `/adminUpdateHostData/${id}`,
        {
          FirstName: formData.FirstName,
          LastName: formData.LastName,
          Gender: formData.Gender,
          dateOfBirth: formData.dateOfBirth,
          number: formData.phone,
          age: formData.age,
          city: formData.city,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.log(res.data);
        setEditUserModalIsOpen(false);
        getHost();
        toast.success("Host Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleImageSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", newImage);
    axios
      .put(api + `/adminUpdateHostPic/${id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        console.log(res.data);
        setImageIsOpen(false);
        getHost();
        toast.success("Host Image Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleRemoveImage = (e) => {
    e.preventDefault();
    axios
      .put(
        api + `/admin/adminRemoveHostPic/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        console.log(res.data);
        setImageIsOpen(false);
        getHost();
        toast.success("Host Image Removed Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const indianCities = [
    "Mumbai",
    "Delhi",
    "Bangalore",
    "Kolkata",
    "Chennai",
    "Hyderabad",
    "Pune",
    "Ahmedabad",
    "Jaipur",
    "Lucknow",
    "Kanpur",
    "Nagpur",
    "Surat",
    "Bhopal",
    "Indore",
    "Thane",
    "Coimbatore",
    "Kochi",
    "Vadodara",
    "Visakhapatnam",
    "Chandigarh",
    "Patna",
    "Ludhiana",
    "Agra",
    "Varanasi",
    "Meerut",
    "Rajkot",
    "Jamshedpur",
    "Amritsar",
    "Allahabad",
  ];

  const [callpage, setcallPage] = useState(0);
  const [callrowsPerPage, setcallRowsPerPage] = useState(5);
  const handlecallChangePage = (event, newPage) => {
    setcallPage(newPage);
  };

  const handlecallChangeRowsPerPage = (event) => {
    setcallRowsPerPage(event.target.value);
    setcallPage(0);
  };

  //Followers

  const [followpage, setfollowPage] = useState(0);
  const [followrowsPerPage, setfollowRowsPerPage] = useState(5);
  const handlefollowChangePage = (event, newPage) => {
    setfollowPage(newPage);
  };

  const handlefollowChangeRowsPerPage = (event) => {
    setfollowRowsPerPage(event.target.value);
    setfollowPage(0);
  };

  //Following

  const [followingpage, setfollowingPage] = useState(0);
  const [followingrowsPerPage, setfollowingRowsPerPage] = useState(5);
  const handlefollowingChangePage = (event, newPage) => {
    setfollowingPage(newPage);
  };

  const handlefollowingChangeRowsPerPage = (event) => {
    setfollowingRowsPerPage(event.target.value);
    setfollowingPage(0);
  };

  //blocked

  const [blockpage, setBlockPage] = useState(0);
  const [blockrowsPerPage, setBlockRowsPerPage] = useState(5);
  const handleBlockChangePage = (event, newPage) => {
    setBlockPage(newPage);
  };

  const handleBlockChangeRowsPerPage = (event) => {
    setBlockRowsPerPage(event.target.value);
    setBlockPage(0);
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoIsOpen, setVideoIsOpen] = useState(false);

  const handleModal = () => {
    setModalIsOpen(true);
  };

  const handleVideoModal = () => {
    setVideoIsOpen(true);
  };

  return (
    <LayOut>
      <div className="header">
        <Header title="Host Details" />
        <div
          style={{
            cursor: "pointer",
            width: "95%",
            display: "flex",
            justifyContent: "flex-end",
            marginRight: "30px",
          }}
        >
          <EditIcon
            onClick={handleOpenModal}
            style={{
              fontSize: "30px",

              padding: "5px",
            }}
          />

          <HideImageIcon
            onClick={handleChangeImageIsOpen}
            style={{
              fontSize: "30px",

              padding: "5px",
            }}
          />
        </div>
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex">
            <img src={host?.userImage} className="oneUserImg" />
            <div className="tableBox">
              <table className="oneUserTable">
                <tr>
                  <th>Id</th>
                  <td>{host?._id}</td>
                </tr>
                <tr>
                  <th>User Id</th>
                  <td>{host?.userId}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{host?.FirstName + " " + host?.LastName}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{host?.Gender}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{host?.dateOfBirth?.substring(0, 10)}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{host?.number}</td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>{host?.age}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{host?.city}</td>
                </tr>
                <tr>
                  <th>AadharCard No.</th>
                  <td>{host?.AadharCardNo + "  "}</td>
                </tr>
                <tr>
                  <th>AadharCard Image</th>
                  <td>
                    <i class="fa-solid fa-eye" onClick={handleModal}></i>
                  </td>
                </tr>
              </table>

              <table className="oneUserTable">
                <tr>
                  <th>Status</th>
                  <td>{host?.acctiveStatus}</td>
                </tr>
                <tr>
                  <th>Charge per Minute</th>
                  <td>{host?.hostuser_fees}</td>
                </tr>
                <tr>
                  <th>Total Host Earning</th>
                  <td>{host?.host_balance}</td>
                </tr>
                <tr>
                  <th>Block</th>
                  <td>{host?.blockuser?.length || 0}</td>
                </tr>
                <tr>
                  <th>Followers</th>
                  <td>{host?.followers?.length || 0}</td>
                </tr>
                <tr>
                  <th>Following</th>
                  <td>{host?.followings?.length || 0}</td>
                </tr>
                <tr>
                  <th>Likes</th>
                  <td>{host?.likes?.length || 0}</td>
                </tr>
                <tr>
                  <th>Video Profile</th>
                  <td>
                    <i class="fa-solid fa-eye" onClick={handleVideoModal}></i>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <h4 className="paymentHead">Host Call History</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>ID</th>
                <th>Video Coins</th>
                <th>Sticker Coins</th>
                {/* <th>Sticker Coins</th>
                <th>Total Coins</th> */}
                <th>Status</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {host?.userCallhistorys
                ?.slice(
                  callpage * callrowsPerPage,
                  callpage * callrowsPerPage + callrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber = index + 1 + callpage * callrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.targetId?.fullName}</td>
                      <td>{item?.targetId?._id}</td>
                      <td>{item?.targetId?.videoCoins}</td>
                      <td>{item?.targetId?.stickerSpentCoins}</td>
                      {/* <td>{item?.targetId?.stickerSpentCoins}</td>
                      <td>{item?.targetId?.total_coins}</td> */}
                      <td>{item?.targetId?.acctiveStatus}</td>
                      <td>{item?.targetId?.total_minute + " min"}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.userCallhistorys?.length}
              rowsPerPage={callrowsPerPage}
              page={callpage}
              onPageChange={handlecallChangePage}
              onRowsPerPageChange={handlecallChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Followers List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.followers
                ?.slice(
                  followpage * followrowsPerPage,
                  followpage * followrowsPerPage + followrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber =
                    index + 1 + followpage * followrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?._id}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item?.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.followers?.length}
              rowsPerPage={followrowsPerPage}
              page={followpage}
              onPageChange={handlefollowChangePage}
              onRowsPerPageChange={handlefollowChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Following List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.followings
                ?.slice(
                  followingpage * followingrowsPerPage,
                  followingpage * followingrowsPerPage + followingrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber =
                    index + 1 + followingpage * followingrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?._id}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.followings?.length}
              rowsPerPage={followingrowsPerPage}
              page={followingpage}
              onPageChange={handlefollowingChangePage}
              onRowsPerPageChange={handlefollowingChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Blocked List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.blockuser
                ?.slice(
                  blockpage * blockrowsPerPage,
                  blockpage * blockrowsPerPage + blockrowsPerPage
                )
                .map((item, index) => {
                  const serialNumber = index + 1 + blockpage * blockrowsPerPage;
                  return (
                    <tr>
                      <td>{serialNumber}</td>
                      <td>{item?.fullName}</td>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.dateOfBirth?.substring(0, 10)}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.blockuser?.length}
              rowsPerPage={blockrowsPerPage}
              page={blockpage}
              onPageChange={handleBlockChangePage}
              onRowsPerPageChange={handleBlockChangeRowsPerPage}
            />
          </div>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} style={customStyles}>
        {console.log("Host", host)}
        <button onClick={() => setModalIsOpen(false)} className="closeModal">
          X
        </button>
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Aadhar Card
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={host?.AadharCardFrontPicture}
            alt="AadharCardFrontPicture"
            style={{ width: "300px", height: "auto", marginBottom: "20px" }}
          ></img>
          <img
            src={host?.AadharCardBackPicture}
            alt="AadharCardBackPicture"
            style={{ width: "300px", height: "auto" }}
          ></img>
        </div>
      </Modal>

      <Modal isOpen={videoIsOpen} style={customStyles}>
        <button onClick={() => setVideoIsOpen(false)} className="closeModal">
          X
        </button>
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Video Profile
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={host?.videoProfile}
            alt="videoProfile"
            style={{ width: "300px", height: "auto", marginBottom: "20px" }}
          ></img>
        </div>
      </Modal>

      <Modal
        isOpen={editUserModalIsOpen}
        onRequestClose={handleCloseModal}
        style={customStyles}
      >
        <button
          className="colseModal"
          onClick={() => setEditUserModalIsOpen(false)}
        >
          X
        </button>
        <div className="modalHeader">
          <h2>Edit User</h2>
        </div>

        <div className="modalBody">
          <form className="form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="fullName" className="form-label">
                Full Name
              </label>
              <input
                type="text"
                className="form-control"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={(e) =>
                  setFormData({ ...formData, fullName: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="gender" className="form-label">
                Gender
              </label>
              <select
                className="form-control"
                id="gender"
                name="gender"
                value={formData.gender}
                onChange={(e) =>
                  setFormData({ ...formData, gender: e.target.value })
                }
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="dateOfBirth" className="form-label">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control"
                id="dateOfBirth"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={(e) =>
                  setFormData({ ...formData, dateOfBirth: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                maxLength="10"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({ ...formData, phone: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="age" className="form-label">
                Age
              </label>
              <input
                type="number"
                className="form-control"
                id="age"
                name="age"
                value={formData.age}
                onChange={(e) =>
                  setFormData({ ...formData, age: e.target.value })
                }
              />
            </div>

            <div className="form-group">
              <label htmlFor="city" className="form-label">
                City
              </label>
              <select
                className="form-control"
                id="city"
                name="city"
                value={formData.city}
                onChange={(e) =>
                  setFormData({ ...formData, city: e.target.value })
                }
              >
                <option value="">Select City</option>
                {indianCities.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </select>
            </div>

            <button type="submit" className="form-button">
              Submit
            </button>
          </form>
        </div>
      </Modal>

      <Modal
        isOpen={editImageIsOpen}
        onRequestClose={handleCloseImageIsOpen}
        style={customStyles}
      >
        <button className="colseModal" onClick={() => setImageIsOpen(false)}>
          X
        </button>
        <div className="modalHeader">
          <h2>Edit User</h2>
        </div>

        <div className="modalBody">
          <label htmlFor="image">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            name="image"
            onChange={(e) => setNewImage(e.target.files[0])}
          />
        </div>
        <button
          onClick={handleImageSubmit}
          type="submit"
          className="form-button"
          style={{
            marginRight: "20px",
          }}
        >
          Submit
        </button>
        <button
          onClick={handleRemoveImage}
          type="submit"
          className="form-button"
        >
          Remove Image
        </button>
      </Modal>
    </LayOut>
  );
}
