import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import herImage from "../../assets/AllUserImages/Hero.jpg";
import api from "../../components/api";

const LeaderLogin = () => {
  const navigate = useNavigate();
  const [usernameField, setUsernameField] = useState("");
  const [passwordField, setPasswordField] = useState("");

  const username = (e) => {
    setUsernameField(e.target.value);
  };
  const password = (e) => {
    setPasswordField(e.target.value);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    axios
      .post(api + "/admin/leaderLogin", {
        email: usernameField,
        password: passwordField,
      })
      .then((response) => {
        localStorage.setItem("leaderId", response.data.leader_Id);
        console.log(response);
        localStorage.setItem("leaderToken", response.data.token);
        navigate("/leader");
        navigate(0);
      })
      .catch(function (error) {
        console.log(error);
        if (usernameField === "") {
          toast.error("Please Enter Username");
          return;
        } else if (passwordField === "") {
          toast.error("Please Enter Password");
          return;
        } else {
          toast.error("Invalid Credentials");
          return;
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflowY: "hidden",
      }}
    >
      <div className="mainlogin">
        <div className="headerlogin">
          <h1 className="login">Welcome To Catchwoo</h1>
          <h1
            style={{
              fontSize: "50px",
              color: "#410d63",
              textAlign: "center",
            }}
          >
            Leader
          </h1>
          <h2 className="sublogin">Please Login To Your Account</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>
            <label className="username" for="uname"></label>
            <input
              className="usernamebox"
              type="text"
              placeholder="Enter Username"
              name="uname"
              onChange={username}
              required
            />
          </div>
          <div>
            <label className="userpassword" for="psw"></label>
            <input
              className="passbox"
              type="password"
              placeholder="Enter Password"
              name="psw"
              onChange={password}
              required
            />
          </div>
        </div>
        <div>
          <button className="submitbtn" type="submit" onClick={formSubmit}>
            Login
          </button>
        </div>
      </div>
      <div className="imgcontainer">
        <img className="hero" src={herImage} alt="erorr"></img>
      </div>
    </div>
  );
};

export default LeaderLogin;
