import { TablePagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Modal from "react-modal";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import List from "../components/List";
import Spinner from "../components/Spinner";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const HostReport = () => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState(false);

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/getAllHostUserreport", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setPosts(res.data.findreport);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  //
  //handleImage-------------------------------------------------------------//
  //

  const [imageurl, setImageurl] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const handleImage = (val) => {
    setImageModal(true);
    setImageurl(val);
  };

  const hostReportTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };
  {
  }

  //Handle Warning Messages
  const [userToWarn, setUserToWarn] = useState(null);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningForm, setWarningForm] = useState({
    title: "",
    body: "",
  });

  const handleWarningModalOpen = (user) => {
    setUserToWarn(user);
    setIsWarningModalOpen(true);
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleAdminWarning = () => {
    if (warningForm.title && warningForm.body) {
      axios
        .post(
          api + `/sendNotiHostRequest`,
          {
            _id: userToWarn,
            title: warningForm.title,
            body: warningForm.body,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          alert(res.data.message);
          setIsWarningModalOpen(false);
          setWarningForm({
            title: "",
            body: "",
          });
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("Please fill all the fields");
    }
  };

  const [isWarningAcceptModalOpen, setIsWarningAcceptModalOpen] =
    useState(false);

  const handleWarningAcceptModalOpen = (user) => {
    setUserToWarn(user);
    setIsWarningAcceptModalOpen(true);
  };

  const handleWarningAcceptModalClose = () => {
    setIsWarningAcceptModalOpen(false);
  };

  const handleAdminWarningAccept = () => {
    axios
      .post(
        api + `/sendNotiHostResponce`,
        {
          _id: userToWarn,
          title: warningForm.title,
          body: warningForm.body,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        alert(res.data.message);
        setIsWarningAcceptModalOpen(false);
        setWarningForm({
          title: "",
          body: "",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LayOut>
      <div className="header">
        <Header title="Host Report" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={hostReportTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={hostReportTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Report Id</th>
                <th>Host Name</th>
                <th>Host Phone</th>
                <th>User Name</th>
                <th>User Phone</th>
                <th>Report Reason</th>
                <th>Report Image</th>
                <th>CreatedAt</th>
                <th>UpdatedAt</th>
                <th>Warning</th>
                <th>Remove Warning</th>
              </tr>
            </thead>
            <tbody>
              {posts.length > 0 ? (
                posts
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= weekDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= monthDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1 + rowsPerPage * page}</td>
                        <td>{item?.userId?._id}</td>
                        <td>{item?.userId?.FirstName}</td>
                        <td>{item?.userId?.number}</td>
                        <td>{item?.targetId?.fullName}</td>
                        <td>{item?.targetId?.phone}</td>
                        <td>{item?.Choose_the_Reason}</td>
                        <td onClick={() => handleImage(item?.ReportImage)}>
                          <i class="fa-solid fa-eye"></i>
                        </td>
                        <td>{item?.createdAt.split("T")[0]}</td>
                        <td>{item?.updatedAt.split("T")[0]}</td>
                        <td
                          style={{
                            color: "#116FCD",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                          onClick={() => handleWarningModalOpen(item?._id)}
                        >
                          Warning
                        </td>
                        <td
                          style={{
                            color: "#116FCD",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                          onClick={() =>
                            handleWarningAcceptModalOpen(item?._id)
                          }
                        >
                          Remove Warning
                        </td>
                      </tr>
                    );
                  })
              ) : (
                <tr>
                  <td colSpan="12" style={{ textAlign: "center" }}>
                    No Reports Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={posts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <Modal isOpen={imageModal} style={customStyles}>
        <button className="colseModal" onClick={() => setImageModal(false)}>
          X
        </button>
        <img src={imageurl} className="bannerImage" alt="ReportImage"></img>
      </Modal>
      <Modal
        isOpen={isWarningModalOpen}
        style={customStyles}
        onRequestClose={handleWarningModalClose}
      >
        <button className="colseModal" onClick={handleWarningModalClose}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Warning Host
        </h3>
        <div className="addHostCharge">
          <input
            type="text"
            placeholder="Title"
            value={warningForm.title}
            onChange={(e) =>
              setWarningForm({ ...warningForm, title: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Body"
            value={warningForm.body}
            onChange={(e) =>
              setWarningForm({ ...warningForm, body: e.target.value })
            }
          />
          <button onClick={handleAdminWarning}>Warning</button>
        </div>
      </Modal>

      <Modal
        isOpen={isWarningAcceptModalOpen}
        style={customStyles}
        onRequestClose={handleWarningAcceptModalClose}
      >
        <button className="colseModal" onClick={handleWarningAcceptModalClose}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Remove Warning
        </h3>
        <div className="addHostCharge">
          <input
            type="text"
            placeholder="Title"
            value={warningForm.title}
            onChange={(e) =>
              setWarningForm({ ...warningForm, title: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Body"
            value={warningForm.body}
            onChange={(e) =>
              setWarningForm({ ...warningForm, body: e.target.value })
            }
          />
          <button onClick={handleAdminWarningAccept}>Send Message</button>
        </div>
      </Modal>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default HostReport;
