import React, { useState, useRef } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import logout from "../assets/AllUserImages/logout.png";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import api from "./api";
import { useEffect } from "react";
import { Avatar } from "@mui/material";
import { useLocation } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const Navbar = () => {
  const navigate = useNavigate();
  let date = new Date();
  const [time, setTime] = useState(date.toLocaleString());

  setInterval(() => {
    date = new Date();
    setTime(date.toLocaleString());
  }, 1000);

  const handleSideBar = () => {
    let elem = document.getElementById("sidebar");
    if (elem.style.display === "none") {
      elem.style.display = "block";
    } else {
      elem.style.display = "none";
    }
  };

  const handleLogOut = () => {
    localStorage.clear("token");
    navigate("/");
  };

  //---------------------------HelpLine--------------------------------//
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [editPassword, setEditPassword] = useState(false);
  const [helpNo, setHelpNo] = useState("");
  const [data, setData] = useState("");
  const [id, setId] = useState("");
  const [addNo, setAddNo] = useState("");

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const hanldeAddModal = () => {
    setOpenAdd(true);
  };

  const hanldeEditModal = () => {
    setOpenEdit(true);
    setDropdownOpen(false);
  };

  const handleCloseModal = () => {
    setOpenAdd(false);
  };

  const handleValue = (e) => {
    setHelpNo(e.target.value);
  };

  const handleNewValue = (e) => {
    setData(e.target.value);
  };

  const handleAddHelpline = () => {
    if (!data) {
      axios
        .post(
          api + "/addhelplinenumber",
          {
            number: helpNo,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then(() => {
          toast.success("HelpLine Number Added");
          setOpenAdd(false);
          setAddNo("added");
        })
        .catch((e) => {
          console.log("AddhelpLine", e);
          toast.error("HelpLine Number Not Added");
        });
    } else {
      console.log("edit");
    }
  };

  const getHelpLineNumber = () => {
    axios
      .get(api + "/findAllhelplinenumber", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setData(res.data.getalldata[0].number);
        setId(res.data.getalldata[0]._id);
      })
      .catch((e) => {
        console.log("getHelpLine", e);
      });
  };

  useEffect(() => {
    getHelpLineNumber();
  }, [addNo]);

  const handleEditHelpLine = () => {
    axios
      .put(
        api + `/updatehelplinenumber/${id}`,
        {
          number: data,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        toast.success("HelpLine Number Updated");
        setOpenEdit(false);
      })
      .catch((e) => {
        console.log("AddhelpLine", e);
        toast.error("HelpLine Number Not Updated");
      });
  };

  const handleDeleteHelpLine = () => {
    axios
      .delete(api + `/deletehelplinenumber/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        toast.success("HelpLine Number Deleted");
        setOpenEdit(false);
        setAddNo("");
      })
      .catch((e) => {
        console.log("AddhelpLine", e);
        toast.error("HelpLine Number Not Deleted");
      });

    setData("");
  };

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const handleProfileClick = () => {
    setDropdownOpen(true); // Toggle the dropdown
  };

  const listItemStyle = {
    padding: "10px",
    cursor: "pointer",
  };

  const profileIconStyle = {
    cursor: "pointer",
  };

  const dropdownMenuStyle = {
    position: "absolute",
    top: "100%",
    right: "0",
    backgroundColor: "white",
    border: "1px solid #ccc",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)",
    zIndex: 1,
    width: "120px",
    display: isDropdownOpen ? "block" : "none",
  };

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleChangePassword = () => {
    console.log(id);
    axios
      .put(
        api + `/adminSetPassword/65003f64e42d8098c0afedb9`,
        {
          password: oldPassword,
          newPassword: newPassword,
          conformPassword: confirmPassword,
        },
        {
          // headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        toast.success("Password Changed");
        setEditPassword(false);
        setDropdownOpen(false);
      })
      .catch((e) => {
        toast.error("Password Not Changed");
        console.log("Password", e);
      });
  };
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to detect clicks outside the profile dropdown
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="nav">
        <div
          style={{
            marginRight: "10px",
          }}
          className="burger"
          onClick={handleSideBar}
        >
          <AiOutlineMenu size={30} color="white" />
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="nav-list">
            <ul className="menu">
              <li className="menu-item">
                <p className="date-text">{time}</p>
              </li>

              {!data ? <></> : <li className="menu-item1"></li>}
            </ul>
          </div>
          <div className="profile" style={{ position: "relative" }}>
            <Avatar
              src="your-profile-image-url.jpg"
              style={profileIconStyle}
              onClick={handleProfileClick}
            />
            {isDropdownOpen && (
              <div
                className="dropdown-menu"
                ref={dropdownRef} // Attach the ref to the dropdown element
                style={{
                  position: "absolute",
                  top: "100%",
                  right: 0,
                  backgroundColor: "#fff",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                  borderRadius: "4px",
                  minWidth: "160px",
                  zIndex: 1000,
                  fontFamily: "Arial, sans-serif",
                }}
              >
                <ul style={{ listStyle: "none", padding: 0 }}>
                  <li
                    onClick={hanldeEditModal}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    Edit HelpLine Number
                  </li>
                  <li
                    onClick={() => {
                      setEditPassword(true);
                    }}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      borderBottom: "1px solid #e0e0e0",
                    }}
                  >
                    Change Password
                  </li>
                  <li
                    onClick={handleLogOut}
                    style={{
                      padding: "10px 20px",
                      cursor: "pointer",
                      color: "#dc3545", // Red color
                    }}
                  >
                    Logout{" "}
                    <img className="navlogimg" src={logout} alt="error" />
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <Modal isOpen={openAdd} style={customStyles}>
          <h3 style={{ textAlign: "center" }}>Add HelpLine Number</h3>
          <button className="closeModal" onClick={() => handleCloseModal()}>
            X
          </button>
          <div className="alignInBtn">
            <input
              type="text"
              className="helpLineInput"
              onChange={(val) => handleValue(val)}
              maxLength="10"
            />
            <button onClick={() => handleAddHelpline()}>Add</button>
          </div>
        </Modal>

        <Modal isOpen={openEdit} style={customStyles}>
          <h3 style={{ textAlign: "center" }}>Edit HelpLine Number</h3>
          <button className="closeModal" onClick={() => setOpenEdit(false)}>
            X
          </button>
          <div className="alignInBtn">
            <input
              type="text"
              className="helpLineInput"
              value={data}
              onChange={(val) => handleNewValue(val)}
              maxLength="10"
            />
            <div>
              <button onClick={() => handleEditHelpLine()}>Edit</button>
              <button onClick={() => handleDeleteHelpLine()}>Delete</button>
            </div>
          </div>
        </Modal>

        <Modal isOpen={editPassword} style={customStyles}>
          <h3 style={{ textAlign: "center" }}>New Password</h3>
          <button className="closeModal" onClick={() => setEditPassword(false)}>
            X
          </button>
          <div className="alignInBtn">
            <input
              style={{
                marginBottom: "10px",
              }}
              type="password"
              className="helpLineInput"
              placeholder="Password"
              value={oldPassword}
              onChange={(val) => setOldPassword(val.target.value)}
            ></input>
            <input
              style={{
                marginBottom: "10px",
              }}
              type="password"
              className="helpLineInput"
              placeholder="New password"
              value={newPassword}
              onChange={(val) => setNewPassword(val.target.value)}
            ></input>
            <input
              type="password"
              className="helpLineInput"
              placeholder="Confirm New password"
              value={confirmPassword}
              onChange={(val) => setConfirmPassword(val.target.value)}
            ></input>

            <button onClick={handleChangePassword}>Confirm</button>
          </div>
        </Modal>
      </div>
      <ToastContainer />
    </>
  );
};

export default Navbar;
