import React, { useState } from "react";
import { AiOutlineDown, AiOutlineRight } from "react-icons/ai";
import { BsSpeedometer2 } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { Link } from "react-router-dom";
import memeImage from "../../assets/AllUserImages/02.png";
import CustomLink from "../../components/CustomLink";

const LeaderSidebar = () => {
  const [isVisible, setIsVisible] = useState("");
  const [isOpen, setIsOpen] = useState("");

  const sidebarData = [
    {
      key: "dashboard",
      name: "Dashboard",
      icon: <BsSpeedometer2 />,
      subMenu: false,
      path: "/leader",
    },
    {
      key: "host",
      name: "Host",
      icon: <CgProfile />,
      subMenu: true,
      subMenuData: [
        {
          key: "hostList",
          name: "Host List",
          path: "/leader/allHosts",
        },
        {
          key: "hostRequest",
          name: "Host Request",
          path: "/leader/hostrequest",
        },
        {
          key: "hostAccept",
          name: "Accepted Host",
          path: "/leader/acceptedHost",
        },
        {
          key: "hostRejected",
          name: "Rejected Host",
          path: "/leader/rejectedHost",
        },
        {
          key: "hostEarnings",
          name: "Host Earnings",
          path: "/leader/hostearnings",
        },
        // {
        //   key: "hostReport",
        //   name: "Host Report",
        //   path: "/leader/hostReport",
        // },
      ],
    },
  ];

  const handleDropDown = (key) => {
    if (isVisible === key) {
      setIsVisible("");
    } else {
      setIsVisible(key);
    }
  };
  const handleMenu = (key) => {
    if (isOpen === key) {
      setIsOpen("");
    } else {
      setIsOpen(key);
    }
  };
  // setIsOpen(key);
  /*============================================================== */

  /*============================================================== */
  return (
    <div className="sidebar" id="sidebar">
      <div className="header">
        <img src={memeImage} alt="icon" />
      </div>
      <div className="side-list">
        {sidebarData.map((item, index) => (
          <li className="list-item" key={index}>
            <CustomLink path={item.path}>
              <div
                className="main-group"
                onClick={() => handleDropDown(item.key)}
              >
                <div className="list-group">
                  <div className="icon-holder">{item.icon}</div>
                  <span>{item.name}</span>
                </div>
                {item.subMenu &&
                  (isVisible === item.key ? (
                    <AiOutlineDown
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <AiOutlineRight
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ))}
              </div>
            </CustomLink>
            {item.subMenu && item.key === isVisible && (
              <ul className="submenu">
                {item.subMenuData.map((data, index) => (
                  <li className="sublist" key={index}>
                    <CustomLink path={data.path}>
                      <div onClick={() => handleMenu(data.key)}>
                        {data.name}
                      </div>
                    </CustomLink>
                    {data.subMenu && data.key === isOpen && (
                      <ul className="submenu level3">
                        {data.subMenuData.map((value, index) => (
                          <Link to={value.path}>
                            <li className="sublist" key={index}>
                              <div>{value.name}</div>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </div>
    </div>
  );
};

export default LeaderSidebar;
