import { TablePagination } from "@mui/material";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { useMessage } from "../../../base/context/MessageProvider";
import Header from "../../../components/Header";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";
import Spinner from "../../../components/Spinner";
import api from "../../../components/api";

const LeaderHostEarnings = () => {
  const message = useMessage();
  const [leaderHostEarnings, setLeaderHostEarnings] = useState([]);
  const [load, setLoad] = useState([]);
  const id = localStorage.getItem("leaderId");

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/leader/getLeaderHostEarnings/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderHostEarnings(res.data.hostuserBalance);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  const hostEarnTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Host Earnings" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={hostEarnTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <input
              type="search"
              className="SearchInput"
              placeholder="Search By Name or User Id..."
              onChange={handleSearchValue}
            ></input>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={hostEarnTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Phone</th>
                <th>Email</th>
                {/* <th>Gifts </th>
                <th>Emoji</th> */}
                <th>Charge/min</th>
                <th>Video Users</th>
                <th>Stickers</th>
                <th>Total Earnings </th>
              </tr>
            </thead>

            <tbody>
              {leaderHostEarnings?.length === 0 ? (
                <tr>
                  <td colSpan="8">No earnings found</td>
                </tr>
              ) : (
                leaderHostEarnings
                  .filter((item) => {
                    let Name = item.FirstName + " " + item.LastName;

                    if (!searchValue) {
                      return item;
                    } else if (
                      Name.toLowerCase().includes(searchValue.toLowerCase())
                    ) {
                      return item;
                    } else if (item.userId.includes(searchValue)) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    {
                      console.log(item);
                    }
                    return (
                      <tr>
                        <td>{index + 1 + rowsPerPage * page}</td>
                        <td>{item?.userId}</td>
                        <td>{item?.FirstName + " " + item.LastName}</td>
                        <td>
                          {item?.number
                            ? "*".repeat(
                                Math.max(0, (item.number + "").length - 2)
                              ) + item.number.toString().slice(-2)
                            : ""}
                        </td>

                        <td>
                          {(item?.email || "").substring(0, 5) +
                            "*".repeat(
                              Math.max(0, (item?.email || "").length - 5)
                            )}
                        </td>
                        <td>{item?.hostuser_fees}</td>
                        <td>{item?.videoUserCoins}</td>
                        <td>{item?.stickerCoins}</td>
                        {/* <td>{item?.giftCoins}</td> */}
                        <td>{item?.host_balance}</td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>
        </div>
        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={leaderHostEarnings.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LeaderLayOut>
  );
};

export default LeaderHostEarnings;
