import axios from "axios";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import api from "../components/api";
import ActionModal from "./ActionModal";

const Createwallet = () => {
  const [coins, setCoins] = useState("");
  const [price, setPrice] = useState("");
  const [offer, setOffer] = useState("");
  const [load, setLoad] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleCoins = (e) => {
    setCoins(e.target.value);
  };

  const handlePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleOffer = (e) => {
    setOffer(e.target.value);
  };

  const handleAddWallet = async () => {
    setLoad(true);
    await axios
      .post(
        api + "/admin/addWallet",
        {
          coins,
          price,
          offer,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Deal Added");
        setTimeout(() => {
          setOpenModal(true);
        }, 3000);
      })
      .catch((error) => {
        setLoad(false);
        toast.error("Deal Not Added");
        console.log(error);
      });
  };

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Create Deal" />
      </div>
      <div className="addbannermain">
        <div className="addinput">
          Coins
          <input
            className="addinputtext smallInput"
            type="number"
            onChange={handleCoins}
          />
        </div>
        <div className="addinput">
          Price
          <input
            className="addinputtext smallInput"
            type="number"
            onChange={handlePrice}
          />
        </div>
        <div className="addinput">
          Offer
          <input
            className="addinputtext smallInput"
            type="text"
            onChange={handleOffer}
          />
        </div>

        <div className="savecancelbuttonWalet">
          <button
            className="submitbutton"
            type="submit"
            value="Submit"
            onClick={handleAddWallet}
          >
            SAVE
          </button>
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
      <ActionModal
        openM={openModal}
        openMM={setOpenModal}
        keyWord="Wallet"
        addLink="/Createwallet"
        listLink="/Listwallet"
      />
    </LayOut>
  );
};

export default Createwallet;
