import React, { useEffect, useState } from "react";
import LeaderLogin from "./LeaderLogin";
import LeaderDashboard from "./LeaderDashboard";

function LeaderWrapper() {
  const [isLeaderLogin, setIsLeaderLogin] = useState(false);

  useEffect(() => {
    setIsLeaderLogin(localStorage.getItem("leaderToken"));
  }, []);

  return <div>{isLeaderLogin ? <LeaderDashboard /> : <LeaderLogin />}</div>;
}

export default LeaderWrapper;
