import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import api from "../components/api";

const Topearningsgirls = () => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState(false);

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/AdminfindTopHost", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setPosts(res.data.hostuser);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  const TopHostTableRef = useRef(null);

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <LayOut>
      <div className="header">
        <Header title="Top Earnings Host" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={TopHostTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <input
              type="search"
              className="SearchInput"
              placeholder="Search By Name or User Id..."
              onChange={handleSearchValue}
            ></input>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={TopHostTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Host ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Phone</th>
                <th>Email</th>
                <th>Age </th>
                <th>City</th>
                <th>Status </th>
                <th>Charge/min</th>
                <th>Video Coins</th>
                <th>Sticker Coins</th>
                <th>Total Earnings </th>
              </tr>
            </thead>
            <tbody>
              {posts
                ?.filter((item) => {
                  let Name = item.FirstName + " " + item.LastName;

                  if (!searchValue) {
                    return item;
                  } else if (
                    Name.toLowerCase().includes(searchValue.toLowerCase())
                  ) {
                    return item;
                  } else if (item.userId.includes(searchValue)) {
                    return item;
                  }
                })
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?._id}</td>
                      <td>{item?.FirstName + " " + item.LastName}</td>
                      <td>{item?.Gender}</td>
                      <td>{item?.number}</td>
                      <td title={item?.email}>
                        {item?.email.substring(0, 5) + "..."}
                      </td>
                      <td>{item?.age}</td>
                      <td>{item?.city}</td>
                      <td>{item?.status}</td>
                      <td>{item?.hostuser_fees}</td>
                      <td>{item?.videoUserCoins}</td>
                      <th>{item?.stickerCoins}</th>
                      <td>{item?.host_balance}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default Topearningsgirls;
