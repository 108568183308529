import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import api from "../components/api";
import ActionModal from "./ActionModal";

export default function UploadBannerImage() {
  let params = useParams();
  let id = params.id;
  let navigate = useNavigate();

  //---------------------------getOneBanner----------------------------------//
  const [oneBanner, setOneBanner] = useState("");
  const [load, setLoad] = useState(false);
  const getOneBanner = async () => {
    setLoad(true);
    axios
      .get(api + `/getOneBanner/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setOneBanner(res.data.findbanner);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getOneBanner();
  }, []);
  //-----------------------------------------------------------------------//

  const [openModal, setOpenModal] = useState(false);

  //----------------------------------UploadImage-------------------------------------//
  const [image, setImage] = useState("");

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUploadImage = () => {
    if (image.size > 2 * 1024 * 1024) {
      // 2MB in bytes
      toast.error("Banner Image size should be less than 2MB.");
    } else {
      setLoad(true);
      axios
        .put(
          api + `/updateBannerPicture/${id}`,
          { image: image },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(() => {
          setLoad(false);
          toast.success("Banner Uploaded");
          setTimeout(() => {
            setOpenModal(true);
          }, 3000);
        })
        .catch((e) => {
          setLoad(false);
          toast.error("Banner Image Not Uploaded");
          console.log("error", e);
        });
    }
  };

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Upload Image" />
      </div>
      <div className="addbannermain">
        <div className="addinput smallInput">
          Banner Name
          <input className="addinputtext" type="text" value={oneBanner.name} />
        </div>

        <div
          className="briefdescription smallInput"
          style={{ marginBottom: "20px" }}
        >
          Banner Description
          <textarea
            className="textBox"
            type="text"
            value={oneBanner.description}
          ></textarea>
        </div>
        <div className="addchoosetext">Upload Image</div>
        <div className="choosefile smallInput">
          <input type="file" id="actual-btn" onChange={handleImage} />
        </div>
        <div className="savecancelbutton">
          <button
            className="accptBtn"
            type="submit"
            value="Submit"
            onClick={() => handleUploadImage()}
          >
            Upload
          </button>
          <p
            style={{
              color: "red",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            *The Banner Size should be 2419px X 718px
          </p>
        </div>
      </div>
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <ToastContainer />
      <ActionModal
        openM={openModal}
        openMM={setOpenModal}
        keyWord="Banner"
        addLink="/addbanner"
        listLink="/listbanner"
      />
    </LayOut>
  );
}
