import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "./api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

Modal.setAppElement("#root");

const Details = ({
  today,
  posts,
  selectDate,
  refer,
  weekData,
  TodayStatic,
  monthData,
}) => {
  let navigate = useNavigate();

  const handleView = (id) => {
    navigate(`/oneUser/${id}`);
  };

  const todayDate = today;
  const chooseDate = selectDate;
  const tableRef = refer;
  const WeekDate = weekData;
  const TStactic = TodayStatic;
  const monthDate = monthData;

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [editModel, setEditModel] = useState(false);
  const [edit_id, edit_setId] = useState("");
  const [editValue, setEditValue] = useState("");

  const handleEditModel = (id) => {
    setEditModel(true);
    edit_setId(id);
    console.log(id);
  };

  const editCoins = () => {
    axios
      .put(
        api + `/updateUserCoins/${edit_id}`,
        {
          total_coins: editValue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setEditModel(false);
        edit_setId("");
        setEditValue("");
        window.location.reload();
        toast.success("Coins Updated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  useEffect(() => {
    console.log("edit_id:", edit_id);
    console.log("editValue:", editValue);
  }, [edit_id, editValue]);

  const [selectedActions, setSelectedActions] = useState({});
  const [actionId, setActionId] = useState("");

  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);
  const [isTerminationModalOpen, setIsTerminationModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningForm, setWarningForm] = useState({
    title: "",
    body: "",
  });

  const [suspendDays, setSuspendDays] = useState("");

  const handleSuspensionModalOpen = () => {
    setIsSuspensionModalOpen(true);
  };

  const handleSuspensionModalClose = () => {
    setIsSuspensionModalOpen(false);
  };

  const handleTerminationModalOpen = () => {
    setIsTerminationModalOpen(true);
  };

  const handleTerminationModalClose = () => {
    setIsTerminationModalOpen(false);
  };

  const handleWarningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleSelectAction = (postId, selectedAction) => {
    setSelectedActions((prevSelectedActions) => ({
      ...prevSelectedActions,
      [postId]: selectedAction,
    }));
  };

  const handleIconClick = (postId, action) => {
    if (action === "warning") {
      handleWarningModalOpen();
    }
    if (action === "suspend") {
      handleSuspensionModalOpen();
    }

    if (action === "terminate") {
      handleTerminationModalOpen();
    }
    if (action === "removeSuspension") {
      handleRemoveSuspensionModalOpen();
    }
    setActionId(postId);
  };

  const handleAdminSuspend = () => {
    axios
      .put(
        api + `/adminSuspendUser`,
        {
          id: actionId,
          days: suspendDays,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setEditModel(false);
        edit_setId("");
        setEditValue("");
        window.location.reload();
        toast.success("User Suspended Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleAdminTerminate = () => {
    axios
      .delete(api + `/adminDeleteUser/${actionId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        setEditModel(false);
        edit_setId("");
        setEditValue("");
        window.location.reload();
        toast.success("User Terminated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleAdminWarning = () => {
    axios
      .post(
        api + `/sendWorningNotification`,
        {
          id: actionId,
          title: warningForm.title,
          body: warningForm.body,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setEditModel(false);
        edit_setId("");
        setEditValue("");
        window.location.reload();
        toast.success("User Warned Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const [removeSuspensionModalOpen, setRemoveSuspensionModalOpen] =
    useState(false);

  const handleRemoveSuspensionModalOpen = () => {
    setRemoveSuspensionModalOpen(true);
  };

  const handleRemoveSuspensionModalClose = () => {
    setRemoveSuspensionModalOpen(false);
  };

  const handleRemoveSuspension = () => {
    axios
      .put(
        api + `/updateSuspendedUser/` + actionId,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setEditModel(false);
        edit_setId("");
        setEditValue("");
        window.location.reload();
        toast.success("Suspension Removed Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  return (
    <>
      <div className="table-container">
        <table className="content-table" ref={tableRef}>
          <thead className="table-head">
            <tr>
              <th>S.No.</th>
              <th>Id</th>
              <th>Name</th>
              <th>Gender</th>
              {/* <th>Date Of Birth</th> */}
              {/* <th>Phone</th> */}
              <th>City</th>
              <th>Created At</th>
              <th>Current Balance</th>
              <th>Updated Date</th>
              <th>Previous Balance</th>
              <th>Previous Date</th>
              <th>All Details</th>
              <th>Action</th>
              <th>Status</th>
            </tr>
          </thead>

          <tbody>
            {posts
              ?.filter((item) => {
                if (!todayDate && !chooseDate && !WeekDate && !monthDate) {
                  return item;
                } else if (
                  item.createdAt.split("T")[0].includes(todayDate) &&
                  !chooseDate &&
                  !WeekDate &&
                  !monthDate
                ) {
                  return item;
                } else if (
                  item.createdAt.split("T")[0].includes(chooseDate) &&
                  !todayDate &&
                  !WeekDate &&
                  !monthDate
                ) {
                  return item;
                } else if (
                  item.createdAt >= WeekDate &&
                  item.createdAt <= TStactic &&
                  !todayDate &&
                  !chooseDate &&
                  !monthDate
                ) {
                  return item;
                } else if (
                  item.createdAt >= monthDate &&
                  item.createdAt <= TStactic &&
                  !todayDate &&
                  !chooseDate &&
                  !WeekDate
                ) {
                  return item;
                }
              })
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((post, index) => (
                <tr>
                  <td>{index + 1 + rowsPerPage * page}</td>
                  <td>{post?._id}</td>
                  <td>{post?.fullName}</td>
                  <td>{post?.gender}</td>
                  {/* <td>{post?.dateOfBirth.substring(0, 10)}</td> */}
                  {/* <td>{post?.phone}</td> */}
                  <td>{post?.city}</td>
                  <td>{moment(post?.createdAt)?.format("YYYY-MM-DD")}</td>

                  <td>
                    {post?.total_coins}
                    <i
                      class="fa-solid fa-pen-to-square HostEdit"
                      onClick={() => handleEditModel(post?._id)}
                    ></i>
                  </td>
                  <td title={post?.updateDate?.substring(0, 25)}>
                    {post?.updateDate ? post?.updateDate?.substring(0, 16) : ""}
                  </td>
                  <td>{post?.priviousCoins}</td>
                  <td title={post?.priviosCoinsDate?.substring(0, 25)}>
                    {post?.priviosCoinsDate
                      ? post?.priviosCoinsDate?.substring(0, 16)
                      : ""}
                  </td>
                  <td
                    onClick={() => handleView(post._id)}
                    style={{
                      color: "#116FCD",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    View
                  </td>

                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <select
                        value={selectedActions[post._id] || ""}
                        onChange={(e) =>
                          handleSelectAction(post._id, e.target.value)
                        }
                      >
                        <option value="">Select Action</option>
                        <option value="warning">Warning</option>
                        <option value="suspend">Suspend</option>
                        <option value="terminate">Terminate</option>
                        <option value="removeSuspension">Active</option>
                      </select>

                      <i
                        style={{
                          width: "20px",
                          color:
                            selectedActions[post._id] === "warning"
                              ? "#afaf0a"
                              : selectedActions[post._id] === "suspend"
                              ? "orange"
                              : selectedActions[post._id] === "terminate"
                              ? "red"
                              : selectedActions[post._id] === "removeSuspension"
                              ? "green"
                              : "",
                        }}
                        className="fas fa-exclamation-triangle warning-icon"
                        onClick={() =>
                          handleIconClick(post._id, selectedActions[post._id])
                        }
                      />
                    </div>
                  </td>
                  <td>
                    {post?.suspendedUntil ? (
                      <>
                        <span style={{ color: "red" }}>
                          {post?.suspendedUntil.split("T")[0]}
                        </span>
                      </>
                    ) : (
                      <span style={{ color: "green" }}>Active</span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      <div className="paginationAlign">
        <TablePagination
          rowsPerPageOptions={[10, 15, 20, 25, 30]}
          component="div"
          count={posts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
      <Modal isOpen={editModel} style={customStyles}>
        <button className="colseModal" onClick={() => setEditModel(false)}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Edit Coins
        </h3>

        <div className="addHostCharge">
          <input
            type="number"
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
          />
          <button onClick={editCoins}>Save</button>
        </div>
      </Modal>

      <Modal
        isOpen={isSuspensionModalOpen}
        style={customStyles}
        onRequestClose={handleSuspensionModalClose}
      >
        <button className="colseModal" onClick={handleSuspensionModalClose}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Suspend User
        </h3>
        <div className="addHostCharge">
          <input
            type="number"
            placeholder="Days"
            value={suspendDays}
            onChange={(e) => setSuspendDays(e.target.value)}
          />
          <button onClick={handleAdminSuspend}>Suspend</button>
        </div>
      </Modal>

      <Modal
        isOpen={isTerminationModalOpen}
        style={customStyles}
        onRequestClose={handleTerminationModalClose}
      >
        <button className="colseModal" onClick={handleTerminationModalClose}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Are you use you want to terminate this user?
        </h3>
        <div className="addHostCharge">
          <button onClick={handleAdminTerminate}>Terminate</button>
        </div>
      </Modal>

      <Modal
        isOpen={isWarningModalOpen}
        style={customStyles}
        onRequestClose={handleWarningModalClose}
      >
        <button className="colseModal" onClick={handleWarningModalClose}>
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Warning User
        </h3>
        <div className="addHostCharge">
          <input
            type="text"
            placeholder="Title"
            value={warningForm.title}
            onChange={(e) =>
              setWarningForm({ ...warningForm, title: e.target.value })
            }
          />
          <input
            type="text"
            placeholder="Body"
            value={warningForm.body}
            onChange={(e) =>
              setWarningForm({ ...warningForm, body: e.target.value })
            }
          />
          <button onClick={handleAdminWarning}>Warning</button>
        </div>
      </Modal>
      <Modal isOpen={removeSuspensionModalOpen} style={customStyles}>
        <button
          className="colseModal"
          onClick={handleRemoveSuspensionModalClose}
        >
          X
        </button>
        <h3 align="center" style={{ marginBottom: "50px" }}>
          Are you sure you want to remove suspension?
        </h3>
        <div className="addHostCharge">
          <button onClick={handleRemoveSuspensionModalClose}>Cancel</button>
          <button onClick={handleRemoveSuspension}>Remove</button>
        </div>
      </Modal>
    </>
  );
};

export default Details;
