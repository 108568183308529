import React from "react";

const Card = ({
  sname,
  subname,
  number,
  type = "",
  size = "",
  width = "",
  onClick,
}) => {
  return (
    <div className="card" style={{ width: width }}>
      <div className={`card_info ${type} ${size}`} onClick={onClick}>
        <div>
          <h1 className="card_title">{sname}</h1>
          <h2 className="card_subtitle">{subname}</h2>
        </div>
        <span className="card_category">{number}</span>
      </div>
    </div>
  );
};

export default Card;
