import React from "react";

const Progressbar = ({
  activeHost,
  activeUser,
  inactiveHost,
  inactiveUser,
}) => {

  let activeHostPercent = 0;
  let activeUserPercent = 0;

  if (activeHost + inactiveHost !== 0) {
    activeHostPercent = (activeHost / (activeHost + inactiveHost)) * 100;
  }

  if (activeUser + inactiveUser !== 0) {
    activeUserPercent = (activeUser / (activeUser + inactiveUser)) * 100;
  }

  return (
    <div
      className="Users"
      style={{
        marginLeft: "90px",
        marginTop: "20px",
        marginBottom: "20px",
        width: "60vw",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <h2>Users </h2>
        <p> ({activeUserPercent.toFixed(2)}%) </p>
      </div>
      <div
        style={{
          width: "95%",
          height: "20px",
          border: "1px solid grey",
          overflow: "hidden",
          borderRadius: "5px",
        }}
      >
        <div
          style={{
            width: `${activeUserPercent}%`,
            height: "100%",
            background: "orange",
          }}
        ></div>
      </div>
      <div
        className="Host"
        style={{
          // marginLeft: "5px",
          marginTop: "20px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "10px",

            alignItems: "center",
          }}
        >
          <h2>Host </h2>
          <p> ({activeHostPercent.toFixed(2)}%)</p>
        </div>
        <div
          style={{
            width: "95%",
            height: "20px",
            border: "1px solid grey",
            overflow: "hidden",
            borderRadius: "5px",
          }}
        >
          <div
            style={{
              width: `${activeHostPercent}%`,
              height: "100%",
              background: "orange",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Progressbar;
