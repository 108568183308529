import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../../../components/Header";
import api from "../../../components/api";

import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";

export default function HostAction() {
  let params = useParams();
  let id = params.id;

  let navigate = useNavigate();
  const [host, setHost] = useState("");

  const getHost = async () => {
    axios

      .get(api + `/findOneHostrequest/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setHost(res.data.getOneHost);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getHost();
  }, []);

  const handleAccept = (id) => {
    axios
      .put(
        api + `/accept/hostRequest/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setTimeout(() => {
          navigate("/acceptedHost");
        }, 3000);
      })
      .catch((error) => {
        toast.success("Host Not Accepted");
        console.log(error);
      });
  };

  const handleReject = (id) => {
    axios
      .put(
        api + `/reject/hostRequest/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Host Rejected");
        setTimeout(() => {
          navigate("/rejectedHost");
        }, 3000);
      })
      .catch((error) => {
        toast.success("Host Not Rejected");
        console.log(error);
      });
  };

  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Host Action" />
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex">
            <div className="imgBox">
              {console.log(host)}
              <img
                src={host?.userImage}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>User Image</p>
            </div>

            <div className="imgBox2">
              <video width="100%" height="200px" controls>
                <source src={host?.videoProfile} type="video/mp4" />
              </video>
              <p>Video Profile</p>
            </div>
            <div className="imgBox">
              <img
                src={host?.AadharCardFrontPicture}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>AadharCard Front Image</p>
            </div>
            <div className="imgBox">
              <img
                src={host?.AadharCardBackPicture}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>AadharCard Back Image</p>
            </div>
          </div>
          <div className="ARbtns">
            <button className="accptBtn" onClick={() => handleAccept(id)}>
              Accept
            </button>
            <button className="rejectBtn" onClick={() => handleReject(id)}>
              Reject
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </LeaderLayOut>
  );
}
