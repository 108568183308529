import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import api from "../components/api";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import ActionModal from "./ActionModal";

export default function UploadStickerImage() {
  let params = useParams();
  let id = params.id;
  //---------------------------getOneGift----------------------------------//
  const [oneSticker, setoneSticker] = useState("");
  const getoneSticker = async () => {
    axios
      .get(api + `/findoneAllsticker/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setoneSticker(res.data.getonedata);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getoneSticker();
  }, []);
  //-----------------------------------------------------------------------//
  const [openModal,setOpenModal] = useState(false)
  //----------------------------------UploadImage-------------------------------------//
  const [image, setImage] = useState("");
  const [load, setLoad] = useState(false);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUploadImage = () => {
    setLoad(true);
    axios
      .put(
        api + `/updateStickerPicture/${id}`,
        { image: image },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Sticker Uploaded");
        setTimeout(() => {
          setOpenModal(true);
        }, 3000);
      })
      .catch((e) => {
        setLoad(false);
        toast.error("Sticker Image Not Uploaded");
        console.log("error", e);
      });
  };

  //-----------------------------------------------------------------------//

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Sticker Image" />
      </div>
      <div className="addbannermain">
        <div className="addinput">
          Sticker Name
          <input
            className="addinputtext smallInput"
            type="text"
            value={oneSticker?.name}
          />
        </div>

        <div className="briefdescription">
          Sticker Price
          <input
            className="addinputtext "
            type="number"
            value={oneSticker?.coins}
          />
        </div>
        <div className="addchoosetext mt-5">Upload Image</div>
        <div className="choosefile">
          <input type="file" id="actual-btn" 

          onChange={handleImage} />
        </div>
        <div className="savecancelbutton">
          <button
            className="accptBtn imgBtn"
            type="submit"
            value="Submit"
            onClick={() => handleUploadImage()}
          >
            Upload
          </button>
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}

      <ToastContainer />
      <ActionModal openM={openModal} openMM={setOpenModal} keyWord="Sticker"  addLink="/addSticker" listLink="/listSticker"/>
    </LayOut>
  );
}
