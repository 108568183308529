import React, { useEffect } from "react";

import api from "./api";

import axios from "axios";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const Chart = () => {
  const [traffic, setTraffic] = React.useState([]);
  const id = localStorage.getItem("leaderId");

  const getHostTraffic = async () => {
    await axios
      .get(api + `/getLeaderMonthHost/` + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        console.log(res.data.result);
        setTraffic(res.data.result);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getHostTraffic();
  }, []);

  const rearrangedDataHost = Array(12).fill(null);
  for (let i = 0; i < traffic.length; i++) {
    rearrangedDataHost[traffic[i].month - 1] = traffic[i];
  }

  const pdata = [
    {
      name: "January",

      Host: rearrangedDataHost[0]?.count,
      // amt: 2400,
    },
    {
      name: "February",
      Host: rearrangedDataHost[1]?.count,
      // amt: 2210,
    },
    {
      name: "March",
      Host: rearrangedDataHost[2]?.count,
      // amt: 2290,
    },
    {
      name: "April",
      Host: rearrangedDataHost[3]?.count,
      // amt: 2000,
    },
    {
      name: "May",
      Host: rearrangedDataHost[4]?.count,
      // amt: 2181,
    },
    {
      name: "June",
      Host: rearrangedDataHost[5]?.count,
      // amt: 2500,
    },
    {
      name: "July",
      Host: rearrangedDataHost[6]?.count,
      // amt: 2100,
    },
    {
      name: "August",
      Host: rearrangedDataHost[7]?.count,
    },
    {
      name: "September",
      Host: rearrangedDataHost[8]?.count,
    },
    {
      name: "October",
      Host: rearrangedDataHost[9]?.count,
    },
    {
      name: "November",
      Host: rearrangedDataHost[10]?.count,
    },
    {
      name: "December",
      Host: rearrangedDataHost[11]?.count,
    },
  ];

  return (
    <div className="back">
      <div className="chart-heading">
        <h1> Traffic </h1>
        <p> Of Year {traffic[0]?.year}</p>
      </div>

      <div className="chart-design">
        <BarChart
          width={1000}
          height={400}
          data={pdata}
          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
          <XAxis dataKey="name" tick={{ fill: "#333" }} />
          <YAxis tick={{ fill: "#333" }} />
          <Tooltip
            contentStyle={{
              backgroundColor: "#333",
              border: "none",
              color: "#fff",
            }}
          />
          <Legend verticalAlign="top" align="right" />
          <Bar
            dataKey="Host"
            fill="#8884d8"
            barSize={30} // Adjust the width of the bars
          >
            <LabelList dataKey="Host" position="top" />
          </Bar>
        </BarChart>
      </div>
    </div>
  );
};

export default Chart;
