import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LeaderProtectRoute = (props) => {
  const Component = props.Component;

  const navigate = useNavigate();

  useEffect(() => {
    let login = localStorage.getItem("leaderToken");

    if (!login) {
      navigate("/leader");
    }
  }, []);

  return (
    <div>
      <Component />
    </div>
  );
};

export default LeaderProtectRoute;
