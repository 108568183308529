import "./App.css";
import Allusers from "./pages/Allusers";
import Dashboard from "./pages/Dashboard";
import Hostrequest from "./pages/Hostrequest";
import Hostearnings from "./pages/Hostearings";
import Addbanner from "./pages/Addbanner";
import ListBanner from "./pages/ListBanner";
import Createwallet from "./pages/Createwallet";
import Listwallet from "./pages/Listwallet";
import Addgifts from "./pages/Addgifts";
import Listgifts from "./pages/Listgifts";
import Addemoji from "./pages/Addemoji";
import Listemoji from "./pages/Listemoji";
import Topearningsgirls from "./pages/Topearningsgirls";
import Login from "./pages/Login";
import OneUser from "./pages/OneUser";
import HostAction from "./pages/HostAction";
import AcceptedHost from "./pages/AcceptedHost";
import RejectedHost from "./pages/RejectedHost";
import HostDetail from "./pages/HostDetail";
import HostReport from "./pages/HostReport";
import TopUsers from "./pages/TopUsers";
import UploadImage from "./pages/UploadImage";
import UploadEmojiImage from "./pages/UploadEmojiImage";
import UploadBannerImage from "./pages/UploadBannerImage";
import UserReport from "./pages/UserReport";
import AddSticker from "./pages/AddSticker";
import ListSticker from "./pages/ListSticker";
import UploadStickerImage from "./pages/uploadStickerImage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectRoute from "./components/ProtectRoute";
import SendNotification from "./pages/SendNotification";
import NotificationList from "./pages/NotificationList";
import RejectHostDetail from "./pages/RejectHostDetail";
import AddLeader from "./pages/AddLeader";
import LeaderList from "./pages/LeaderList";
import LeaderWrapper from "./pages/leader/LeaderWrapper";
import LeaderHostRequest from "./pages/leader/host/LeaderHostRequest";
import LeaderAcceptedHost from "./pages/leader/host/LeaderAcceptedHost";
import LeaderRejectedHost from "./pages/leader/host/LeaderRejectedHost";
import LeaderHostEarnings from "./pages/leader/host/LeaderHostEarnings";
import MessageProvider from "./base/context/MessageProvider";
import LeaderHostReports from "./pages/leader/host/LeaderHostReports";
import LeaderHostList from "./pages/leader/host/LeaderHostList";
import LeaderHostDetails from "./pages/leader/host/LeaderHostDetails";
import UserFeedback from "./pages/UserFeedback";
import HostFeedback from "./pages/HostFeedback";
import LeaderHostAction from "./pages/leader/host/LeaderHostAction";
import LeaderProtectRoute from "./components/LeaderProtectRoute";

function App() {
  return (
    <MessageProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/oneUser/:id"
            element={<ProtectRoute Component={OneUser} />}
          />
          <Route
            path="/hostAction/:id"
            element={<ProtectRoute Component={HostAction} />}
          />

          <Route
            path="/hostReport"
            element={<ProtectRoute Component={HostReport} />}
          />
          <Route
            path="/dashboard"
            element={<ProtectRoute Component={Dashboard} />}
          />

          <Route
            path="/allUsers"
            element={<ProtectRoute Component={Allusers} />}
          />
          <Route
            path="/hostrequest"
            element={<ProtectRoute Component={Hostrequest} />}
          />
          <Route
            path="/hostearnings"
            element={<ProtectRoute Component={Hostearnings} />}
          />
          <Route
            path="/addbanner"
            element={<ProtectRoute Component={Addbanner} />}
          />
          <Route
            path="/listbanner"
            element={<ProtectRoute Component={ListBanner} />}
          />
          <Route
            path="/createwallet"
            element={<ProtectRoute Component={Createwallet} />}
          />
          <Route
            path="/listwallet"
            element={<ProtectRoute Component={Listwallet} />}
          />
          <Route
            path="/addgifts"
            element={<ProtectRoute Component={Addgifts} />}
          />
          <Route
            path="/listgifts"
            element={<ProtectRoute Component={Listgifts} />}
          />
          <Route
            path="/addemoji"
            element={<ProtectRoute Component={Addemoji} />}
          />
          <Route
            path="/listemoji"
            element={<ProtectRoute Component={Listemoji} />}
          />
          <Route
            path="/topearningsgirls"
            element={<ProtectRoute Component={Topearningsgirls} />}
          />
          <Route
            path="/acceptedHost"
            element={<ProtectRoute Component={AcceptedHost} />}
          />
          <Route
            path="/rejectedHost"
            element={<ProtectRoute Component={RejectedHost} />}
          />
          <Route
            path="/HostDetail/:id"
            element={<ProtectRoute Component={HostDetail} />}
          />
          <Route
            path="/topUsers"
            element={<ProtectRoute Component={TopUsers} />}
          />
          <Route
            path="/uploadImage/:id"
            element={<ProtectRoute Component={UploadImage} />}
          />
          <Route
            path="/uploadEmojiImage/:id"
            element={<ProtectRoute Component={UploadEmojiImage} />}
          />
          <Route
            path="/uploadBannerImage/:id"
            element={<ProtectRoute Component={UploadBannerImage} />}
          />

           <Route
            path="/userFeedback" element={<ProtectRoute Component={UserFeedback} />}
          />

          <Route
            path="hostFeedback" element={<ProtectRoute Component={HostFeedback} />}
            />
            


          <Route
            path="/userReport"
            element={<ProtectRoute Component={UserReport} />}
          />
          <Route
            path="/addSticker"
            element={<ProtectRoute Component={AddSticker} />}
          />
          <Route
            path="/listSticker"
            element={<ProtectRoute Component={ListSticker} />}
          />
          <Route
            path="/uploadStickerImage/:id"
            element={<ProtectRoute Component={UploadStickerImage} />}
          />
          <Route
            path="/sendNotification"
            element={<ProtectRoute Component={SendNotification} />}
          ></Route>
          <Route
            path="/notificationList"
            element={<ProtectRoute Component={NotificationList} />}
          ></Route>
          <Route
            path="/rejectHostDetail/:id"
            element={<ProtectRoute Component={RejectHostDetail} />}
          ></Route>

          <Route
            path="/leader"
            element={<LeaderProtectRoute Component={LeaderWrapper} />}
          />

          <Route
            path="/leader/allHosts"
            element={<LeaderProtectRoute Component={LeaderHostList} />}
          />

          <Route
            path="/leader/hostrequest"
            element={<LeaderProtectRoute Component={LeaderHostRequest} />}
          />
          <Route
            path="/leader/HostDetail/:id"
            element={<LeaderProtectRoute Component={LeaderHostDetails} />}
          />
          <Route
          
            path="/leader/hostAction/:id"
            element={<LeaderProtectRoute Component={LeaderHostAction} />}
          />

          <Route
            path="/leader/acceptedHost"
            element={<LeaderProtectRoute Component={LeaderAcceptedHost} />}
          />

          <Route
            path="/leader/rejectedHost"
            element={<LeaderProtectRoute Component={LeaderRejectedHost} />}
          />

          <Route
            path="/leader/hostearnings"
            element={<LeaderProtectRoute Component={LeaderHostEarnings} />}
          />

          <Route
            path="/leader/hostReport"
            element={<LeaderProtectRoute Component={LeaderHostReports} />}
          />

          <Route
            path="/addLeader"
            element={<ProtectRoute Component={AddLeader} />}
          />

          <Route
            path="/leaderList"
            element={<ProtectRoute Component={LeaderList} />}
          />
        </Routes>
      </BrowserRouter>
    </MessageProvider>
  );
}

export default App;
