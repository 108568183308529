import React, { useEffect, useState, useRef } from "react";
import api from "../components/api";
import axios from "axios";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";

export default function HostAction() {
  let params = useParams();
  let id = params.id;

  let navigate = useNavigate();
  const [host, setHost] = useState("");
  const [video, setVideo] = useState("");

  const videoRef = useRef(null);

  const getHost = async () => {
    axios
      .get(api + `/findOneHostrequest/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setHost(res.data.getOneHost);
        const video = res.data.getOneHost.videoProfile;
        setVideo(video);
        console.log(video);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getHost();
  }, []);

  const handleAccept = (id) => {
    axios
      .put(
        api + `/accept/hostRequest/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Host Accepted");
        setTimeout(() => {
          navigate("/acceptedHost");
        }, 3000);
      })
      .catch((error) => {
        toast.success("Host Not Accepted");
        console.log(error);
      });
  };

  const handleReject = (id) => {
    axios
      .put(
        api + `/reject/hostRequest/${id}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Host Rejected");
        setTimeout(() => {
          navigate("/rejectedHost");
        }, 3000);
      })
      .catch((error) => {
        toast.success("Host Not Rejected");
        console.log(error);
      });
  };


  return (
    <LayOut>
      <div className="header">
        <Header title="Host Action" />
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex">
            <div className="imgBox">
              {console.log(host)}
              <img
                src={host?.userImage}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>User Image</p>
            </div>

            <div className="imgBox2">
            <ReactPlayer
                url={video}
                controls={true}
                width="100%"
                height="auto"
              />
              <p>Video Profile</p>

            
            </div>
            <div className="imgBox">
              <img
                src={host?.AadharCardFrontPicture}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>AadharCard Front Image</p>
            </div>
            <div className="imgBox">
              <img
                src={host?.AadharCardBackPicture}
                className="oneUserImg22"
                alt="urlnotset"
              />
              <p>AadharCard Back Image</p>
            </div>
          </div>
          <div className="ARbtns">
            <button className="accptBtn" onClick={() => handleAccept(id)}>
              Accept
            </button>
            <button className="rejectBtn" onClick={() => handleReject(id)}>
              Reject
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </LayOut>
  );
}
