import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../../../components/Header";
import List from "../../../components/List";
import api from "../../../components/api";
import Modal from "react-modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import Spinner from "../../../components/Spinner";
import moment from "moment";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";
import { useMessage } from "../../../base/context/MessageProvider";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const LeaderHostReports = () => {
  const message = useMessage();
  const [leaderHostReports, setLeaderHostReports] = useState([]);
  const [load, setLoad] = useState(false);

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/getLeaderHostReports", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderHostReports(res.data.findreport);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  //
  //handleImage-------------------------------------------------------------//
  //

  const [imageurl, setImageurl] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const handleImage = (val) => {
    setImageModal(true);
    setImageurl(val);
  };

  const hostReportTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Host Report" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={hostReportTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={hostReportTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                {/* <th>Report Id</th> */}
                {/* <th>Host Name</th> */}
                {/* <th>Host Phone</th> */}
                {/* <th>User Name</th> */}
                {/* <th>User Phone</th> */}
                <th>Report Reason</th>
                <th>Report Image</th>
                <th>CreatedAt</th>
                <th>UpdatedAt</th>
              </tr>
            </thead>
            <tbody>
              {leaderHostReports?.length === 0 ? (
                <tr>
                  <td colSpan="1">No Report found</td>
                </tr>
              ) : (
                leaderHostReports
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= weekDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= monthDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <tr>
                        <td>{index + 1 + rowsPerPage * page}</td>
                        {/* <td>{item._id}</td>
                      <td>{item.userId.fullName}</td>
                      <td>{item.userId.phone}</td>
                      <td>
                        {item.targetId.FirstName + item.targetId.LastName}
                      </td>
                      <td>{item.targetId.number}</td> */}
                        <td>{item.Choose_the_Reason}</td>
                        <td onClick={() => handleImage(item.ReportImage)}>
                          <i class="fa-solid fa-eye"></i>
                        </td>
                        <td>{item?.createdAt.split("T")[0]}</td>
                        <td>{item?.updatedAt.split("T")[0]}</td>
                      </tr>
                    );
                  })
              )}
            </tbody>
          </table>
        </div>

        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={leaderHostReports.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <Modal isOpen={imageModal} style={customStyles}>
        <button className="colseModal" onClick={() => setImageModal(false)}>
          X
        </button>
        <img src={imageurl} className="bannerImage" alt="ReportImage"></img>
      </Modal>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LeaderLayOut>
  );
};

export default LeaderHostReports;
