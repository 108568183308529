import { TablePagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../base/context/MessageProvider";
import Header from "../../../components/Header";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";
import List from "../../../components/List";
import Spinner from "../../../components/Spinner";
import api from "../../../components/api";
const LeaderHostRequest = () => {
  const message = useMessage();
  const [leaderHostRequests, setLeaderHostRequests] = useState([]);
  const [load, setLoad] = useState(false);
  const id = localStorage.getItem("leaderId");

  const getPost = () => {
    setLoad(true);
    axios
      .get(api + "/leader/getLeaderHostRequests/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderHostRequests(res.data.getallHost.reverse());
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  let navigate = useNavigate();
  const handleAction = (id) => {
    navigate(`leader/hostAction/${id}`);
  };

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  const TopHostTableRef = useRef(null);

  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Host Request" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={TopHostTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table
            className="content-table"
            ref={TopHostTableRef}
            style={{ width: "120%" }}
          >
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Age</th>
                <th>City</th>
                <th>Email ID</th>
                <th>Phone</th>
                <th>Created At</th>
                <th>AadharCardNo</th>
              </tr>
            </thead>
            <tbody>
              {leaderHostRequests?.length === 0 ? (
                <tr>
                  <td colSpan="1">No Request is pending</td>
                </tr>
              ) : (
                leaderHostRequests
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= weekDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= monthDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  .map((post, index) => (
                    <tr key={post.index}>
                      {console.log(post)}
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{post?.userId}</td>
                      <td>{post?.FirstName + " " + post?.LastName}</td>
                      <td>{post.Gender}</td>
                      <td>{post?.dateOfBirth.substring(0, 10)}</td>
                      <td>{post?.age}</td>
                      <td>{post?.city}</td>
                      <td>
                        {(post?.email || "").substring(0, 4) +
                          "*".repeat(
                            Math.max(0, (post?.email || "").length - 4)
                          )}
                      </td>
                      <td>
                        {post?.number
                          ? "*".repeat(
                              Math.max(0, (post.number + "").length - 2)
                            ) + post.number.toString().slice(-2)
                          : ""}
                      </td>
                      <td>{post?.createdAt.split("T")[0]}</td>
                      <td>
                        {(post?.AadharCardNo || "").substring(0, 2) +
                          "*".repeat(
                            Math.max(0, (post?.AadharCardNo || "").length - 2)
                          )}
                      </td>
                      {/* <td
            onClick={() => handleAction(post?._id)}
            style={{
              color: "#116FCD",
              fontWeight: "500",
              fontSize: "16px",
            }}
          >
            View
          </td> */}
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25]}
            component="div"
            count={leaderHostRequests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LeaderLayOut>
  );
};

export default LeaderHostRequest;
