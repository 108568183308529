import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Cards from "../../components/Cards";
import Chart from "../../components/ChartLeader";
import LeaderLayOut from "../../components/LeaderLayout/LeaderLayout";
import Progressbar from "../../components/ProgressbarLeader";
import api from "../../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "80%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    height: "80%",
  },
};

Modal.setAppElement("#root");

const LeaderDashboard = () => {
  const [activeHost, setActiveHost] = useState(0);
  const [inactiveHost, sectInactiveHost] = useState(0);
  // const [activeUser, setActiveUser] = useState(0);
  // const [inactiveUser, setInactiveUser] = useState(0);
  // const [coinPurchase, setCoinPurchase] = useState(0);
  // const [coinAmount, setcoinAmount] = useState(0);
  const [newRequest, setNewRequest] = useState("");
  const [total, setTotal] = useState(0);
  const id = localStorage.getItem("leaderId");

  const [activeHostModalisOpen, setActiveHostModalisOpen] = useState(false);
  const [inActiveHostModal, setInActiveHostModal] = useState(false);

  const handleActiveHostModalOpen = () => {
    setActiveHostModalisOpen(true);
  };

  const handleActiveHostModalClose = () => {
    setActiveHostModalisOpen(false);
  };

  const handleInActiveHostModalOpen = () => {
    setInActiveHostModal(true);
  };

  const handleInActiveHostModalClose = () => {
    setInActiveHostModal(false);
  };

  const handleActiveHost = () => {
    axios
      .get(api + `/getLeaderOnlineCount/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setActiveHost(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleInActiveHost = () => {
    axios
      .get(api + `/getLeaderOfflineCount/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        sectInactiveHost(res.data);
      })
      .catch((error) => {
        console.log("ofline", error);
      });
  };

  const handleNewRequest = () => {
    axios
      .get(api + "/leader/getLeaderHostRequests/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setNewRequest(res.data.getallHost.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleTotalCoins = () => {
    axios
      .get(api + "/getallLeaderHostEarning/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setTotal(res.data.result);
        console.log("total coins", res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const handleCoinPurchase = () => {
  //   axios
  //     .get(api + "/adminFindAllbalance", {
  //       headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
  //     })
  //     .then((res) => {
  //       setCoinPurchase(res.data.total);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };

  const [leader, setLeader] = useState(null);

  const getLeader = () => {
    axios
      .get(api + `/leader/showProfileLeader`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeader(res.data?.result);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleNewRequest();
    // handleCoinPurchase();
    handleActiveHost();
    handleInActiveHost();
    handleTotalCoins();
    getLeader();
  }, []);

  const Carddata = [
    {
      sname: "Active",
      subname: "Host",
      number: activeHost?.result,
      type: "card-1",
      size: "small-size",
      onClick: handleActiveHostModalOpen,
    },
    {
      sname: "Inactive",
      subname: "Host",
      number: inactiveHost?.result,
      type: "card-2",
      size: "small-size",
      onClick: handleInActiveHostModalOpen,
    },
    // {
    //   sname: "Coin",
    //   subname: "Purchase",
    //   number: coinPurchase,
    //   type: "card-5",
    //   size: "small-size",
    // },
    // {
    //   sname: "Coin",
    //   subname: "Amount",
    //   number: coinAmount,
    //   type: "card-6",
    //   size: "small-size",
    // },
    {
      sname: "New_Request",
      subname: "Of_Host",
      number: newRequest,
      type: "card-7",
      size: "small-size",
    },
    {
      sname: "Total",
      subname: "Total Coins",
      number: total,
      type: "card-8",
      size: "small-size",
    },
  ];

  return (
    <>
      <LeaderLayOut>
        <h1
          style={{
            margin: "20px",
          }}
        >
          Welcome {leader?.leaderName}
        </h1>
        <div className="card">
          {Carddata.map((card, index) => (
            <Cards
              key={index}
              sname={card.sname}
              subname={card.subname}
              number={card.number}
              type={card.type}
              size={card.size}
              width="250px"
              onClick={card.onClick}
            />
          ))}
        </div>
        <Chart />
        <Progressbar
          activeHost={activeHost.result}
          inactiveHost={inactiveHost.result}
        />
        <Modal
          isOpen={activeHostModalisOpen}
          style={customStyles}
          onRequestClose={handleActiveHostModalClose}
        >
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Active Host
          </h2>

          <table className="cardTable" style={{ width: "100%" }}>
            <thead>
              <tr className="cardTableHeader">
                <th>ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>DOB</th>
                <th>Age</th>
              </tr>
            </thead>
            <tbody>
              {activeHost?.data?.map((item, index) => (
                <tr key={item._id} className="cardTableRow">
                  <td>{item?._id}</td>
                  <td>{item?.FirstName}</td>
                  <td>{item?.Gender}</td>
                  <td>{item?.email}</td>
                  <td>{item?.number}</td>
                  <td>{item?.city}</td>
                  <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                  <td>{item?.age}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>

        <Modal
          isOpen={inActiveHostModal}
          style={customStyles}
          onRequestClose={handleInActiveHostModalClose}
        >
          <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
            Inactive Host
          </h2>

          <table className="cardTable" style={{ width: "100%" }}>
            <thead>
              <tr className="cardTableHeader">
                <th>ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Email</th>
                <th>Phone</th>
                <th>City</th>
                <th>DOB</th>
                <th>Age</th>
              </tr>
            </thead>

            <tbody>
              {inactiveHost?.data?.map((item, index) => (
                <tr key={item._id} className="cardTableRow">
                  <td>{item?._id}</td>
                  <td>{item?.FirstName}</td>
                  <td>{item?.Gender}</td>
                  <td>{item?.email}</td>
                  <td>{item?.number}</td>
                  <td>{item?.city}</td>
                  <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                  <td>{item?.age}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      </LeaderLayOut>
    </>
  );
};

export default LeaderDashboard;
