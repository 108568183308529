import React, { useState } from "react";
import { AiOutlineMenu } from "react-icons/ai";
import logout from "../../assets/AllUserImages/logout.png";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { toast, ToastContainer } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const LeaderNavbar = () => {
  const navigate = useNavigate();
  let date = new Date();
  const [time, setTime] = useState(date.toLocaleString());

  setInterval(() => {
    date = new Date();
    setTime(date.toLocaleString());
  }, 1000);

  const handleSideBar = () => {
    let elem = document.getElementById("sidebar");
    if (elem.style.display === "none") {
      elem.style.display = "block";
    } else {
      elem.style.display = "none";
    }
  };

  const handleLogOut = () => {
    localStorage.clear("leaderToken");
    navigate("/leader");
    navigate(0);
  };

  return (
    <>
      <div className="nav">
        <div className="burger" onClick={handleSideBar}>
          <AiOutlineMenu size={30} color="white" />
        </div>

        <div className="nav-list">
          <ul className="menu">
            <li className="menu-item">
              <p className="date-text">{time}</p>
            </li>

            <ul className="menu-item1">
              <button className="log" onClick={() => handleLogOut()}>
                Logout
                <img
                  className="navlogimg"
                  src={logout}
                  alt="error"
                  style={{ transform: `rotate(180deg)` }}
                />
              </button>
            </ul>
          </ul>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default LeaderNavbar;
