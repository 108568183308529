import React from "react";
import { FaTimes } from "react-icons/fa";
import { motion } from "framer-motion";

const animation = {
	initial: { opacity: 0, y: -100 },
	animate: { opacity: 1, y: 0 },
	exit: { opacity: 0, y: -100 },
};

const Modal = ({ setIsVisible, post }) => {
	return (
		<motion.div
			className="modal-container"
			variants={animation}
			initial="initial"
			animate="animate"
			exit="exit"
			transition={{
				duration: 0.5,
			}}
		>
			<div className="modal-content">
				<div className="close-Icon">
					<FaTimes onClick={() => setIsVisible(false)} />
				</div>
				<div>
					<img className="photo" src={post} alt="Profile Picture" />
				</div>
			</div>
		</motion.div>
	);
};

export default Modal;
