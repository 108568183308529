import { TablePagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import List from "../components/List";
import Spinner from "../components/Spinner";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

Modal.setAppElement("#root");

const Listwallet = () => {
  const [tableData, setTableData] = useState([]);
  const [load, setLoad] = useState(false);
  const getList = async () => {
    setLoad(true);
    axios

      .get(api + "/admin/getWallet", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setTableData(res.data.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getList();
  }, []);

  const [updateModal, setUpdateModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [oneDataCoin, setOneDataCoin] = useState("");
  const [oneDataPrice, setOneDataPrice] = useState("");
  const [oneDataOffer, setOneDataOffer] = useState("");
  const [oneDataId, setOneDataId] = useState("");

  const handleCoins = (e) => {
    setOneDataCoin(e.target.value);
  };

  const handlePrice = (e) => {
    setOneDataPrice(e.target.value);
  };

  const handleOffer = (e) => {
    setOneDataOffer(e.target.value);
  };

  const handleEditModal = (id) => {
    setUpdateModal(true);

    axios
      .get(api + `/findOnewallet/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setOneDataId(res.data.getWallet._id);
        setOneDataCoin(res.data.getWallet.coins);
        setOneDataPrice(res.data.getWallet.price);
        setOneDataOffer(res.data.getWallet.offer);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleDeleteModal = (id) => {
    setDeleteModal(true);
    axios
      .get(api + `/findOnewallet/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setOneDataId(res.data.getWallet._id);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdate = (id) => {
    setLoad(true);
    axios
      .put(
        api + `/updateWalletAdmin/${id}`,
        {
          coins: oneDataCoin,
          price: oneDataPrice,
          offer: oneDataOffer,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Deal Updated");
        getList();
      })
      .catch((error) => {
        setLoad(false);
        toast.error("Deal Not Updated");
        console.log(error);
      });
    setUpdateModal(false);
  };

  const handleDelete = (id) => {
    setLoad(true);
    axios
      .delete(api + `/admin/deleteWallet/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        setLoad(false);
        toast.success("Deal Deleted");
        getList();
      })
      .catch((error) => {
        setLoad(false);
        toast.error("Deal Not Deleted");
        console.log(error);
      });

    setDeleteModal(false);
  };

  const walletListTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LayOut>
      <div className="header">
        <Header title="Deal List" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={walletListTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={walletListTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Id</th>
                <th>Coins</th>
                <th>Price</th>
                <th>Offer</th>
                <th>createdAt</th>
                <th>updatedAt</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {tableData
                ?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                  } else if (
                    item.createdAt.split("T")[0].includes(todayDate) &&
                    !chooseDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item.createdAt.split("T")[0].includes(chooseDate) &&
                    !todayDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item.createdAt >= weekDate &&
                    item.createdAt <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item.createdAt >= monthDate &&
                    item.createdAt <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !weekDate
                  ) {
                    return item;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{item._id}</td>
                      <td>{item.coins}</td>
                      <td>{item.price}</td>
                      <td>{item.offer}</td>
                      <td>{item.createdAt.split("T")[0]}</td>
                      <td>{item.updatedAt.split("T")[0]}</td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square ListEdit"
                          onClick={() => handleEditModal(item._id)}
                        ></i>
                        <i
                          class="fa-solid fa-trash ListDelte"
                          onClick={() => handleDeleteModal(item._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={tableData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>

      <Modal isOpen={updateModal} style={customStyles}>
        <button className="colseModal" onClick={() => setUpdateModal(false)}>
          X
        </button>
        <div className="updateModal">
          <label for="Coins">Coins</label>
          <br />
          <input
            type="number "
            id="Coins"
            value={oneDataCoin}
            onChange={handleCoins}
          ></input>
          <br />
          <label for="Price">Price</label>
          <br />
          <input
            type="number "
            id="Price"
            value={oneDataPrice}
            onChange={handlePrice}
          ></input>
          <br />
          <label for="Offer">Offer</label>
          <br />
          <input
            type="text "
            id="Offer"
            value={oneDataOffer}
            onChange={handleOffer}
          ></input>
          <div className="updateBtns">
            <button
              className="UpdateBtn"
              onClick={() => handleUpdate(oneDataId)}
            >
              Update
            </button>
            <button className="CancelBtn" onClick={() => setUpdateModal(false)}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={deleteModal} style={customStyles}>
        <button className="colseModal" onClick={() => setDeleteModal(false)}>
          X
        </button>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Are you Sure You Want To Delete this Wallet?
        </h3>
        <div className="updateBtns">
          <button className="UpdateBtn" onClick={() => handleDelete(oneDataId)}>
            Delete
          </button>
          <button className="CancelBtn" onClick={() => setDeleteModal(false)}>
            Cancel
          </button>
        </div>
      </Modal>
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </LayOut>
  );
};

export default Listwallet;
