import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import List from "../components/List";
import Spinner from "../components/Spinner";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

Modal.setAppElement("#root");

const AcceptedHost = () => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState(false);
  const [leaderModal, setLeaderModal] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);
  const [hostId, setHostId] = useState("");
  const [charge, setCharge] = useState("");
  const [leaderList, setLeaderList] = useState([]);
  const [leaderId, setLeaderId] = useState("");

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/getAcceptHost", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setPosts(res.data.getAcceptHost.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  // console.log("posts", posts);

  let navigate = useNavigate();
  const handleAction = (id) => {
    navigate(`/HostDetail/${id}`);
  };

  const handleAddChargeModal = (e) => {
    setChargeModal(true);
    setHostId(e);
  };

  const handleCharge = (e) => {
    setCharge(e.target.value);
  };

  const handleAddHostCharge = () => {
    setLoad(true);
    axios
      .put(
        api + `/adminAdd/Hostuserfress/${hostId}`,
        { hostuser_fees: charge },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Host Charge Per Minute Added");
        getPost();
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });

    setChargeModal(false);
  };

  const acceptTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//
  const handleLeaderList = async () => {
    axios
      .get(api + "/admin/getAllLeader", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLeaderList(res.data.result);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    handleLeaderList();
  }, []);

  const handleAddLeaderModal = (e) => {
    setLeaderModal(true);
    setHostId(e);
  };

  const handleAssignLeader = (e) => {
    setLeaderId(e.target.value);
  };

  const handleHostAddtoLeader = () => {
    axios
      .put(
        api + `/admin/addHostIngroup/${leaderId}`,
        {
          host_id: hostId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Leader Assigned Successfully");
      })

      .catch((e) => {
        console.log("errorerror", e);
      });
  };

  const handleAddLeader = () => {
    setLoad(true);
    axios
      .put(
        api + `/addleaderId/${hostId}`,
        {
          leader: leaderId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setLoad(false);
        getPost();
        setLeaderModal(false);
        handleHostAddtoLeader();
      })

      .catch((e) => {
        setLoad(false);
        console.log("e", e);
        toast.error("Leader Not Assigned");
        setLeaderModal(false);
        getPost();
      });
  };

  const [selectedActions, setSelectedActions] = useState({});
  const [actionId, setActionId] = useState("");

  const [isSuspensionModalOpen, setIsSuspensionModalOpen] = useState(false);
  const [isTerminationModalOpen, setIsTerminationModalOpen] = useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningForm, setWarningForm] = useState({
    title: "",
    body: "",
  });

  const [suspendDays, setSuspendDays] = useState("");

  const handleSuspensionModalOpen = () => {
    setIsSuspensionModalOpen(true);
  };

  const handleSuspensionModalClose = () => {
    setIsSuspensionModalOpen(false);
  };

  const handleTerminationModalOpen = () => {
    setIsTerminationModalOpen(true);
  };

  const handleTerminationModalClose = () => {
    setIsTerminationModalOpen(false);
  };

  const handleWarningModalOpen = () => {
    setIsWarningModalOpen(true);
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleSelectAction = (postId, selectedAction) => {
    setSelectedActions((prevSelectedActions) => ({
      ...prevSelectedActions,
      [postId]: selectedAction,
    }));
  };

  const handleIconClick = (postId, action) => {
    if (action === "warning") {
      handleWarningModalOpen();
    }
    if (action === "suspend") {
      handleSuspensionModalOpen();
    }

    if (action === "terminate") {
      handleTerminationModalOpen();
    }
    if (action === "removeSuspension") {
      handleRemoveSuspensionModalOpen();
    }
    setActionId(postId);
  };

  const handleAdminSuspend = () => {
    axios
      .put(
        api + `/adminSuspendHost`,
        {
          id: actionId,
          days: suspendDays,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        getPost();
        handleSuspensionModalClose();

        toast.success("Host Suspended Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleAdminTerminate = () => {
    axios
      .delete(api + `/adminDeleteHost/${actionId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        console.log(res);
        getPost();
        handleTerminationModalClose();
        toast.success("Host Terminated Successfully");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  const handleAdminWarning = () => {
    axios
      .post(
        api + `/sendWorningNotificationHost`,
        {
          id: actionId,
          title: warningForm.title,
          body: warningForm.body,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        getPost();
        handleWarningModalClose();
        toast.success("Host Warned Successfully");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [removeSuspensionModalOpen, setRemoveSuspensionModalOpen] =
    useState(false);

  const handleRemoveSuspensionModalOpen = () => {
    setRemoveSuspensionModalOpen(true);
  };

  const handleRemoveSuspensionModalClose = () => {
    setRemoveSuspensionModalOpen(false);
  };

  const handleRemoveSuspension = () => {
    axios
      .put(
        api + `/updateSuspendedHost/` + actionId,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        getPost();
        handleRemoveSuspensionModalClose();
        toast.success("Host Suspension Removed");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Something went wrong");
      });
  };

  return (
    <div id="container">
      <LayOut>
        <div className="header">
          <Header title="Accepted Host" />
          <div className="Dash">
            <div className="container">
              <div className="expt">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={acceptTableRef.current}
                >
                  <button className="export">
                    Export
                    <div className="expicon">
                      <BiExport size={20} />
                    </div>
                  </button>
                </DownloadTableExcel>
              </div>
              <List
                Today={handleTodayData}
                setDate={setChooseDate}
                setToday={setTodayDate}
                setWeek={setWeekDate}
                setMonth={setMonthDate}
                Week={handleWeekData}
                month={handleMonthData}
              />
            </div>
          </div>
        </div>
        <div className="details">
          <div className="table-container">
            <table className="content-table" ref={acceptTableRef}>
              <thead className="table-head">
                <tr>
                  <th>S.No.</th>
                  <th>Id</th>
                  <th>User ID</th>
                  <th>Leader ID</th>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Phone</th>
                  <th>Accepted At</th>
                  <th>Charge/min</th>
                  <th>Leader</th>
                  <th>Details</th>
                  <th>Action</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {posts
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      moment(item.acceptedDate)
                        .format()
                        .split("T")[0]
                        .includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.acceptedDate)
                        .format()
                        .split("T")[0]
                        .includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.acceptedDate).format() >= weekDate &&
                      moment(item.acceptedDate).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.acceptedDate).format() >= monthDate &&
                      moment(item.acceptedDate).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((post, index) => (
                    <tr key={post.index}>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td title={post?._id}>
                        {post?._id?.substring(0, 5) + "..."}
                      </td>
                      <td title={post?.userId}>
                        {post?.userId?.substring(0, 5) + "..."}
                      </td>
                      <td title={post?.leader?._id}>
                        {post?.leader?._id.substring(0, 5) + "..."}
                      </td>
                      <td>{post?.FirstName + " " + post?.LastName}</td>
                      <td title={post?.email}>
                        {post?.email.substring(0, 5) + "..."}
                      </td>
                      <td>{post?.number}</td>
                      <td>
                        {moment(post?.acceptedDate).format().split("T")[0]}
                      </td>
                      <td>
                        {post?.hostuser_fees == "" ? (
                          <>
                            <button
                              className="AddCharge"
                              onClick={() => handleAddChargeModal(post?._id)}
                            >
                              Add
                            </button>
                          </>
                        ) : (
                          <>
                            {post?.hostuser_fees}
                            <i
                              class="fa-solid fa-pen-to-square HostEdit"
                              onClick={() => handleAddChargeModal(post?._id)}
                            ></i>
                          </>
                        )}
                      </td>
                      <td>
                        {post?.leader ? (
                          <>
                            {post?.leader?.leaderName}{" "}
                            <i
                              class="fa-solid fa-pen-to-square HostEdit"
                              onClick={() => handleAddLeaderModal(post?._id)}
                            ></i>
                          </>
                        ) : (
                          <>
                            <button
                              className="AddCharge"
                              onClick={() => handleAddLeaderModal(post?._id)}
                            >
                              Add
                            </button>
                          </>
                        )}
                      </td>
                      <td
                        onClick={() => handleAction(post?._id)}
                        style={{
                          color: "#116FCD",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        View
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <select
                            value={selectedActions[post._id] || ""}
                            onChange={(e) =>
                              handleSelectAction(post._id, e.target.value)
                            }
                          >
                            <option value="">Select Action</option>
                            <option value="warning">Warning</option>
                            <option value="suspend">Suspend</option>
                            <option value="terminate">Terminate</option>
                            <option value="removeSuspension">Active</option>
                          </select>
                          <i
                            style={{
                              width: "20px",
                              color:
                                selectedActions[post._id] === "warning"
                                  ? "#afaf0a"
                                  : selectedActions[post._id] === "suspend"
                                  ? "orange"
                                  : selectedActions[post._id] === "terminate"
                                  ? "red"
                                  : selectedActions[post._id] ===
                                    "removeSuspension"
                                  ? "green"
                                  : "",
                            }}
                            className="fas fa-exclamation-triangle warning-icon"
                            onClick={() =>
                              handleIconClick(
                                post._id,
                                selectedActions[post._id]
                              )
                            }
                          />
                        </div>
                      </td>
                      <td>
                        {post?.suspendedUntil ? (
                          <span style={{ color: "red" }}>
                            {post?.suspendedUntil.split("T")[0]}
                          </span>
                        ) : (
                          <span style={{ color: "green" }}>Active</span>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={posts.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

        <Modal isOpen={chargeModal} style={customStyles}>
          <button className="colseModal" onClick={() => setChargeModal(false)}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Add Host Charge Per Minute
          </h3>
          <div className="addHostCharge">
            <input type="number" onChange={handleCharge}></input>
            <button onClick={handleAddHostCharge}>Add</button>
          </div>
        </Modal>

        <Modal isOpen={leaderModal} style={customStyles}>
          <button className="colseModal" onClick={() => setLeaderModal(false)}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Add Leader
          </h3>

          <div className="addHostCharge">
            <select
              onChange={handleAssignLeader}
              style={{ width: "80%", height: "40px" }}
            >
              <option>Select Leader</option>
              {leaderList.map((item) => {
                return <option value={item._id}>{item.leaderName}</option>;
              })}
            </select>
            <button onClick={handleAddLeader}>Add</button>
          </div>
        </Modal>
        <Modal
          isOpen={isSuspensionModalOpen}
          style={customStyles}
          onRequestClose={handleSuspensionModalClose}
        >
          <button className="colseModal" onClick={handleSuspensionModalClose}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Suspend User
          </h3>
          <div className="addHostCharge">
            <input
              type="number"
              placeholder="Days"
              value={suspendDays}
              onChange={(e) => setSuspendDays(e.target.value)}
            />
            <button onClick={handleAdminSuspend}>Suspend</button>
          </div>
        </Modal>

        <Modal
          isOpen={isTerminationModalOpen}
          style={customStyles}
          onRequestClose={handleTerminationModalClose}
        >
          <button className="colseModal" onClick={handleTerminationModalClose}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Are you use you want to terminate this user?
          </h3>
          <div className="addHostCharge">
            <button onClick={handleAdminTerminate}>Terminate</button>
          </div>
        </Modal>

        <Modal
          isOpen={isWarningModalOpen}
          style={customStyles}
          onRequestClose={handleWarningModalClose}
        >
          <button className="colseModal" onClick={handleWarningModalClose}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Warning User
          </h3>
          <div className="addHostCharge">
            <input
              type="text"
              placeholder="Title"
              value={warningForm.title}
              onChange={(e) =>
                setWarningForm({ ...warningForm, title: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Body"
              value={warningForm.body}
              onChange={(e) =>
                setWarningForm({ ...warningForm, body: e.target.value })
              }
            />
            <button onClick={handleAdminWarning}>Warning</button>
          </div>
        </Modal>

        <Modal
          isOpen={removeSuspensionModalOpen}
          style={customStyles}
          onRequestClose={handleRemoveSuspensionModalClose}
        >
          <button
            className="colseModal"
            onClick={handleRemoveSuspensionModalClose}
          >
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Are you sure you want to remove suspension?
          </h3>
          <div className="addHostCharge">
            <button onClick={handleRemoveSuspensionModalClose}>Cancel</button>
            <button onClick={handleRemoveSuspension}>Remove</button>
          </div>
        </Modal>

        {load ? (
          <>
            <div className="spinnerBg">
              <div>
                <Spinner />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <ToastContainer />
      </LayOut>
    </div>
  );
};

export default AcceptedHost;
