import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { RiseLoader } from "react-spinners";

export default function Spinner() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 60000);
  }, []);
  return (
    <div>
      <RiseLoader
        className="spin"
        color={"#FFFFFF"}
        loading={loading}
        size={15}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
