import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import Cards from "../components/Cards";
import Chart from "../components/Chart";
import LayOut from "../components/Layout";
import Progressbar from "../components/Progressbar";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    width: "80%",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "10px",
    height: "80%",
  },
};

Modal.setAppElement("#root");

const Dashboard = () => {
  const navigate = useNavigate();

  const [activeHost, setActiveHost] = useState(0);
  const [inactiveHost, sectInactiveHost] = useState(0);
  const [activeUser, setActiveUser] = useState(0);
  const [inactiveUser, setInactiveUser] = useState(0);
  const [coinPurchase, setCoinPurchase] = useState(0);
  const [coinAmount, setcoinAmount] = useState(0);
  const [newRequest, setNewRequest] = useState(0);
  const [total, setTotal] = useState(0);

  const [activeHostModalisOpen, setActiveHostModalisOpen] = useState(false);
  const [inActiveHostModal, setInActiveHostModal] = useState(false);
  const [activeUserModal, setActiveUserModal] = useState(false);
  const [inActiveUserModal, setInActiveUserModal] = useState(false);

  const handleActiveHostModalOpen = () => {
    setActiveHostModalisOpen(true);
  };

  const handleActiveHostModalClose = () => {
    setActiveHostModalisOpen(false);
  };

  const handleInActiveHostModalOpen = () => {
    setInActiveHostModal(true);
  };

  const handleInActiveHostModalClose = () => {
    setInActiveHostModal(false);
  };

  const handleActiveUserModalOpen = () => {
    setActiveUserModal(true);
  };

  const handleActiveUserModalClose = () => {
    setActiveUserModal(false);
  };

  const handleInActiveUserModalOpen = () => {
    setInActiveUserModal(true);
  };

  const handleInActiveUserModalClose = () => {
    setInActiveUserModal(false);
  };

  const handleTotalCoins = () => {
    axios
      .get(api + "/getallHostEarning", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setTotal(res.data.result);
        console.log("total coins", total);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleActiveHost = () => {
    axios
      .get(api + "/getHostOnlineCount", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setActiveHost(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleGetAllCoins = () => {
    axios
      .get(api + "/getallPrice", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log(res.data);
        setcoinAmount(res.data.totalPrice);
      })
      .catch((error) => {
        console.log("ofline", error);
      });
  };

  const handleInActiveHost = () => {
    axios
      .get(api + "/getHostOfflineCount", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        sectInactiveHost(res.data);
      })
      .catch((error) => {
        console.log("ofline", error);
      });
  };
  const handleActiveUser = () => {
    axios
      .get(api + "/getUserOnlineCount", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setActiveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleInActiveUser = () => {
    axios
      .get(api + "/getUserOfflineCount", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setInactiveUser(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleNewRequest = () => {
    axios
      .get(api + "/admin/hostRequest", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setNewRequest(res.data.getallHost.length);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCoinPurchase = () => {
    axios
      .get(api + "/getallCoins", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setCoinPurchase(res.data.totalCoins);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    handleNewRequest();
    handleCoinPurchase();
    handleActiveHost();
    handleInActiveHost();
    handleActiveUser();
    handleInActiveUser();
    handleGetAllCoins();
    handleTotalCoins();
  }, []);

  const Carddata = [
    {
      sname: "Active",
      subname: "Host",
      number: activeHost.result,
      type: "card-1",
      size: "small-size",
      onClick: handleActiveHostModalOpen,
    },
    {
      sname: "Inactive",
      subname: "Host",
      number: inactiveHost.result,
      type: "card-2",
      size: "small-size",
      onClick: handleInActiveHostModalOpen,
    },
    {
      sname: "Active",
      subname: "User",
      number: activeUser.result,
      type: "card-3",
      size: "small-size",
      onClick: handleActiveUserModalOpen,
    },
    {
      sname: "Inactive",
      subname: "User",
      number: inactiveUser.result,
      type: "card-4",
      size: "small-size",
      onClick: handleInActiveUserModalOpen,
    },
    {
      sname: "Coin",
      subname: "Purchase",
      number: coinPurchase,
      type: "card-5",
      size: "small-size",
    },
    {
      sname: "Coin",
      subname: "Amount",
      number: coinAmount,
      type: "card-6",
      size: "small-size",
    },
    {
      sname: "New Request",
      subname: "Of Host",
      number: newRequest,
      type: "card-7",
      size: "small-size",
      onClick: function () {
        navigate("/hostrequest");
      },
    },
    {
      sname: "Total",
      subname: "Host Earnings",
      number: total,
      type: "card-8",
      size: "small-size",
      onClick: function () {
        navigate("/hostearnings");
      },
    },
  ];

  return (
    <>
      <LayOut>
        <div className="card">
          {Carddata.map((card, index) => (
            <Cards
              key={index}
              sname={card.sname}
              subname={card.subname}
              number={card.number}
              type={card.type}
              size={card.size}
              width="250px"
              onClick={card.onClick}
            />
          ))}
          {/* <Calendar/> */}
        </div>
        <Chart />
        <Progressbar
          activeHost={activeHost.result}
          activeUser={activeUser.result}
          inactiveHost={inactiveHost.result}
          inactiveUser={inactiveUser.result}
        />
      </LayOut>

      <Modal
        isOpen={activeHostModalisOpen}
        style={customStyles}
        onRequestClose={handleActiveHostModalClose}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Active Host
        </h2>

        <table className="cardTable" style={{ width: "100%" }}>
          <thead>
            <tr className="cardTableHeader">
              <th>ID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>DOB</th>
              <th>Age</th>
            </tr>
          </thead>
          <tbody>
            {activeHost?.data?.map((item, index) => (
              <tr key={item._id} className="cardTableRow">
                <td>{item?._id}</td>
                <td>{item?.FirstName}</td>
                <td>{item?.Gender}</td>
                <td>{item?.email}</td>
                <td>{item?.number}</td>
                <td>{item?.city}</td>
                <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                <td>{item?.age}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>

      <Modal
        isOpen={inActiveHostModal}
        style={customStyles}
        onRequestClose={handleInActiveHostModalClose}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Inactive Host
        </h2>

        <table className="cardTable" style={{ width: "100%" }}>
          <thead>
            <tr className="cardTableHeader">
              <th>ID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>DOB</th>
              <th>Age</th>
            </tr>
          </thead>

          <tbody>
            {inactiveHost?.data?.map((item, index) => (
              <tr key={item._id} className="cardTableRow">
                <td>{item?._id}</td>
                <td>{item?.FirstName}</td>
                <td>{item?.Gender}</td>
                <td>{item?.email}</td>
                <td>{item?.number}</td>
                <td>{item?.city}</td>
                <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                <td>{item?.age}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>

      <Modal
        isOpen={activeUserModal}
        style={customStyles}
        onRequestClose={handleActiveUserModalClose}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Active User
        </h2>

        <table className="cardTable" style={{ width: "100%" }}>
          <thead>
            <tr className="cardTableHeader">
              {" "}
              <th>ID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>DOB</th>
              <th>Age</th>
            </tr>
          </thead>

          <tbody>
            {activeUser?.data?.map((item, index) => (
              <tr key={index} className="cardTableRow">
                <td>{item?._id}</td>
                <td>{item?.fullName}</td>
                <td>{item?.gender}</td>
                <td>{item?.email}</td>
                <td>{item?.phone}</td>
                <td>{item?.city}</td>
                <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                <td>{item?.age}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>

      <Modal
        isOpen={inActiveUserModal}
        style={customStyles}
        onRequestClose={handleInActiveUserModalClose}
      >
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Inactive User
        </h2>

        <table className="cardTable" style={{ width: "100%" }}>
          <thead>
            <tr className="cardTableHeader">
              <th>ID</th>
              <th>Name</th>
              <th>Gender</th>
              <th>Email</th>
              <th>Phone</th>
              <th>City</th>
              <th>DOB</th>
              <th>Age</th>
            </tr>
          </thead>
          <tbody>
            {inactiveUser?.data?.map((item, index) => (
              <tr key={index} className="cardTableRow">
                <td>{item?._id}</td>
                <td>{item?.fullName}</td>
                <td>{item?.gender}</td>
                <td>{item?.email}</td>
                <td>{item?.phone}</td>
                <td>{item?.city}</td>
                <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                <td>{item?.age}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal>
    </>
  );
};

export default Dashboard;
