import React, { useEffect, useRef, useState } from "react";
import api from "../components/api";
import Details from "../components/Details";
import Modal from "../components/Modal";
import axios from "axios";
import Header from "../components/Header";
import List from "../components/List";
import LayOut from "../components/Layout";
import { BiExport } from "react-icons/bi";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Spinner from "../components/Spinner";
import moment from "moment";

const Allusers = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [ID, setID] = useState("");
  const [tableData, setTableData] = useState([]);
  const [load, setLoad] = useState(false);

  // card api
  const getUsers = async () => {
    setLoad(true);
    axios
      .get(api + "/admin/users", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        console.log(res.data);
        setTableData(res.data.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  const tableRef = useRef(null);

  return (
    <div id="container">
    <LayOut>
      <div className="header">
        <Header title="All User" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <Details
          setIsVisible={setIsVisible}
          posts={tableData}
          setID={setID}
          today={todayDate}
          selectDate={chooseDate}
          refer={tableRef}
          weekData={weekDate}
          TodayStatic={Date1}
          monthData={monthDate}
        />
      </div>

      {isVisible && <Modal setIsVisible={setIsVisible} post={ID} />}

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
    </div>
  );
};

export default Allusers;
