import React, { useState } from "react";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import "./style.css";

export default function PasswordInput({ onChangeFun, value }) {
  const [eye, setEye] = useState(false);

  const handleEye = () => {
    setEye(!eye);
  };
  return (
    <>
      <div className="passwordInput">
        <input
          type={eye ? "text" : "password"}
          className="addinputtext"
          onChange={onChangeFun}
          value={value}
        ></input>
        <div className="passwordEye" onClick={() => handleEye()}>
          {eye ? <BsEyeSlashFill /> : <BsEyeFill />}
        </div>
      </div>
    </>
  );
}
