import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import api from "../components/api";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Spinner from "../components/Spinner";

const TopUsers = () => {
  const [posts, setPosts] = useState([]);
  const [load, setLoad] = useState(false);

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/AdminfindTopUser", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setPosts(res?.data?.hostuser);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  const TopHostTableRef = useRef(null);
  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  return (
    <LayOut>
      <div className="header">
        <Header title="Top Spending Users" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={TopHostTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>

            <input
              type="search"
              className="SearchInput"
              placeholder="Search By Name or User Id..."
              onChange={handleSearchValue}
            ></input>
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={TopHostTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>User ID</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Date Of Birth</th>
                <th>Age</th>
                <th>Phone</th>
                <th>City</th>
                <th>Created At</th>
                <th>Total Coins</th>
                <th>Spent Coins</th>
              </tr>
            </thead>
            <tbody>
              {posts?.map((item, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?._id}</td>
                    <td>{item?.fullName}</td>
                    <td>{item?.gender}</td>
                    <td>{item?.dateOfBirth?.substring(0, 10)}</td>
                    <td>{item?.age}</td>
                    <td>{item?.phone}</td>
                    <td>{item?.city}</td>
                    <td>{item?.createdAt?.split("T")[0]}</td>
                    <td>{item?.total_coins}</td>
                    <td>{item?.spentCoins}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default TopUsers;
