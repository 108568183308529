import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Header from "../components/Header";
import List from "../components/List";
import LayOut from "../components/Layout";
import api from "../components/api";
import Modal from "react-modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { TablePagination } from "@mui/material";
import Spinner from "../components/Spinner";
import moment from "moment";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

export default function UserReport() {
  const [userReportData, setUserReportData] = useState([]);
  const [load, setLoad] = useState(false);
  const getUserReport = () => {
    setLoad(true);
    axios
      .get(api + `/allfindReport`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setUserReportData(res.data.findreport.reverse());
      })
      .catch((e) => {
        setLoad(false);
        console.log("UserReport", e);
      });
  };

  useEffect(() => {
    getUserReport();
  }, []);
  //
  //handleImage-------------------------------------------------------------//
  //

  const [imageurl, setImageurl] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const handleImage = (val) => {
    setImageModal(true);
    setImageurl(val);
  };

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  const TopHostTableRef = useRef(null);
//Send Warnng Message
  const [userToWarn, setUserToWarn] = useState(null);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [warningForm, setWarningForm] = useState({
    title: "",
    body: "",
  });

  const handleWarningModalOpen = (user) => {
    setUserToWarn(user);
    console.log("Warning User", user)
    setIsWarningModalOpen(true);
  };

  const handleWarningModalClose = () => {
    setIsWarningModalOpen(false);
  };

  const handleAdminWarning = () => {
    if (warningForm.title && warningForm.body) {
      axios
        .post(
          api + `/sendNotiUserRequest`,
          {
            _id: userToWarn,
            title: warningForm.title,
            body: warningForm.body,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          alert(res.data.message);
          setIsWarningModalOpen(false);
          setWarningForm({
            title: "",
            body: "",
          });

        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      alert("Please fill all the fields");
    }
  };

  //Remove Warning Message
  const [isWarningAcceptModalOpen, setIsWarningAcceptModalOpen] = useState(false);

  const handleWarningAcceptModalOpen = (user) => {
    setUserToWarn(user);
    setIsWarningAcceptModalOpen(true);
  }

  const handleWarningAcceptModalClose = () => {
    setIsWarningAcceptModalOpen(false);
  }


  const handleAdminWarningAccept = () => {
    axios
      .post(
        api + `/sendNotiUserResponce`,
        {
          _id: userToWarn,
          title: warningForm.title,
          body: warningForm.body,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        alert(res.data.message);
        setIsWarningAcceptModalOpen(false);
        setWarningForm({
          title: "",
          body: "",
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  

  return (
    <div>
      <LayOut>
        <div className="header">
          <Header title="User Report" />
          <div className="Dash">
            <div className="container">
              <div className="expt">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={TopHostTableRef.current}
                >
                  <button className="export">
                    Export
                    <div className="expicon">
                      <BiExport size={20} />
                    </div>
                  </button>
                </DownloadTableExcel>
              </div>
              <List
                Today={handleTodayData}
                setDate={setChooseDate}
                setToday={setTodayDate}
                setWeek={setWeekDate}
                setMonth={setMonthDate}
                Week={handleWeekData}
                month={handleMonthData}
              />
            </div>
          </div>
        </div>
        <div className="details">
          <div className="table-container">
            <table className="content-table" ref={TopHostTableRef}>
              <thead className="table-head">
                <tr>
                  <th>S.No.</th>
                  <th>Report ID</th>
                  <th>User Name</th>
                  <th>User Phone</th>
                  <th>Host ID</th>
                  <th>Host Name</th>
                  <th>Host Phone</th>
                  <th>Report Reason</th>
                  <th>Report Image</th>
                  <th>CreatedAt</th>
                  <th>UpdatedAt</th>
                  <th>Warning</th>
                  <th>Remove Warning</th>
                </tr>
              </thead>
              <tbody>
                {userReportData
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt.split("T")[0].includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= weekDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      item.createdAt >= monthDate &&
                      item.createdAt <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    {
                      console.log(item);
                    }
                    return (
                      <tr>
                        <td>{index + 1 + rowsPerPage * page}</td>
                        <td title={item?.userId?._id}>
                          {item?.userId?._id.substring(0, 5) + ".."}
                        </td>
                        <td>{item?.userId?.fullName}</td>
                        <td>{item?.userId?.phone}</td>
                        <td>{item?.targetId?._id}</td>
                        <td>
                          {item?.targetId?.FirstName + item?.targetId?.LastName}
                        </td>
                        <td>{item?.targetId?.number}</td>
                        <td>{item?.Choose_the_Reason}</td>
                        <td onClick={() => handleImage(item?.ReportImage)}>
                          <i class="fa-solid fa-eye"></i>
                        </td>
                        <td>{item?.createdAt.split("T")[0]}</td>
                        <td>{item?.updatedAt.split("T")[0]}</td>
                        <td
                          onClick={() => handleWarningModalOpen(item._id)}
                          style={{
                            color: "#116FCD",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Warn User
                        </td>
                        <td
                          onClick={() => handleWarningAcceptModalOpen(item._id)}
                          style={{
                            color: "#116FCD",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Remove Warning
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={userReportData.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

        <Modal isOpen={imageModal} style={customStyles}>
          <button className="colseModal" onClick={() => setImageModal(false)}>
            X
          </button>
          <img src={imageurl} className="bannerImage" alt="ReportImage"></img>
          {console.log("imageurl", imageurl)}
        </Modal>

        <Modal
          isOpen={isWarningModalOpen}
          style={customStyles}
          onRequestClose={handleWarningModalClose}
        >
          <button className="colseModal" onClick={handleWarningModalClose}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Warning User
          </h3>
          <div className="addHostCharge">
            <input
              type="text"
              placeholder="Title"
              value={warningForm.title}
              onChange={(e) =>
                setWarningForm({ ...warningForm, title: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Body"
              value={warningForm.body}
              onChange={(e) =>
                setWarningForm({ ...warningForm, body: e.target.value })
              }
            />
            <button onClick={handleAdminWarning}>Warning</button>
          </div>
        </Modal>

        <Modal
          isOpen={isWarningAcceptModalOpen}
          style={customStyles}
          onRequestClose={handleWarningAcceptModalClose}
        >
          <button className="colseModal" onClick={handleWarningAcceptModalClose}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Remove Warning
          </h3>
          <div className="addHostCharge">
            <input
              type="text"
              placeholder="Title"
              value={warningForm.title}
              onChange={(e) =>
                setWarningForm({ ...warningForm, title: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Body"
              value={warningForm.body}
              onChange={(e) =>
                setWarningForm({ ...warningForm, body: e.target.value })
              }
            />
            <button onClick={handleAdminWarningAccept}>Send Message</button>
          </div>
        </Modal>


        {load ? (
          <>
            <div className="spinnerBg">
              <div>
                <Spinner />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </LayOut>
    </div>
  );
}
