import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};
Modal.setAppElement("#root");

export default function SendNotification() {
  // ------------api Intergration for Array of Tokens--------------------------//
  const [selectedRecipient, setSelectedRecipient] = useState("none");

  //Users

  const [users, setUsers] = useState([]);
  const [userToken, setUserToken] = useState([]);

  const handleUsers = () => {
    let arr = [];
    axios
      .get(api + "/admin/users", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setUsers(res.data);
        res.data.forEach((item) => {
          arr.push(item.fcmToken);
        });
        setUserToken(arr);
      })
      .catch((e) => {
        console.log("uesrsError", e);
      });
  };
  useEffect(() => {
    handleUsers();
  }, []);

  //Host
  const [host, setHost] = useState([]);
  const [hostToken, setHostToken] = useState([]);

  const handleHosts = () => {
    let HostArr = [];
    axios
      .get(api + "/getAcceptHost", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setHost(res.data.getAcceptHost);
        res.data.getAcceptHost.forEach((item) => {
          HostArr.push(item.fcmToken);
        });
        setHostToken(HostArr);
      })
      .catch((e) => {
        console.log("uesrsError", e);
      });
  };
  useEffect(() => {
    handleHosts();
  }, []);

  let HostUserToken = hostToken.concat(userToken);

  const [load, setLoad] = useState(false);
  // ------------api Intergration of Send Multiple Notification--------------------------//

  const [allToken, setAllToken] = useState([]);
  const [allTittle, setAllTittle] = useState("");
  const [allMessage, setAllMessage] = useState("");

  const handleValueofAll = (e) => {
    setAllToken(e.target.value.split(","));
  };

  const handleAllTitle = (e) => {
    setAllTittle(e.target.value);
  };

  const handleAllMessage = (e) => {
    setAllMessage(e.target.value);
  };

  const handleMUltiSend = () => {
    setLoad(true);
    // axios
    // .post(
    //   api + "/sendMultiNotification",
    //   {
    //     tokens: allToken.filter((item) => item),
    //     title: allTittle,
    //     body: allMessage,
    //   },
    //   {
    //     headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    //   }
    // )
    // .then((res) => {
    //   toast.success("Notification Send SucessFully");
    //   setLoad(false);
    // })
    // .catch((e) => {
    //   console.log("MUltiSend", e);
    //   toast.error("Notification Not Send");
    //   setLoad(false);
    // });
    if (selectedRecipient === "users") {
      axios
        .post(
          api + "/sendMultiNotification",
          {
            tokens: userToken,
            title: allTittle,
            body: allMessage,
            to: "users",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Notification Send SucessFully");
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Notification Not Send");
          setLoad(false);
        });
    }

    if (selectedRecipient === "hosts") {
      axios
        .post(
          api + "/sendMultiNotification",
          {
            tokens: hostToken,
            title: allTittle,
            body: allMessage,
            to: "hosts",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Notification Send SucessFully");
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Notification Not Send");
          setLoad(false);
        });
    }

    if (selectedRecipient === "all") {
      axios
        .post(
          api + "/sendMultiNotification",
          {
            tokens: HostUserToken,
            title: allTittle,
            body: allMessage,
            to: "all",
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Notification Send SucessFully");
          setLoad(false);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Notification Not Send");
          setLoad(false);
        });
    }
  };

  const [singleCategory, setSingleCategory] = useState("");
  const [singleTitle, setSingleTitle] = useState("");
  const [singleMessage, setSingleMessage] = useState("");
  const [singleId, setSingleId] = useState("");

  const handleSingleCategory = (e) => {
    setSingleCategory(e.target.value);
  };

  const handleSingleValue = (e) => {
    if (!singleCategory == "") {
      setSingleId(e.target.value);
    }
  };

  const handleSingleTitle = (e) => {
    setSingleTitle(e.target.value);
  };
  const handleSingleMessage = (e) => {
    setSingleMessage(e.target.value);
  };

  //Host

  let singleHost = host.filter((item) => item._id == singleId);

  const [singleHostToken, setSingleHostToken] = useState("");
  const [hostName, setHostName] = useState("");

  useEffect(() => {
    for (let item of singleHost) {
      setSingleHostToken(item.fcmToken);
      setHostName(item.FirstName + " " + item.LastName);
      // setUid(item.)
      console.log("host", item);
    }
  }, [singleId]);

  //User
  let singleUser = users.filter((item) => item._id == singleId);
  const [singleUserToken, setSingleUserToken] = useState("");
  const [userName, setUserName] = useState("");
  const [uid, setUid] = useState("");

  useEffect(() => {
    for (let item of singleUser) {
      setSingleUserToken(item.fcmToken);
      setUserName(item.fullName);
      setUid(item.uid);
    }
  }, [singleId]);

  const SendNotToSingle = () => {
    setLoad(true);
    if (singleCategory == "user") {
      axios
        .post(
          api + "/adminSendNotification",
          {
            registrationToken: singleUserToken,
            title: singleTitle,
            body: singleMessage,
            uid: uid,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setLoad(false);
          toast.success("Notification Send SucessFully");
        })
        .catch((e) => {
          console.log("uesrsError", e);
          toast.error("Notification Not Send");
          setLoad(false);
        });
    } else {
      axios
        .post(
          api + "/adminSendNotification",
          {
            registrationToken: singleHostToken,
            title: singleTitle,
            body: singleMessage,
            hostId: singleId,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          toast.success("Notification Send SucessFully");
          setLoad(false);
        })
        .catch((e) => {
          console.log("uesrsError", e);
          toast.error("Notification Not Send");
          setLoad(false);
        });
    }
  };

  const handleunselectUser = () => {
    setSingleId("");
    setUserName("");
    setSingleUserToken("");
  };

  const handleunselectHost = () => {
    setSingleId("");
    setHostName("");
    setSingleHostToken("");
  };

  // ------------api Intergration for Two or more Notification--------------------------//
  const [openModal, setOpenModal] = useState(false);
  const handleModal = (e) => {
    setOpenModal(true);
  };

  // let ArrMore=[];

  const [moreArray, setMoreArray] = useState([]);
  const [valueId, setValueId] = useState("");
  const [hostNames, setHostNames] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [removeModal, setRemoveModal] = useState(false);
  const [moreTittle, setMoreTittle] = useState("");
  const [moreMessage, setMoreMessage] = useState("");

  const handleMoreTitle = (e) => {
    setMoreTittle(e.target.value);
  };

  const handleMoreMessage = (e) => {
    setMoreMessage(e.target.value);
  };

  const handleModalId = (e) => {
    setValueId(e.target.value);
  };

  const handleAddTokens = () => {
    if (valueId) {
      if (selectedRecipientType === "users") {
        // Add to the selectedUserIds array
        setSelectedUserIds([...selectedUserIds, valueId]);
        // Add to the userNames state
        for (let val of users) {
          if (val._id == valueId) {
            setMoreArray([...moreArray, val.fcmToken]);
            setUserNames([...userNames, val]);
          }
        }
      } else if (selectedRecipientType === "hosts") {
        // Add to the selectedHostIds array
        setSelectedHostIds([...selectedHostIds, valueId]);
        // Add to the hostNames state
        for (let item of host) {
          if (item._id == valueId) {
            setMoreArray([...moreArray, item.fcmToken]);
            setHostNames([...hostNames, item]);
          }
        }
      }
    }
    setValueId("");
  };

  const handleRemove = () => {
    setRemoveModal(true);
  };

  const [showNames, setShowNames] = useState([]);

  const handleRemoveItem = (e) => {
    setUserNames(userNames.filter((item) => item._id !== e._id));
    setHostNames(hostNames.filter((item) => item._id !== e._id));
  };

  useEffect(() => {
    setShowNames(
      userNames
        .map((item) => {
          return item.fullName;
        })
        .concat(
          hostNames.map((item) => {
            return item.FirstName + " " + item.LastName;
          })
        )
    );

    setMoreArray(
      userNames
        .map((item) => {
          return item.fcmToken;
        })
        .concat(
          hostNames.map((item) => {
            return item.fcmToken;
          })
        )
    );
  }, [userNames, hostNames]);

  const handleMoreSend = () => {
    setLoad(true);
    axios
      .post(
        api + "/sendMultiNotiToMore",
        {
          tokens: moreArray?.filter((item) => item),
          title: moreTittle,
          body: moreMessage,
          users: selectedUserIds,
          hosts: selectedHostIds,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Notification Send Successfully");
        setLoad(false);
      })
      .catch((e) => {
        console.log("MoreSend", e);
        toast.error("Notification Not Send");
        setLoad(false);
      });
  };

  const [selectedRecipientType, setSelectedRecipientType] = useState("users");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedHostIds, setSelectedHostIds] = useState([]);

  return (
    <div>
      <LayOut>
        <div className="addheader">
          <Header title="Send Notification" />
        </div>
        <div className="addbannermain">
          <div className="noitficationDisplay">
            <div className="leftBox">
              <div className="noticationBox">
                <div className="notificationHead">Notification to All</div>
                <select
                  className="NotificationSelect"
                  onChange={(e) => {
                    setSelectedRecipient(e.target.value);
                    handleValueofAll(e);
                  }}
                >
                  <option selected disabled>
                    Select Recipient
                  </option>
                  <option value="users">Users</option>
                  <option value="hosts">Hosts</option>
                  <option value="all">Both Host & Users</option>
                </select>

                <input
                  type="text"
                  placeholder="Enter Title"
                  className="notiInput"
                  onChange={handleAllTitle}
                ></input>
                <input
                  type="text"
                  placeholder="Enter Message"
                  className="notiInput"
                  onChange={handleAllMessage}
                ></input>
                <button
                  className="sendBTnNoti"
                  onClick={() => handleMUltiSend()}
                >
                  Send
                </button>
              </div>

              <div className="noticationBox">
                <div className="notificationHead">Notification to Single</div>
                <select
                  className="NotificationSelect"
                  onChange={handleSingleCategory}
                >
                  <option selected disabled>
                    Select Category
                  </option>
                  <option value="user">User</option>
                  <option value="host">Host</option>
                </select>

                {singleCategory == "host" ? (
                  <>
                    {!hostName ? (
                      <>
                        <input
                          type="text"
                          placeholder="Enter Id (CWHxxxxx)"
                          className="notiInput"
                          value={singleId}
                          onChange={handleSingleValue}
                        ></input>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Enter Id (CWHxxxxx)"
                          className="notiInput3"
                          value={hostName}
                        ></input>
                        <button
                          className="sendBTnNotiAdd2"
                          onClick={() => handleunselectHost()}
                        >
                          X
                        </button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {!userName ? (
                      <>
                        <input
                          type="text"
                          placeholder="Enter Id (CWUxxxxx)"
                          className="notiInput"
                          value={singleId}
                          onChange={handleSingleValue}
                        ></input>
                      </>
                    ) : (
                      <>
                        <input
                          type="text"
                          placeholder="Enter Id (CWUxxxxx)"
                          className="notiInput3"
                          value={userName}
                        ></input>
                        <button
                          className="sendBTnNotiAdd2"
                          onClick={() => handleunselectUser()}
                        >
                          X
                        </button>
                      </>
                    )}
                  </>
                )}

                <input
                  type="text"
                  placeholder="Enter Title"
                  className="notiInput"
                  onChange={handleSingleTitle}
                ></input>
                <input
                  type="text"
                  placeholder="Enter Message"
                  className="notiInput"
                  onChange={handleSingleMessage}
                ></input>
                <button
                  className="sendBTnNoti"
                  onClick={
                    !singleUserToken && !singleHostToken
                      ? () => {
                          toast.error("No Token Found");
                        }
                      : SendNotToSingle
                  }
                >
                  Send
                </button>
              </div>
            </div>
            <div className="rightBox">
              <div className="noticationBox">
                <div className="notificationHead">
                  Notification to Two or More
                </div>
                <select
                  className="NotificationSelect"
                  onChange={(e) => setSelectedRecipientType(e.target.value)}
                >
                  <option value="users">Users</option>
                  <option value="hosts">Hosts</option>
                </select>

                {userNames.length || hostNames.length ? (
                  <>
                    <input
                      type="text"
                      placeholder="Enter Id"
                      className="notiInput"
                      value={showNames}
                    ></input>
                    <div className="moreBtns">
                      <button
                        className="sendBTnNotiAdd"
                        onClick={() => handleModal()}
                      >
                        Add More
                      </button>
                      <button
                        className="sendBTnNotiAdd"
                        style={{ backgroundColor: "#dd3232" }}
                        onClick={() => handleRemove()}
                      >
                        Remove
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <button
                      className="notiInput22"
                      onClick={() => handleModal()}
                    >
                      Enter Id (CWxxxxxx)
                    </button>
                  </>
                )}

                <input
                  type="text"
                  placeholder="Enter Title"
                  className="notiInput"
                  onChange={handleMoreTitle}
                ></input>
                <input
                  type="text"
                  placeholder="Enter Message"
                  className="notiInput"
                  onChange={handleMoreMessage}
                ></input>
                <button
                  className="sendBTnNoti"
                  onClick={() => handleMoreSend()}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
        {load ? (
          <>
            <div className="spinnerBg">
              <div>
                <Spinner />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </LayOut>

      <Modal isOpen={openModal} style={customStyles}>
        <div className="closeBtnModal" onClick={() => setOpenModal(false)}>
          X
        </div>
        <div className="ModalHead">Add Recipient</div>
        <input
          type="text"
          placeholder={`Enter ${
            selectedRecipientType === "users" ? "User" : "Host"
          } ID`}
          className="notiInput"
          onChange={handleModalId}
        ></input>
        <button className="sendBTnNotiAdd3" onClick={handleAddTokens}>
          Add
        </button>
      </Modal>

      <Modal isOpen={removeModal} style={customStyles}>
        <div className="closeBtnModal" onClick={() => setRemoveModal(false)}>
          X
        </div>
        <div className="ModalHead">Remove Recipient</div>
        <table className="tableRemove">
          <tbody>
            {userNames.map((item) => {
              return (
                <tr>
                  <td>{item.fullName}</td>
                  <td onClick={() => handleRemoveItem(item)}>Remove</td>
                </tr>
              );
            })}

            {hostNames.map((item) => {
              return (
                <tr>
                  <td>{item.FirstName + " " + item.LastName}</td>
                  <td onClick={() => handleRemoveItem(item)}>Remove</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Modal>
    </div>
  );
}
