import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import List from "../components/List";
import LayOut from "../components/Layout";
import Header from "../components/Header";
import { BiExport } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import api from "../components/api";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { TablePagination } from "@mui/material";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Spinner from "../components/Spinner";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const Listemoji = () => {
  const [load, setLoad] = useState(false);
  //
  //GetEmojiList-----------------------------------------------------//
  //
  const [emojiLists, setEmojiLists] = useState([]);

  const handleEmojiList = () => {
    setLoad(true);
    axios
      .get(api + "/admin/getEmoji", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setEmojiLists(res.data.getAllEmoji.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  useEffect(() => {
    handleEmojiList();
  }, []);

  //
  //Set Image Modal And Update Image--------------------------------------------//
  //
  let navigate = useNavigate();
  const [imageUrl, setImageUrl] = useState("");
  const [imageId, setImageId] = useState("");
  const [imageModal, setImageModal] = useState(false);

  const handlImage = (val) => {
    setImageUrl(val.split("space")[0]);
    setImageId(val.split("space")[1]);
    setImageModal(true);
  };

  const handleUpdateImageModal = (id) => {
    navigate(`/uploadEmojiImage/${id}`);
  };

  //
  //DeleteEmoji--------------------------------------------------------------//
  //
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const handleDeleteEmoji = (val) => {
    setDeleteModal(true);
    setDeleteId(val);
  };

  const handleDelete = (id) => {
    setLoad(true);
    axios
      .delete(api + `/admin/deleteEmoji/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        setLoad(false);
        toast.success("Emoji Deleted");
        handleEmojiList();
      })
      .catch((e) => {
        setLoad(false);
        console.log("error", e);
        toast.error("Emoji not Deleted");
      });
    setDeleteModal(false);
  };

  //
  //UpdateEmoji-----------------------------------------------------------------//
  //

  const [updateModal, setUpdateModal] = useState(false);
  const [updateId, setUpdateId] = useState(false);
  const handleUpdateEmoji = (val) => {
    setUpdateModal(true);
    setUpdateId(val);
  };

  const handleUpdateName = (e) => {
    setName(e.target.value);
  };
  const handleUpdatePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleUpdateBtn = () => {
    setLoad(true);
    axios
      .put(
        api + `/updateEmojidata/${updateId}`,
        {
          name: name,
          coins: price,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Emoji Updated");
        handleEmojiList();
      })
      .catch((e) => {
        setLoad(false);
        console.log("error", e);
        toast.error("Emoji not Updated");
      });
    setUpdateModal(false);
  };

  //getOneEmoji-----------------------------------------------------------------//
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const getOneEmoji = () => {
    setLoad(true);
    axios
      .get(api + `/getOneEmoji/${updateId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setName(res?.data?.getOneEmoji?.name);
        setPrice(res?.data?.getOneEmoji?.coins);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getOneEmoji();
  }, [updateId]);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event,newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//
//---------------------------------------filter Acc to Date-----------------------//
  
const [todayDate, setTodayDate] = useState("");
const [chooseDate, setChooseDate] = useState("");
const [weekDate, setWeekDate] = useState("");
const [monthDate,setMonthDate] = useState("")

const handleTodayData = () => {
  setTodayDate(moment().format("YYYY-MM-DD"));
  setChooseDate("");
  setWeekDate("");
  setMonthDate("");
};

const Date1 = moment(new Date()).format();
let preDate = moment().subtract(7,'days').format();
let monthhDate = moment().subtract(1,'months').format();

const handleWeekData = () => {
  setWeekDate(preDate);
  setChooseDate("");
  setTodayDate("");
  setMonthDate("");
};

const handleMonthData = () => {
  setMonthDate(monthhDate);
  setChooseDate("");
  setTodayDate("");
  setWeekDate("");
};

  //------------------------------------Filter Acc to  Date End---------------------------------------//

  const TopHostTableRef = useRef(null);
  //
  //
  return (
    <LayOut>
      <div className="header">
        <Header title="Emoji List" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={TopHostTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={TopHostTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Id</th>
                <th>Emoji Name</th>
                <th>Emoji Price</th>
                <th>Emoji Image</th>
                <th>createdAt</th>
                <th>updatedAt</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {emojiLists?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                    
                  } else if (item.createdAt.split("T")[0].includes(todayDate) && !chooseDate && !weekDate && !monthDate ) {
                    return item;
    
                  } else if (item.createdAt.split("T")[0].includes(chooseDate) && !todayDate && !weekDate && !monthDate) {
                    return item;
                  } else if(item.createdAt >= weekDate && item.createdAt <= Date1 && !todayDate && !chooseDate && !monthDate ){
                    return item;
                  }else if(item.createdAt >= monthDate && item.createdAt <= Date1 && !todayDate && !chooseDate && !weekDate){
                    return item;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{item?._id}</td>
                      <td>{item?.name}</td>
                      <td>{item?.coins}</td>
                      <td
                        onClick={() =>
                          handlImage(item.stickerUrl + "space" + item._id)
                        }
                      >
                        <i class="fa-solid fa-eye"></i>
                      </td>
                      <td>{item?.createdAt.substring(0,10)}</td>
                      <td>{item?.updatedAt.substring(0,10)}</td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square ListEdit"
                          onClick={() => handleUpdateEmoji(item._id)}
                        ></i>
                        <i
                          class="fa-solid fa-trash ListDelte"
                          onClick={() => handleDeleteEmoji(item._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          
        </div>
        <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10,15,20,25,30]}
              component="div"
              count={emojiLists.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
      </div>

      {/* //------------------------------------UpdateModal---------------------------------------// */}
      <Modal isOpen={updateModal} style={customStyles}>
        <button className="colseModal" onClick={() => setUpdateModal(false)}>
          X
        </button>
        <div className="updateModal">
          <div className="addinput">
            Emoji Name
            <input
              className="addinputtext"
              type="text"
              value={name}
              onChange={handleUpdateName}
            />
          </div>

          <div className="addinput">
            Emoji Price
            <input
              className="addinputtext"
              type="text"
              value={price}
              onChange={handleUpdatePrice}
            />
          </div>
          <div className="updateBtns">
            <button className="UpdateBtn" onClick={() => handleUpdateBtn()}>
              Update
            </button>
            <button className="CancelBtn">Cancel</button>
          </div>
        </div>
      </Modal>

      {/* //------------------------------------DeleteModal---------------------------------------// */}

      <Modal isOpen={deleteModal} style={customStyles}>
        <button className="colseModal" onClick={() => setDeleteModal(false)}>
          X
        </button>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Are you Sure You Want To Delete this Emoji?
        </h3>
        <div className="updateBtns">
          <button className="UpdateBtn" onClick={() => handleDelete(deleteId)}>
            Delete
          </button>
          <button className="CancelBtn" onClick={() => setDeleteModal(false)}>
            Cancel
          </button>
        </div>
      </Modal>

      {/* //------------------------------------imageModal---------------------------------------// */}

      <Modal isOpen={imageModal} style={customStyles}>
        <button className="colseModal" onClick={() => setImageModal(false)}>
          X
        </button>
        <img src={imageUrl} className="bannerImage" alt="Image"></img>
        <div className="updateImageBtn">
          <button onClick={() => handleUpdateImageModal(imageId)}>
            Update Image
          </button>
        </div>
      </Modal>

      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </LayOut>
  );
};

export default Listemoji;
