import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../../../components/Header";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";
import List from "../../../components/List";
import Spinner from "../../../components/Spinner";
import api from "../../../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
};

Modal.setAppElement("#root");

const LeaderAcceptedHost = () => {
  const [leaderAcceptedHost, setLeaderAcceptedHost] = useState([]);
  const [load, setLoad] = useState(false);
  const [leaderModal, setLeaderModal] = useState(false);
  const [chargeModal, setChargeModal] = useState(false);
  const [hostId, setHostId] = useState("");
  const [charge, setCharge] = useState("");
  const [leaderList, setLeaderList] = useState([]);
  const [leaderId, setLeaderId] = useState("");
  const id = localStorage.getItem("leaderId");

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/leader/getLeaderAcceptedHost/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderAcceptedHost(res.data.getAcceptHost.reverse());
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  // console.log("posts", posts);

  let navigate = useNavigate();

  const handleAddChargeModal = (e) => {
    setChargeModal(true);
    setHostId(e);
  };

  const handleCharge = (e) => {
    setCharge(e.target.value);
  };

  const handleAddHostCharge = () => {
    setLoad(true);
    axios
      .put(
        api + `/adminAdd/Hostuserfress/${hostId}`,
        { hostuser_fees: charge },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Host Charge Per Minute Added");
        getPost();
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
        toast.error("Host Charge Per Minute Not Added");
      });

    setChargeModal(false);
  };

  const acceptTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//
  const handleLeaderList = async () => {
    axios
      .get(api + "/admin/getAllLeader", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderList(res.data.result);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    handleLeaderList();
  }, []);

  const handleAddLeaderModal = (e) => {
    setLeaderModal(true);
    setHostId(e);
  };

  const handleAssignLeader = (e) => {
    setLeaderId(e.target.value);
  };

  const handleHostAddtoLeader = () => {
    axios
      .put(
        api + `/admin/addHostIngroup/${leaderId}`,
        {
          host_id: hostId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        toast.success("Leader Assigned Successfully");
      })

      .catch((e) => {
        console.log("errorerror", e);
      });
  };

  const handleAddLeader = () => {
    setLoad(true);
    axios
      .put(
        api + `/addleaderId/${hostId}`,
        {
          leader: leaderId,
        },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      )
      .then((res) => {
        setLoad(false);
        getPost();
        setLeaderModal(false);
        handleHostAddtoLeader();
      })

      .catch((e) => {
        setLoad(false);
        console.log("e", e);
        toast.error("Leader Not Assigned");
        setLeaderModal(false);
        getPost();
      });
  };

  return (
    <div id="container">
      <LeaderLayOut>
        <div className="header">
          <Header title="Accepted Host" />
          <div className="Dash">
            <div className="container">
              <div className="expt">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={acceptTableRef.current}
                >
                  <button className="export">
                    Export
                    <div className="expicon">
                      <BiExport size={20} />
                    </div>
                  </button>
                </DownloadTableExcel>
              </div>
              <List
                Today={handleTodayData}
                setDate={setChooseDate}
                setToday={setTodayDate}
                setWeek={setWeekDate}
                setMonth={setMonthDate}
                Week={handleWeekData}
                month={handleMonthData}
              />
            </div>
          </div>
        </div>
        <div className="details">
          <div className="table-container">
            <table className="content-table" ref={acceptTableRef}>
              <thead className="table-head">
                <tr>
                  <th>S.No.</th>
                  <th>Id</th>
                  <th>User ID</th>
                  <th>Name</th>
                  <th>Email ID</th>
                  <th>Phone</th>
                  <th>Accepted At</th>
                  <th>Charge/min</th>
                  <th>Sticker Coins</th>
                  <th>Video Coins</th>
                  <th>Total Earning</th>
                  <th>Leader</th>
                </tr>
              </thead>
              <tbody>
                {leaderAcceptedHost?.length === 0 ? (
                  <tr>
                    <td colSpan="9">No accepted host</td>
                  </tr>
                ) : (
                  leaderAcceptedHost
                    ?.filter((item) => {
                      if (
                        !todayDate &&
                        !chooseDate &&
                        !weekDate &&
                        !monthDate
                      ) {
                        return item;
                      } else if (
                        moment(item.acceptedDate)
                          .format()
                          .split("T")[0]
                          .includes(todayDate) &&
                        !chooseDate &&
                        !weekDate &&
                        !monthDate
                      ) {
                        return item;
                      } else if (
                        moment(item.acceptedDate)
                          .format()
                          .split("T")[0]
                          .includes(chooseDate) &&
                        !todayDate &&
                        !weekDate &&
                        !monthDate
                      ) {
                        return item;
                      } else if (
                        moment(item.acceptedDate).format() >= weekDate &&
                        moment(item.acceptedDate).format() <= Date1 &&
                        !todayDate &&
                        !chooseDate &&
                        !monthDate
                      ) {
                        return item;
                      } else if (
                        moment(item.acceptedDate).format() >= monthDate &&
                        moment(item.acceptedDate).format() <= Date1 &&
                        !todayDate &&
                        !chooseDate &&
                        !weekDate
                      ) {
                        return item;
                      }
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((post, index) => (
                      <tr key={post.index}>
                        <td>{index + 1 + rowsPerPage * page}</td>
                        <td>{post?._id}</td>
                        <td>{post?.userId}</td>
                        <td>{post?.FirstName + " " + post?.LastName}</td>
                        <td>
                          {(post?.email || "").substring(0, 5) +
                            "*".repeat(
                              Math.max(0, (post?.email || "").length - 5)
                            )}
                        </td>
                        <td>
                          {post?.number
                            ? "*".repeat(
                                Math.max(0, (post.number + "").length - 2)
                              ) + post.number.toString().slice(-2)
                            : ""}
                        </td>

                        <td>
                          {moment(post.acceptedDate).format().split("T")[0]}
                        </td>
                        <td>{post?.hostuser_fees}</td>
                        <td>{post?.stickerCoins}</td>
                        <td>{post?.videoUserCoins}</td>
                        <td>{post?.host_balance}</td>

                        <td>{post?.leader?.leaderName} </td>
                      </tr>
                    ))
                )}
              </tbody>
            </table>
          </div>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={leaderAcceptedHost.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </div>

        <Modal isOpen={chargeModal} style={customStyles}>
          <button className="colseModal" onClick={() => setChargeModal(false)}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Add Host Charge Per Minute
          </h3>
          <div className="addHostCharge">
            <input type="number" onChange={handleCharge}></input>
            <button onClick={handleAddHostCharge}>Add</button>
          </div>
        </Modal>

        <Modal isOpen={leaderModal} style={customStyles}>
          <button className="colseModal" onClick={() => setLeaderModal(false)}>
            X
          </button>
          <h3 align="center" style={{ marginBottom: "50px" }}>
            Add Leader
          </h3>

          <div className="addHostCharge">
            <select
              onChange={handleAssignLeader}
              style={{ width: "80%", height: "40px" }}
            >
              <option>Select Leader</option>
              {leaderList.map((item) => {
                return <option value={item._id}>{item.leaderName}</option>;
              })}
            </select>
            <button onClick={handleAddLeader}>Add</button>
          </div>
        </Modal>
        {load ? (
          <>
            <div className="spinnerBg">
              <div>
                <Spinner />
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
        <ToastContainer />
      </LeaderLayOut>
    </div>
  );
};

export default LeaderAcceptedHost;
