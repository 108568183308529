import React from 'react'
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


Modal.setAppElement('#root');


export default function ActionModal(props) {
    let navigate = useNavigate();
    const setOpenModal=props.openMM;
    const  openModal=props.openM;
    const Add=props.addLink;
    const List=props.listLink;

    const handleAdd=()=>{
        setOpenModal(false);
        navigate(Add);
    }

  return (
    <div>
         <Modal isOpen={openModal} style={customStyles}>
        <button className="closeModal" onClick={()=>
        {
          setOpenModal(false)
          navigate(List)
        }
          } >X</button>
        <div style={{fontSize:"20px",fontWeight:"500",marginTop:"20px"}}>Do You Want to Add More {props.keyWord} Or View {props.keyWord} List?</div>
       <div className='ActionButtons'>
        <button onClick={()=>handleAdd()}>Add More {props.keyWord}</button>
        <button onClick={()=>navigate(List)}>View {props.keyWord} List</button>
       </div>
      </Modal>
    </div>
  )
}
