import React, { useState } from "react";

export default function List(props) {
  const TD = props.Today;
  const Wd = props.Week;
  const MD = props.month;


  const handleSelectDate = (e) => {
    props.setDate(e.target.value);
    props.setToday("");
    props.setWeek("");
    props.setMonth("");
    document.getElementById("choose").style.backgroundColor = "#8713d4";
    document.getElementById("choose").style.color = "white";
    document.getElementById("week").style.backgroundColor = "white";
    document.getElementById("week").style.color = "black";
    document.getElementById("monthly").style.backgroundColor = "white";
    document.getElementById("monthly").style.color = "black";
    document.getElementById("today").style.backgroundColor = "white";
    document.getElementById("today").style.color = "black";
    document.getElementById("All").style.backgroundColor = "white";
    document.getElementById("All").style.color = "black";
  };

  const TodayFilter = () => {
    TD();
    document.getElementById("today").style.backgroundColor = "#8713d4";
    document.getElementById("today").style.color = "white";
    document.getElementById("week").style.backgroundColor = "white";
    document.getElementById("week").style.color = "black";
    document.getElementById("monthly").style.backgroundColor = "white";
    document.getElementById("monthly").style.color = "black";
    document.getElementById("choose").style.backgroundColor = "white";
    document.getElementById("choose").style.color = "black";
    document.getElementById("All").style.backgroundColor = "white";
    document.getElementById("All").style.color = "black";
  };

  const WeekFilter = () => {
    Wd();

    document.getElementById("week").style.backgroundColor = "#8713d4";
    document.getElementById("week").style.color = "white";
    document.getElementById("today").style.backgroundColor = "white";
    document.getElementById("today").style.color = "black";
    document.getElementById("monthly").style.backgroundColor = "white";
    document.getElementById("monthly").style.color = "black";
    document.getElementById("choose").style.backgroundColor = "white";
    document.getElementById("choose").style.color = "black";
    document.getElementById("All").style.backgroundColor = "white";
    document.getElementById("All").style.color = "black";
  };

  const MonthlyFilter = () => {
    MD();

    document.getElementById("monthly").style.backgroundColor = "#8713d4";
    document.getElementById("monthly").style.color = "white";

    document.getElementById("today").style.backgroundColor = "white";
    document.getElementById("today").style.color = "black";
    document.getElementById("week").style.backgroundColor = "white";
    document.getElementById("week").style.color = "Black";
    document.getElementById("choose").style.backgroundColor = "white";
    document.getElementById("choose").style.color = "black";
    document.getElementById("All").style.backgroundColor = "white";
    document.getElementById("All").style.color = "black";
  };


  const AllFilter=()=>{
    props.setDate("");
    props.setToday("");
    props.setWeek("");
    props.setMonth("");

    document.getElementById("All").style.backgroundColor = "#8713d4";
    document.getElementById("All").style.color = "white";

    document.getElementById("monthly").style.backgroundColor = "white";
    document.getElementById("monthly").style.color = "black";
    document.getElementById("today").style.backgroundColor = "white";
    document.getElementById("today").style.color = "black";
    document.getElementById("week").style.backgroundColor = "white";
    document.getElementById("week").style.color = "Black";
    document.getElementById("choose").style.backgroundColor = "white";
    document.getElementById("choose").style.color = "black";
  }





  return (
    <div className="chart-container">
      <div className="chart-today">
        <li className="item" id="today" onClick={() => TodayFilter()}>
          <span>Today</span>
        </li>
        <li className="item" id="week" onClick={() => WeekFilter()}>
          <span>Weekly</span>
        </li>
        <li className="item" id="monthly" onClick={() => MonthlyFilter()}>
          <span>Monthly</span>
        </li>
        <li className="item" id="All" onClick={() => AllFilter()}>
          <span>All</span>
        </li>
      </div>
      <div>
        <div className="chart-date">
          <input
            className="datetex"
            id="choose"
            type="date"
            onChange={handleSelectDate}
          />
        </div>
      </div>
    </div>
  );
}
