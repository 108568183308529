import React from "react";
import LeaderSidebar from "./LeaderSidebar";
import LeaderNavbar from "./LeaderNavbar";
const LeaderLayOut = ({ children }) => {
  return (
    <div className="dashboard">
      <LeaderSidebar />

      <div className="right">
        <LeaderNavbar />
        <>{children}</>
      </div>
    </div>
  );
};

export default LeaderLayOut;
