import React, { useEffect, useState } from "react";
import api from "../../../components/api";
import axios from "axios";
import Header from "../../../components/Header";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { TablePagination } from "@mui/material";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function HostAction() {
  let params = useParams();
  let id = params.id;

  const [host, setHost] = useState("");

  const getHost = async () => {
    axios

      .get(api + `/findOneHostrequest/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        console.log("Host", res.data.getOneHost);
        setHost(res.data.getOneHost);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getHost();
  }, []);

  //-----------------pagination here👇👇👇👇-----------------------------------//

  //CallHistory

  const [callpage, setcallPage] = useState(0);
  const [callrowsPerPage, setcallRowsPerPage] = useState(5);
  const handlecallChangePage = (event, newPage) => {
    setcallPage(newPage);
  };

  const handlecallChangeRowsPerPage = (event) => {
    setcallRowsPerPage(event.target.value);
    setcallPage(0);
  };

  //Followers

  const [followpage, setfollowPage] = useState(0);
  const [followrowsPerPage, setfollowRowsPerPage] = useState(5);
  const handlefollowChangePage = (event, newPage) => {
    setfollowPage(newPage);
  };

  const handlefollowChangeRowsPerPage = (event) => {
    setfollowRowsPerPage(event.target.value);
    setfollowPage(0);
  };

  //Following

  const [followingpage, setfollowingPage] = useState(0);
  const [followingrowsPerPage, setfollowingRowsPerPage] = useState(5);
  const handlefollowingChangePage = (event, newPage) => {
    setfollowingPage(newPage);
  };

  const handlefollowingChangeRowsPerPage = (event) => {
    setfollowingRowsPerPage(event.target.value);
    setfollowingPage(0);
  };

  //blocked

  const [blockpage, setBlockPage] = useState(0);
  const [blockrowsPerPage, setBlockRowsPerPage] = useState(5);
  const handleBlockChangePage = (event, newPage) => {
    setBlockPage(newPage);
  };

  const handleBlockChangeRowsPerPage = (event) => {
    setBlockRowsPerPage(event.target.value);
    setBlockPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [videoIsOpen, setVideoIsOpen] = useState(false);

  const handleModal = () => {
    setModalIsOpen(true);
  };

  const handleVideoModal = () => {
    setVideoIsOpen(true);
  };

  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Host Details" />
        <div className="Dash"></div>
        <div className="details">
          <div className="oneflex">
            <img src={host?.userImage} className="oneUserImg" />
            <div className="tableBox">
              <table className="oneUserTable">
                <tr>
                  <th>Id</th>
                  <td>{host?._id}</td>
                </tr>
                <tr>
                  <th>User Id</th>
                  <td>{host?.userId}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{host?.FirstName + " " + host?.LastName}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{host?.Gender}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{host?.dateOfBirth}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{host?.number}</td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>{host?.age}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{host?.city}</td>
                </tr>
                <tr>
                  <th>AadharCard No.</th>
                  <td>{host?.AadharCardNo + "  "}</td>
                </tr>
                <tr>
                  <th>AadharCard Image</th>
                  <td>
                    <i class="fa-solid fa-eye" onClick={handleModal}></i>
                  </td>
                </tr>
              </table>

              <table className="oneUserTable">
                <tr>
                  <th>Status</th>
                  <td>{host?.acctiveStatus}</td>
                </tr>
                <tr>
                  <th>Charge per Minute</th>
                  <td>{host?.hostuser_fees}</td>
                </tr>
                <tr>
                  <th>Total Host Balance</th>
                  <td>{host?.host_balance}</td>
                </tr>
                <tr>
                  <th>Block</th>
                  <td>{host?.blockuser?.length || 0}</td>
                </tr>
                <tr>
                  <th>Followers</th>
                  <td>{host?.followers?.length || 0}</td>
                </tr>
                <tr>
                  <th>Following</th>
                  <td>{host?.followings?.length || 0}</td>
                </tr>
                <tr>
                  <th>Likes</th>
                  <td>{host?.likes?.length || 0}</td>
                </tr>
                <tr>
                  <th>Video Profile</th>
                  <td>
                    <i class="fa-solid fa-eye" onClick={handleVideoModal}></i>
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <h4 className="paymentHead">Host Call History</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>ID</th>
                <th>Coins Earned</th>
                <th>Status</th>
                <th>Time</th>
              </tr>
            </thead>
            <tbody>
              {host?.userCallhistorys
                ?.slice(
                  callpage * callrowsPerPage,
                  callpage * callrowsPerPage + callrowsPerPage
                )
                .map((item, index) => {
                  {
                    console.log("Host call" ,item)
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.targetId?.fullName}</td>
                      <td>{item?.targetId?._id}</td>
                      <td>{item?.targetId?.spentCoins}</td>
                      <td>{item?.targetId?.acctiveStatus}</td>
                      <td>
                        {
                          item?.targetId?.total_minute + " min"
                        }
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.userCallhistorys?.length}
              rowsPerPage={callrowsPerPage}
              page={callpage}
              onPageChange={handlecallChangePage}
              onRowsPerPageChange={handlecallChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Followers List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.followers
                ?.slice(
                  followpage * followrowsPerPage,
                  followpage * followrowsPerPage + followrowsPerPage
                )
                .map((item, index) => {
                  {
                    // console.log("Followers List", item)
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?._id}</td>
                      <td>{item?.email}</td>
                      <td>{item?.phone}</td>
                      <td>{item?.dateOfBirth}</td>
                      <td>{item?.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.followers?.length}
              rowsPerPage={followrowsPerPage}
              page={followpage}
              onPageChange={handlefollowChangePage}
              onRowsPerPageChange={handlefollowChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Following List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.followings
                ?.slice(
                  followingpage * followingrowsPerPage,
                  followingpage * followingrowsPerPage + followingrowsPerPage
                )
                .map((item, index) => {
                  {
                    // console.log("Following List" , item)
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.fullName}</td>
                      <td>{item?._id}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.dateOfBirth}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.followings?.length}
              rowsPerPage={followingrowsPerPage}
              page={followingpage}
              onPageChange={handlefollowingChangePage}
              onRowsPerPageChange={handlefollowingChangeRowsPerPage}
            />
          </div>

          <h4 className="paymentHead">Blocked List</h4>
          <table className="content-table payment">
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Name</th>
                <th>Id</th>
                <th>Email Id</th>
                <th>Mobile</th>
                <th>DOB</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {host?.blockuser
                ?.slice(
                  blockpage * blockrowsPerPage,
                  blockpage * blockrowsPerPage + blockrowsPerPage
                )
                .map((item, index) => {
                  {
                    // console.log("Blocked List", item)
                  }
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      <td>{item?.fullName}</td>
                      <td>{item._id}</td>
                      <td>{item.email}</td>
                      <td>{item.phone}</td>
                      <td>{item.dateOfBirth}</td>
                      <td>{item.acctiveStatus}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[5, 10, 20]}
              component="div"
              count={host?.blockuser?.length}
              rowsPerPage={blockrowsPerPage}
              page={blockpage}
              onPageChange={handleBlockChangePage}
              onRowsPerPageChange={handleBlockChangeRowsPerPage}
            />
          </div>
        </div>
      </div>

      <Modal isOpen={modalIsOpen} style={customStyles}>
        {console.log("Host", host)}
        <button onClick={() => setModalIsOpen(false)} className="closeModal">
          X
        </button>
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Aadhar Card
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={host?.AadharCardFrontPicture}
            alt="AadharCardFrontPicture"
            style={{ width: "300px", height: "auto", marginBottom: "20px" }}
          ></img>
          <img
            src={host?.AadharCardBackPicture}
            alt="AadharCardBackPicture"
            style={{ width: "300px", height: "auto" }}
          ></img>
        </div>
      </Modal>

      <Modal isOpen={videoIsOpen} style={customStyles}>
        <button onClick={() => setVideoIsOpen(false)} className="closeModal">
          X
        </button>
        <div
          style={{
            fontSize: "20px",
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Video Profile
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <img
            src={host?.videoProfile}
            alt="videoProfile"
            style={{ width: "300px", height: "auto", marginBottom: "20px" }}
          ></img>
        </div>
      </Modal>
    </LeaderLayOut>
  );
}
