import React from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
const Header = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className="header-container">
      <div className="header-title" onClick={() => navigate(-1)}>
        <BiArrowBack />
        <h2>{title}</h2>
      </div>
    </div>
  );
};

export default Header;
