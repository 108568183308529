import React, { useState } from "react";
import { AiOutlineDown, AiOutlineGroup, AiOutlineRight } from "react-icons/ai";
import { BsFillEmojiSmileFill, BsSpeedometer2 } from "react-icons/bs";
import { CgGirl, CgProfile } from "react-icons/cg";
import { FaUsers, FaWallet } from "react-icons/fa";
import { IoMdNotifications } from "react-icons/io";
import { Link } from "react-router-dom";
import memeImage from "../assets/AllUserImages/02.png";
import CustomLink from "../components/CustomLink";

const Sidebar = () => {
  const [isVisible, setIsVisible] = useState("");
  const [isOpen, setIsOpen] = useState("");

  const sidebarData = [
    {
      key: "dashboard",
      name: "Dashboard",
      icon: <BsSpeedometer2 />,
      subMenu: false,
      path: "/dashboard",
    },
    {
      key: "user",
      name: "Users",
      icon: <FaUsers />,
      subMenu: true,
      subMenuData: [
        {
          key: "allUsers",
          name: "All Users",
          path: "/allUsers",
        },
        {
          key: "userReport",
          name: "User Report",
          path: "/userReport",
        },
        {
          key: "userFeedback",
          name: "User Feedback",
          path: "/userFeedback",
        },
      ],
    },

    {
      key: "host",
      name: "Host",
      icon: <CgProfile />,
      subMenu: true,
      subMenuData: [
        {
          key: "hostRequest",
          name: "Host Request",
          path: "/hostrequest",
        },
        {
          key: "hostAccept",
          name: "Accepted Host",
          path: "/acceptedHost",
        },
        {
          key: "hostRejected",
          name: "Rejected Host",
          path: "/rejectedHost",
        },
        {
          key: "hostEarnings",
          name: "Host Earnings",
          path: "/hostearnings",
        },
        {
          key: "hostReport",
          name: "Host Report",
          path: "/hostReport",
        },
        {
          key: "hostFeedback",
          name: "Host Feedback",
          path: "/hostFeedback",
        },
      ],
    },
    {
      key: "Leader",
      name: "Leader",
      icon: <FaUsers />,
      subMenu: true,
      subMenuData: [
        {
          key: "addLeader",
          name: "Add Leader",
          path: "/addLeader",
        },
        {
          key: "leaderList",
          name: "Leader List",
          path: "/leaderList",
        },
      ],
    },
    {
      key: "banners",
      name: "Banners",
      icon: <AiOutlineGroup />,
      subMenu: true,
      subMenuData: [
        {
          key: "addbanner",
          name: "Add Banner",
          path: "/addbanner",
        },
        {
          key: "listbanner",
          name: "List Banner",
          path: "/listbanner",
        },
      ],
    },
    {
      key: "wallet",
      name: "Recharge Deals",
      icon: <FaWallet />,
      subMenu: true,
      subMenuData: [
        {
          key: "createwallet",
          name: "Create Deals",
          path: "/Createwallet",
        },
        {
          key: "listwallet",
          name: "List Deals",
          path: "/Listwallet",
        },
      ],
    },
    // {
    //   key: "gift",
    //   name: "Gift",
    //   icon: <AiFillGift />,
    //   subMenu: true,
    //   subMenuData: [
    //     {
    //       key: "addgifts",
    //       name: "Add a Gift",
    //       path: "/Addgifts",
    //     },
    //     {
    //       key: "listgift",
    //       name: "List of Gifts",
    //       path: "/Listgifts",
    //     },
    //   ],
    // },
    // {
    //   key: "emoji",
    //   name: "Emoji",
    //   icon: <BsFillEmojiSmileFill />,
    //   subMenu: true,
    //   subMenuData: [
    //     {
    //       key: "addemoji",
    //       name: "Add Emoji",
    //       path: "/Addemoji",
    //     },
    //     {
    //       key: "listemojis",
    //       name: "List of Emojis",
    //       path: "/Listemoji",
    //     },
    //   ],
    // },
    {
      key: "Sticker",
      name: "Sticker",
      icon: <BsFillEmojiSmileFill />,
      subMenu: true,
      subMenuData: [
        {
          key: "AddSticker",
          name: "Add Sticker",
          path: "/addSticker",
        },
        {
          key: "listSticker",
          name: "Sticker List",
          path: "/listSticker",
        },
      ],
    },

    {
      key: "topGrowing",
      name: "Top Growing ",
      icon: <CgGirl />,
      subMenu: true,
      subMenuData: [
        {
          key: "TopEarningsGirls",
          name: "Top Earnings Host",
          path: "/Topearningsgirls",
        },
        {
          key: "TopUsers",
          name: "Top Spending Users",
          path: "/topUsers",
        },
      ],
    },

    {
      key: "Notification",
      name: "Notification",
      icon: <IoMdNotifications />,
      subMenu: true,
      subMenuData: [
        {
          key: "SendNotification",
          name: "Send Notification",
          path: "/sendNotification",
        },
        {
          key: "listNotification",
          name: "List Notification",
          path: "/notificationList",
        },
      ],
    },
  ];

  const handleDropDown = (key) => {
    if (isVisible === key) {
      setIsVisible("");
    } else {
      setIsVisible(key);
    }
  };
  const handleMenu = (key) => {
    if (isOpen === key) {
      setIsOpen("");
    } else {
      setIsOpen(key);
    }
  };
  // setIsOpen(key);
  /*============================================================== */

  /*============================================================== */
  return (
    <div className="sidebar" id="sidebar">
      <div className="header">
        <img src={memeImage} alt="icon" />
      </div>
      <div className="side-list">
        {sidebarData.map((item, index) => (
          <li className="list-item" key={index}>
            <CustomLink path={item.path}>
              <div
                className="main-group"
                onClick={() => handleDropDown(item.key)}
              >
                <div className="list-group">
                  <div className="icon-holder">{item.icon}</div>
                  <span>{item.name}</span>
                </div>
                {item.subMenu &&
                  (isVisible === item.key ? (
                    <AiOutlineDown
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <AiOutlineRight
                      style={{
                        cursor: "pointer",
                      }}
                    />
                  ))}
              </div>
            </CustomLink>
            {item.subMenu && item.key === isVisible && (
              <ul className="submenu">
                {item.subMenuData.map((data, index) => (
                  <li className="sublist" key={index}>
                    <CustomLink path={data.path}>
                      <div onClick={() => handleMenu(data.key)}>
                        {data.name}
                      </div>
                    </CustomLink>
                    {data.subMenu && data.key === isOpen && (
                      <ul className="submenu level3">
                        {data.subMenuData.map((value, index) => (
                          <Link to={value.path}>
                            <li className="sublist" key={index}>
                              <div>{value.name}</div>
                            </li>
                          </Link>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </div>
    </div>
  );
};

export default Sidebar;
