import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import api from "../components/api";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import Spinner from "../components/Spinner";

const Addbanner = () => {
  let navigate = useNavigate();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [load, setLoad] = useState(false);

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleAddBanner = () => {
    setLoad(true);
    axios
      .post(
        api + `/admin/addBanner`,
        { name: name, description: description },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoad(false);
        setTimeout(() => {
          navigate(`/uploadBannerImage/${res.data.response._id}`);
        }, 3000);
      })
      .catch((e) => {
        setLoad(false);
        toast.error("banner Not added");
        console.log("error", e);
      });
  };

  //
  //handleCancelBtn-------------------------------------------------------------//
  //
  const handleCancelBtn = () => {
    setName("");
    setDescription("");
    document.getElementById("nameid").value = "";
    document.getElementById("despid").value = "";
  };
  //
  //---------------------------------------------------------------------------//
  //

  return (
    <LayOut>
      <div className="addheader">
        <Header title="Add Banner" />
      </div>
      <div className="addbannermain">
        <div className="addinput smallInput">
          Banner Name
          <input
            className="addinputtext"
            id="nameid"
            type="text"
            onChange={handleName}
          />
        </div>

        <div className="addinput smallInput">
          Banner Description
          <textarea
            className="textBox"
            type="text"
            id="despid"
            onChange={handleDescription}
          ></textarea>
          <p
            style={{
              color: "red",
              fontSize: "12px",
              fontWeight: "bold",
              marginTop: "5px",
            }}
          >
            *The Banner Size should be 2419px X 718px
          </p>
        </div>

        <div className="savecancelbutton">
          <button
            className="accptBtn"
            type="submit"
            value="Submit"
            onClick={() => handleAddBanner()}
          >
            SAVE
          </button>
          <button
            className="rejectBtn"
            type="submit"
            value="Submit"
            onClick={() => handleCancelBtn()}
          >
            CANCEL
          </button>
        </div>
      </div>
      <ToastContainer />
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LayOut>
  );
};

export default Addbanner;
