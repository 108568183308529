import TablePagination from "@mui/material/TablePagination";
import axios from "axios";
import { City, Country, State } from "country-state-city";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { BsCardList } from "react-icons/bs";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import List from "../components/List";
import Spinner from "../components/Spinner";
import api from "../components/api";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "600px", // Adjust the maximum width as needed
    width: "100%",
    padding: "20px",
    background: "#fff",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    maxHeight: "90vh",
    overflowY: "scroll",
  },
};

Modal.setAppElement("#root");

const LeaderList = () => {
  const [load, setLoad] = useState(false);
  const [leaderList, setLeaderList] = useState([]);
  const [hostModal, setHostModal] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const countries = Country.getAllCountries();
  const states = State.getStatesOfCountry(selectedCountry);
  const cities = City.getCitiesOfState(selectedState);

  let navigate = useNavigate();
  const handleAction = (id) => {
    navigate(`/HostDetail/${id}`);
  };

  const handleLeaderList = async () => {
    setLoad(true);
    axios
      .get(api + "/admin/getAllLeader", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setLeaderList(res.data.result.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    handleLeaderList();
  }, []);

  const handleHostList = (id) => {
    setHostModal(true);
    setLoad(true);
    axios
      .get(api + `/getAdminLeaderHostlist/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setHosts(res.data.result);
        setPage(0); // Reset the page to 0 when switching leader lists
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
        setHosts([]); // Reset hosts to an empty array when there are no hosts
        setPage(0); // Reset the page to 0 when switching leader lists
      });
  };

  const acceptTableRef = useRef(null);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  const [hostPage, setHostPage] = useState(0);
  const [hostRowsPerPage, setHostRowsPerPage] = useState(10);

  const handleHostPageChange = (event, newPage) => {
    setHostPage(newPage);
  };

  const handleHostRowsPerPageChange = (event) => {
    setHostRowsPerPage(event.target.value);
    setHostPage(0);
  };

  const [openEditLeader, setOpenEditLeader] = useState(false);
  const [leaderToEdit, setLeaderToEdit] = useState(null);
  const [leaderEditForm, setLeaderEditForm] = useState(null);

  useEffect(() => {
    setLeaderEditForm({
      leaderName: leaderToEdit?.leaderName,
      mobileNumber: leaderToEdit?.mobileNumber,
      gender: leaderToEdit?.gender,
      email: leaderToEdit?.email,
      idProof: leaderToEdit?.idProof,
      groupName: leaderToEdit?.groupName,
      country: leaderToEdit?.country,
      city: leaderToEdit?.city,
      state: leaderToEdit?.state,
      pin: leaderToEdit?.pin,
    });
  }, [leaderToEdit]);

  const handleEditLeader = (leader) => {
    setLeaderToEdit(leader);
    setOpenEditLeader(true);
  };

  const handleCloseEditLeader = () => {
    setOpenEditLeader(false);
  };

  const handleEditLeaderSubmit = (e) => {
    e.preventDefault();
    setLoad(true);
    axios
      .put(api + `/admin/updateLeader/${leaderToEdit?._id}`, leaderEditForm, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        toast.success("Leader Updated Successfully");
        setLoad(false);
        setOpenEditLeader(false);
        handleLeaderList();
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  const handleDeleteLeader = () => {
    setLoad(true);
    axios
      .delete(api + `/admin/leaderDeleted/${leaderToEdit?._id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        toast.success("Leader Deleted Successfully");
        setLoad(false);
        setOpenEditLeader(false);
        handleLeaderList();
      })
      .catch((error) => {
        toast.error("Error Deleting The Leader");
        setLoad(false);
        console.log(error);
      });
  };

  const [openEditPasswordModal, editPasswordModalOpen] = useState(false);
  const [passwordID, setPasswordID] = useState(null);
  // const [passwordData, editPasswordData] = useState({
  //   password: "",
  //   conformPassword: "",
  // });

  const [password, setPassword] = useState("");
  const [conformPassword, setConformPassword] = useState("");

  const handleEditPasswordModalOpen = (id) => {
    editPasswordModalOpen(true);
    setPasswordID(id);
  };

  const handleEditPasswordChange = (e) => {
    e.preventDefault();
    setLoad(true);
    axios
      .put(
        api + `/admin/leaderChangesPassword`,
        {
          id: passwordID,
          newPassword: password,
          conformPassword: conformPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Password Updated Successfully");
        setLoad(false);
        editPasswordModalOpen(false);
        handleLeaderList();
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };

  return (
    <div id="container">
      <LayOut>
        <div className="header">
          <Header title="Leader List" />
          <div className="Dash">
            <div className="container">
              <div className="expt">
                <DownloadTableExcel
                  filename="users table"
                  sheet="users"
                  currentTableRef={acceptTableRef.current}
                >
                  <button className="export">
                    Export
                    <div className="expicon">
                      <BiExport size={20} />
                    </div>
                  </button>
                </DownloadTableExcel>
              </div>
              <List
                Today={handleTodayData}
                setDate={setChooseDate}
                setToday={setTodayDate}
                setWeek={setWeekDate}
                setMonth={setMonthDate}
                Week={handleWeekData}
                month={handleMonthData}
              />
            </div>
          </div>
        </div>
        <div className="details">
          <div
            className="table-container"
            style={{
              height: "50vh",
            }}
          >
            <table className="content-table" ref={acceptTableRef}>
              <thead className="table-head">
                <tr>
                  <th>S.No.</th>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Gender</th>
                  <th>IdProof</th>
                  <th>Group Name</th>
                  <th>Country</th>
                  <th>City</th>
                  <th>Pin</th>
                  <th>State</th>
                  <th>Password</th>
                  <th>Host</th>
                  <th>Edit Leader</th>
                </tr>
              </thead>
              <tbody>
                {leaderList
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      moment(item.createdAt)
                        .format()
                        .split("T")[0]
                        .includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.createdAt)
                        .format()
                        .split("T")[0]
                        .includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.createdAt).format() >= weekDate &&
                      moment(item.createdAt).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.createdAt).format() >= monthDate &&
                      moment(item.createdAt).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <tr key={item._id}>
                        <td>{index + 1}</td>
                        <td>{item?._id}</td>
                        <td>{item?.leaderName}</td>
                        <td>{item?.email}</td>
                        <td>{item?.mobileNumber}</td>
                        <td>{item?.gender}</td>
                        <td>{item?.idProof}</td>
                        <td>{item?.groupName}</td>
                        <td>{item?.country}</td>
                        <td>{item?.city}</td>
                        <td>{item?.pin}</td>
                        <td>{item?.state}</td>
                        <td>
                          {item?.conformPassword}
                          <i
                            onClick={() => {
                              handleEditPasswordModalOpen(item?._id);
                            }}
                            class="fa-solid fa-pen-to-square HostEdit"
                          ></i>
                        </td>
                        <td onClick={() => handleHostList(item?._id)}>
                          <BsCardList />
                        </td>
                        <td
                          onClick={() => handleEditLeader(item)}
                          style={{
                            color: "#116FCD",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          Edit
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={leaderList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
          {hosts && hosts.length > 0 ? (
            <>
              <div
                className="table-container"
                style={{
                  height: "30vh",
                  overflowY: "scroll",
                  marginTop: "-20px",
                }}
              >
                <table className="content-table" ref={acceptTableRef}>
                  <thead className="table-head">
                    <tr>
                      <th>S.No.</th>
                      <th>Id</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Date of Birth</th>
                      <th>Phone</th>
                      <th>Email</th>
                      <th>Gender</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {hosts
                      ?.slice(
                        hostPage * hostRowsPerPage,
                        hostPage * hostRowsPerPage + hostRowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?._id}</td>
                            <td>{item?.FirstName}</td>
                            <td>{item?.LastName}</td>
                            <td>{item?.dateOfBirth?.split("T")[0]}</td>
                            <td>{item?.email}</td>
                            <td>{item?.number}</td>
                            <td>{item?.Gender}</td>
                            <td
                              onClick={() => handleAction(item?._id)}
                              style={{
                                color: "#116FCD",
                                fontWeight: "500",
                                fontSize: "16px",
                              }}
                            >
                              View
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="paginationAlign">
                <TablePagination
                  rowsPerPageOptions={[10, 15, 20, 25]}
                  component="div"
                  count={hosts.length}
                  rowsPerPage={hostRowsPerPage}
                  page={hostPage}
                  onPageChange={handleHostPageChange}
                  onRowsPerPageChange={handleHostRowsPerPageChange}
                />
              </div>
            </>
          ) : (
            <>{page === 0 && <h3>No Hosts Found</h3>}</>
          )}
        </div>

        {load ? (
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        ) : (
          <></>
        )}

        <ToastContainer />

        <Modal
          isOpen={openEditLeader}
          onRequestClose={handleCloseEditLeader}
          style={customStyles}
        >
          <button
            className="colseModal"
            onClick={() => {
              handleCloseEditLeader();
            }}
          >
            X
          </button>
          <div className="modalHeader">
            <h2>Edit Leader</h2>
          </div>

          <div className="modalBody">
            <form className="form" onSubmit={handleEditLeaderSubmit}>
              <div className="form-group">
                <label htmlFor="fullName" className="form-label">
                  Full Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="fullName"
                  value={leaderEditForm?.leaderName}
                  onChange={(e) =>
                    setLeaderEditForm({
                      ...leaderEditForm,
                      leaderName: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  value={leaderEditForm?.email}
                  onChange={(e) =>
                    setLeaderEditForm({
                      ...leaderEditForm,
                      email: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="mobileNumber" className="form-label">
                  Phone
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="mobileNumber"
                  name="mobileNumber"
                  value={leaderEditForm?.mobileNumber}
                  onChange={(e) =>
                    setLeaderEditForm({
                      ...leaderEditForm,
                      mobileNumber: e.target.value,
                    })
                  }
                />
              </div>

              <div className="form-group">
                <label htmlFor="gender" className="form-label">
                  Gender
                </label>
                <select
                  className="form-control"
                  id="gender"
                  name="gender"
                  value={leaderEditForm?.gender}
                  onChange={(e) =>
                    setLeaderEditForm({
                      ...leaderEditForm,
                      gender: e.target.value,
                    })
                  }
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div className="form-group">
                <label htmlFor="idProof" className="form-label">
                  Id Proof
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="idProof"
                  name="idProof"
                  value={leaderEditForm?.idProof}
                  onChange={(e) =>
                    setLeaderEditForm({
                      ...leaderEditForm,
                      idProof: e.target.value,
                    })
                  }
                />

                <div className="form-group">
                  <label htmlFor="groupName" className="form-label">
                    Group Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="groupName"
                    name="groupName"
                    value={leaderEditForm?.groupName}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        groupName: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="country" className="form-label">
                    Country
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="country"
                    name="country"
                    value={leaderEditForm?.country}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        country: e.target.value,
                      })
                    }
                  />
                  {/* <select
                    className="form-control"
                    id="country"
                    name="country"
                    value={leaderToEdit?.country}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        country: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select> */}
                </div>

                <div className="form-group">
                  <label htmlFor="city" className="form-label">
                    City
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={leaderEditForm?.city}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        city: e.target.value,
                      })
                    }
                  />

                  {/* <select
                    className="form-control"
                    id="city"
                    name="city"
                    value={leaderToEdit?.city}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        city: e.target.value,
                      })
                    }
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.isoCode} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select> */}
                </div>

                <div className="form-group">
                  <label htmlFor="pin" className="form-label">
                    Pin
                  </label>
                  <input
                    type="number"
                    className="form-control"
                    id="pin"
                    name="pin"
                    value={leaderEditForm?.pin}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        pin: e.target.value,
                      })
                    }
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="state" className="form-label">
                    State
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="state"
                    name="state"
                    value={leaderEditForm?.state}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        state: e.target.value,
                      })
                    }
                  />

                  {/* <select
                    className="form-control"
                    id="state"
                    name="state"
                    value={leaderToEdit?.state}
                    onChange={(e) =>
                      setLeaderEditForm({
                        ...leaderEditForm,
                        state: e.target.value,
                      })
                    }
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select> */}
                </div>

                <button type="submit" className="form-button">
                  Submit
                </button>
                <button
                  className="form-button"
                  onClick={handleDeleteLeader}
                  style={{
                    backgroundColor: "red",
                    marginLeft: "10px",
                  }}
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </Modal>

        <Modal
          isOpen={openEditPasswordModal}
          onRequestClose={() => editPasswordModalOpen(false)}
          style={customStyles}
        >
          <button
            className="colseModal"
            onClick={() => {
              editPasswordModalOpen(false);
            }}
          >
            X
          </button>
          <div className="modalHeader">
            <h2>Edit Password</h2>
          </div>

          <div className="modalBody">
            <form className="form" onSubmit={handleEditPasswordChange}>
              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label htmlFor="conformPassword" className="form-label">
                  Conform Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="conformPassword"
                  name="conformPassword"
                  value={conformPassword}
                  onChange={(e) => setConformPassword(e.target.value)}
                />
              </div>

              <button type="submit" className="form-button">
                Submit
              </button>
            </form>
          </div>
        </Modal>
      </LayOut>
    </div>
  );
};

export default LeaderList;
