import React, { useEffect, useState } from "react";
import api from "../components/api";
import axios from "axios";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import { useParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-modal';
import ReactPlayer from "react-player";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


Modal.setAppElement('#root');

export default function HostAction() {
  let params = useParams();
  let id = params.id;

  const [host, setHost] = useState("");

  const getHost = async () => {
    axios

      .get(api + `/findOneHostrequest/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setHost(res.data.getOneHost);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getHost();
  }, []);


    const[modalIsOpen,setModalIsOpen]  = useState(false)
    const[videoIsOpen,setVideoIsOpen]  = useState(false)

    const handleModal=()=>{
      setModalIsOpen(true)
    }

    const handleVideoModal=()=>{
      setVideoIsOpen(true)
    }


  return (
    <LayOut>
      <div className="header">
        <Header title="Host Details" />
        <div className="Dash">
        </div>
        <div className="details">
          <div className="oneflex">
            <img src={host?.userImage} className="oneUserImg" />
            <div className="tableBox">
              <table className="oneUserTable">
              <tr>
                  <th>Id</th>
                  <td>{host?._id}</td>
                </tr>
                <tr>
                  <th>User Id</th>
                  <td>{host?.userId}</td>
                </tr>
                <tr>
                  <th>Name</th>
                  <td>{host?.FirstName+" "+host?.LastName}</td>
                </tr>
                <tr>
                  <th>Gender</th>
                  <td>{host?.Gender}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{host?.dateOfBirth}</td>
                </tr>
                <tr>
                  <th>Phone</th>
                  <td>{host?.number}</td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>{host?.age}</td>
                </tr>
                <tr>
                  <th>City</th>
                  <td>{host?.city}</td>
                </tr>
                
              </table>


              <table className="oneUserTable">
                <tr>
                  <th>Status</th>
                  <td>{host?.acctiveStatus}</td>
                </tr>
                <tr>
                  <th>AadharCard No.</th>
                  <td>{host?.AadharCardNo+"  "}</td>
                </tr>
                <tr>
                  <th>AadharCard Image</th>
                  <td><i class="fa-solid fa-eye" onClick={handleModal}></i></td>
                </tr>
                
                <tr>
                  <th>Video Profile</th>
                  <td><i class="fa-solid fa-eye" onClick={handleVideoModal}></i></td>
                </tr>
              </table>
            </div>
          </div>


        </div>
      </div>

      

      <Modal isOpen={modalIsOpen} style={customStyles} >
        <button onClick={()=>setModalIsOpen(false)} className="closeModal">X</button>
        <div style={{fontSize:"20px",textAlign:"center",fontWeight:"bold",marginBottom:"10px" }}>Aadhar Card</div>
        <div style={{display:"flex", flexDirection:"column"}}>
          <img src={host?.AadharCardFrontPicture} alt="AadharCardFrontPicture" style={{width:"300px", height:"auto", marginBottom:"20px"}}></img>
          <img src={host?.AadharCardBackPicture} alt="AadharCardBackPicture" style={{width:"300px", height:"auto"}}></img>
        </div>
      </Modal>

      <Modal isOpen={videoIsOpen} style={customStyles} >
        <button onClick={()=>setVideoIsOpen(false)} className="closeModal">X</button>
        <div style={{fontSize:"20px",textAlign:"center",fontWeight:"bold",marginBottom:"10px" }}>
        <ReactPlayer
                url={host.videoProfile}
                controls={true}
                width="100%"
                height="auto"
              />
              <p>Video Profile</p>
          </div>
        <div style={{display:"flex", flexDirection:"column"}}>
        
        </div>
      </Modal>


    </LayOut>
  );
}
