import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import List from "../components/List";
import LayOut from "../components/Layout";
import Header from "../components/Header";
import { BiExport } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";
import api from "../components/api";
import Modal from "react-modal";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "@mui/material";
import Spinner from "../components/Spinner";
import moment from "moment";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
  },
};

Modal.setAppElement("#root");

const ListGift = () => {
  let navigate = useNavigate();

  const [giftLists, setGiftLists] = useState([]);
  const [imageurl, setImageurl] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [load, setLoad] = useState(false);

  const getGiftList = async () => {
    setLoad(true);
    await axios
      .get(api + "/admin/getGift", {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setLoad(false);
        setGiftLists(res.data.reverse());
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getGiftList();
  }, []);

  const [imageId, setImageId] = useState("");
  const handlImage = (val) => {
    setImageurl(val.split("space")[0]);
    setImageId(val.split("space")[1]);
    setImageModal(true);
  };

  //-----------------------------------Delete Banner Api -----------------------------------//
  const handleDelte = (val) => {
    setDeleteModal(true);
    setDeleteId(val);
  };

  const handleDelete = (id) => {
    setLoad(true);
    axios
      .delete(api + `/admin/deleteGift/${id}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then(() => {
        setLoad(false);
        toast.success("Gift Deleted");
        getGiftList();
      })
      .catch((e) => {
        setLoad(false);
        console.log("error", e);
        toast.error("Gift not Deleted");
      });
    setDeleteModal(false);
  };
  //-----------------------------------get one Gift Api -----------------------------------//

  const getOneBanner = async () => {
    await axios
      .get(api + `/findOnegift/${updateId}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setName(res?.data?.findgift?.name);
        setPrice(res?.data?.findgift?.coins);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getOneBanner();
  }, [updateId]);

  //-----------------------------------Update Gift Api -----------------------------------//
  const handleUpdate = (val) => {
    setUpdateModal(true);
    setUpdateId(val);
  };

  const handleUpdateName = (e) => {
    setName(e.target.value);
  };

  const handleUpdatePrice = (e) => {
    setPrice(e.target.value);
  };

  const handleUpdateBtn = () => {
    setLoad(true);
    axios
      .put(
        api + `/admin/updategift/${updateId}`,
        {
          name: name,
          coins: price,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(() => {
        setLoad(false);
        toast.success("Gift Updated");
        getGiftList();
      })
      .catch((e) => {
        setLoad(false);
        console.log("error", e);
        toast.error("Gift not Updated");
      });
    setUpdateModal(false);
  };

  const giftListTableRef = useRef(null);

  //UpdateImage------------------------------------------------------------------//
  const handleUpdateImageModal = (id) => {
    navigate(`/uploadImage/${id}`);
  };

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //---------------------------------------filter Acc to Date-----------------------//
  
  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate,setMonthDate] = useState("")

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };
 
  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7,'days').format();
  let monthhDate = moment().subtract(1,'months').format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

    //------------------------------------Filter Acc to  Date End---------------------------------------//

  return (
    <LayOut>
      <div className="header">
        <Header title="Gift List" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={giftListTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={giftListTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Gift Name</th>
                <th>Gift Image</th>
                <th>Gift Price</th>
                <th>createdAt</th>
                <th>updatedAt</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {giftLists?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                    
                  } else if (item.createdAt.split("T")[0].includes(todayDate) && !chooseDate && !weekDate && !monthDate ) {
                    return item;
    
                  } else if (item.createdAt.split("T")[0].includes(chooseDate) && !todayDate && !weekDate && !monthDate) {
                    return item;
                  } else if(item.createdAt >= weekDate && item.createdAt <= Date1 && !todayDate && !chooseDate && !monthDate ){
                    return item;
                  }else if(item.createdAt >= monthDate && item.createdAt <= Date1 && !todayDate && !chooseDate && !weekDate){
                    return item;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{item?.name}</td>
                      <td
                        onClick={() =>
                          handlImage(item?.stickerUrl + "space" + item._id)
                        }
                      >
                        <i class="fa-solid fa-eye"></i>
                      </td>
                      <td>{item?.coins}</td>
                      <td>{item?.createdAt?.substring(0,10)}</td>
                      <td>{item?.updatedAt?.substring(0,10)}</td>
                      <td>
                        <i
                          className="fa-solid fa-pen-to-square ListEdit"
                          onClick={() => handleUpdate(item._id)}
                        ></i>
                        <i
                          class="fa-solid fa-trash ListDelte"
                          onClick={() => handleDelte(item._id)}
                        ></i>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          
        </div>
        <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10,15, 20,25,30]}
              component="div"
              count={giftLists.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
      </div>

      {/* //------------------------------------UpdateModal---------------------------------------// */}

      <Modal isOpen={updateModal} style={customStyles}>
        <button className="colseModal" onClick={() => setUpdateModal(false)}>
          X
        </button>
        <div className="updateModal">
          <div className="addinput">
            Gift Name
            <input
              className="addinputtext"
              type="text"
              value={name}
              onChange={handleUpdateName}
            />
          </div>

          <div className="addinput">
            Gift Price
            <input
              className="addinputtext"
              type="text"
              value={price}
              onChange={handleUpdatePrice}
            />
          </div>
          <div className="updateBtns">
            <button className="UpdateBtn" onClick={() => handleUpdateBtn()}>
              Update
            </button>
            <button className="CancelBtn">Cancel</button>
          </div>
        </div>
      </Modal>

      {/* //------------------------------------DeleteModal---------------------------------------// */}

      <Modal isOpen={deleteModal} style={customStyles}>
        <button className="colseModal" onClick={() => setDeleteModal(false)}>
          X
        </button>
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          Are you Sure You Want To Delete this Banner?
        </h3>
        <div className="updateBtns">
          <button className="UpdateBtn" onClick={() => handleDelete(deleteId)}>
            Delete
          </button>
          <button className="CancelBtn" onClick={() => setDeleteModal(false)}>
            Cancel
          </button>
        </div>
      </Modal>

      {/* //------------------------------------imageModal---------------------------------------// */}

      <Modal isOpen={imageModal} style={customStyles}>
        <button className="colseModal" onClick={() => setImageModal(false)}>
          X
        </button>
        <img src={imageurl} className="bannerImage" alt="banerImage"></img>
        <div className="updateImageBtn">
          <button onClick={() => handleUpdateImageModal(imageId)}>
            Update Image
          </button>
        </div>
      </Modal>
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <ToastContainer />
    </LayOut>
  );
};

export default ListGift;
