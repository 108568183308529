import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import api from "../components/api";
import herImage from "../assets/AllUserImages/Hero.jpg";
import { toast } from "react-toastify";
import Modal from "react-modal";

const Login = () => {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "50%",
    },
  };

  const navigate = useNavigate();
  const [usernameField, setUsernameField] = useState("");
  const [passwordField, setPasswordField] = useState("");

  const username = (e) => {
    setUsernameField(e.target.value);
  };
  const password = (e) => {
    setPasswordField(e.target.value);
  };

  const formSubmit = () => {
    axios
      .post(api + "/admin/login", {
        email: usernameField,
        password: passwordField,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        toast.success("Login Successful");
        navigate("/dashboard");
      })
      .catch(function (error) {
        console.log(error);
        if (usernameField === "") {
          toast.error("Please Enter Username");
          return;
        } else if (passwordField === "") {
          toast.error("Please Enter Password");
          return;
        } else {
          toast.error("Invalid Credentials");
          return;
        }
      });
  };

  const [openChangePassword, setOpenChangePassword] = useState(false);
  const [enterOTP, setEnterOTP] = useState(false);
  const [otp, setOTP] = useState("");

  const [newPassword, setNewPassword] = useState("");

  const handleEmailSubmit = () => {
    console.log("handleEmailSubmit");
    console.log(usernameField);
    axios
      .put(api + "/adminForgetPassword", {
        email: usernameField,
      })
      .then((response) => {
        toast.success("Email Sent Successful");
        setEnterOTP(true);
        setOpenChangePassword(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Email Sent Failed");
      });
  };

  const handleConfirmPassword = () => {
    console.log("handleConfirmPassword");
    console.log(usernameField);
    axios
      .put(api + "/adminResetPasword/", {
        email: usernameField,
        otp: otp,
        newPassword: newPassword,
      })
      .then((response) => {
        toast.success("Password Changed Successful");
        setEnterOTP(true);
        setOpenChangePassword(false);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Password Changed Failed");
      });
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        overflowY: "hidden",
      }}
    >
      <div className="mainlogin">
        <div className="headerlogin">
          <h1 className="login">Welcome To Catchwoo</h1>
          <h2>Please Login To Your Account</h2>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div>
            <label className="username" for="uname"></label>
            <input
              className="usernamebox"
              type="text"
              placeholder="Enter Username"
              name="uname"
              onChange={username}
              required
            />
          </div>
          <div>
            <label className="userpassword" for="psw">
              {/* <b> Password</b> */}
            </label>
            <input
              className="passbox"
              type="password"
              placeholder="Enter Password"
              name="psw"
              onChange={password}
              required
            />
          </div>
        </div>
        <div>
          <button className="submitbtn" type="submit" onClick={formSubmit}>
            Login
          </button>
        </div>
        <div
          style={{
            alignSelf: "flex-start",
            cursor: "pointer",
            marginLeft: "20px",
            marginTop: "-20px",
          }}
        >
          <p>
            Forgot Password?{" "}
            <span
              style={{ color: "#116FCD" }}
              onClick={() => {
                setOpenChangePassword(true);
              }}
            >
              Click Here
            </span>
          </p>
        </div>
      </div>

      <div>
        <img className="hero" src={herImage} alt="erorr"></img>
      </div>

      <Modal isOpen={openChangePassword} style={customStyles}>
        <h3 style={{ textAlign: "center" }}>Enter Your Email</h3>
        <button
          className="closeModal"
          onClick={() => setOpenChangePassword(false)}
        >
          X
        </button>
        <div className="alignInBtn">
          <input
            type="email"
            className="helpLineInput"
            value={usernameField}
            onChange={(e) => setUsernameField(e.target.value)}
          />

          <button onClick={handleEmailSubmit}>Submit</button>
        </div>
      </Modal>
      <Modal isOpen={enterOTP} style={customStyles}>
        <h3 style={{ textAlign: "center" }}>Change Password</h3>
        <button className="closeModal" onClick={() => setEnterOTP(false)}>
          X
        </button>
        <div className="alignInBtn">
          <input
            type="email"
            className="helpLineInput"
            value={usernameField}
            readOnly
          />
          <input
            type="text"
            className="helpLineInput"
            maxLength="6"
            value={otp}
            onChange={(e) => setOTP(e.target.value)}
          ></input>
          <input
            style={{
              marginBottom: "10px",
            }}
            type="password"
            className="helpLineInput"
            placeholder="New password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          ></input>

          <button
            style={{
              fontSize: "15px",
            }}
            onClick={() => {
              setEnterOTP(false);
              handleConfirmPassword();
            }}
          >
            Chnage Password
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
