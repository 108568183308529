import React, { useEffect, useState, useRef } from "react";
import { TablePagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import api from "../components/api";
import Header from "../components/Header";
import LayOut from "../components/Layout";
import List from "../components/List";

export default function NotificationList() {
  const NotificationList = useRef(null);

  const [notificationData, setNotificationData] = useState([]);
  const [userNotificationData, setUserNotificationData] = useState([]);
  const [hostNotificationData, setHostNotificationData] = useState([]);
  const [allNotification, setAllNotification] = useState([]);

  const getAlllNotifications = () => {
    axios
      .get(api + `/allNotiAdmin`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setAllNotification(res?.data?.getalldata?.reverse());
      })
      .catch((e) => {
        console.log("notificationListError", e);
      });
  };

  useEffect(() => {
    getAlllNotifications();
  }, []);

  const handleNotificationList = () => {
    axios
      .get(api + `/adminGetNotificationNew`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      })
      .then((res) => {
        setNotificationData(res.data.getalldata.reverse());
      })
      .catch((e) => {
        console.log("notificationListError", e);
      });
  };

  const separateNotifications = (notificationData) => {
    const userNotifications = [];
    const hostNotifications = [];

    notificationData.forEach((notification) => {
      if (notification.uid) {
        userNotifications.push(notification); // User notification
      } else if (notification.hostId) {
        hostNotifications.push(notification); // Host notification
      }
    });

    setUserNotificationData(userNotifications);
    setHostNotificationData(hostNotifications);
  };

  useEffect(() => {
    handleNotificationList();
  }, []);

  // When notificationData changes, call the function to separate notifications
  useEffect(() => {
    separateNotifications(notificationData);
  }, [notificationData]);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page1, setPage1] = useState(0);
  const [rowsPerPage1, setRowsPerPage1] = useState(10);
  const handleChangePage1 = (event, newPage) => {
    setPage1(newPage);
  };

  const handleChangeRowsPerPage1 = (event) => {
    setRowsPerPage1(event.target.value);
    setPage1(0);
  };

  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  const handleChangeRowsPerPage2 = (event) => {
    setRowsPerPage2(event.target.value);
    setPage2(0);
  };

  const [page3, setPage3] = useState(0);
  const [rowsPerPage3, setRowsPerPage3] = useState(10);
  const handleChangePage3 = (event, newPage) => {
    setPage3(newPage);
  };

  const handleChangeRowsPerPage3 = (event) => {
    setRowsPerPage3(event.target.value);
    setPage3(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  return (
    <LayOut>
      <div className="header">
        <Header title="Notification List" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={NotificationList.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <h3>Host Notification</h3>
          <table className="content-table" ref={NotificationList}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Recipient</th>
                <th>Id</th>
                <th>Title</th>
                <th>Message</th>
                <th>createdAt</th>
              </tr>
            </thead>
            <tbody>
              {hostNotificationData
                ?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(todayDate) &&
                    !chooseDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(chooseDate) &&
                    !todayDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= weekDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= monthDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !weekDate
                  ) {
                    return item;
                  }
                })
                .slice(page1 * rowsPerPage1, page1 * rowsPerPage1 + rowsPerPage1)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage1 * page1}</td>
                      <td>
                        {item?.hostId ? (
                          <>Host</>
                        ) : item?.uid ? (
                          <>User</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item?.hostId || item?.uid}</td>
                      <td>{item?.title}</td>
                      <td title={item?.body}>
                        {item?.body?.substring(0, 25) + "..."}
                      </td>
                      <td>{item?.date?.substring(0, 25)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={hostNotificationData.length}
              rowsPerPage={rowsPerPage1}
              page={page1}
              onPageChange={handleChangePage1}
              onRowsPerPageChange={handleChangeRowsPerPage1}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <h3>User Notification</h3>
          <table className="content-table" ref={NotificationList}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Recipient</th>
                <th>Id</th>
                <th>Title</th>
                <th>Message</th>
                <th>createdAt</th>
              </tr>
            </thead>
            <tbody>
              {userNotificationData
                ?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(todayDate) &&
                    !chooseDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(chooseDate) &&
                    !todayDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= weekDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= monthDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !weekDate
                  ) {
                    return item;
                  }
                })
                .slice(page2 * rowsPerPage2, page2 * rowsPerPage2 + rowsPerPage2)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage2 * page2}</td>
                      <td>
                        {item?.hostId ? (
                          <>Host</>
                        ) : item?.uid ? (
                          <>User</>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item?.hostId || item?.uid}</td>
                      <td>{item?.title}</td>
                      <td title={item?.body}>
                        {item?.body?.substring(0, 25) + "..."}
                      </td>
                      <td>{item?.date?.substring(0, 25)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={userNotificationData.length}
              rowsPerPage={rowsPerPage2}
              page={page2}
              onPageChange={handleChangePage2}
              onRowsPerPageChange={handleChangeRowsPerPage2}
            />
          </div>
        </div>
        <div
          style={{
            marginTop: "20px",
          }}
        >
          <h3>All Notification</h3>
          <table className="content-table" ref={NotificationList}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Title</th>
                <th>Message</th>
                <th>createdAt</th>
              </tr>
            </thead>
            <tbody>
              {allNotification
                ?.filter((item) => {
                  if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(todayDate) &&
                    !chooseDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time.split("T")[0].includes(chooseDate) &&
                    !todayDate &&
                    !weekDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= weekDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !monthDate
                  ) {
                    return item;
                  } else if (
                    item?.time >= monthDate &&
                    item?.time <= Date1 &&
                    !todayDate &&
                    !chooseDate &&
                    !weekDate
                  ) {
                    return item;
                  }
                })
                .slice(page3 * rowsPerPage3, page3 * rowsPerPage3 + rowsPerPage3)
                .map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1 + rowsPerPage3 * page3}</td>
                      <td>{item?.title}</td>
                      <td title={item?.body}>
                        {item?.body?.substring(0, 25) + "..."}
                      </td>
                      <td>{item?.date?.substring(0, 25)}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="paginationAlign">
            <TablePagination
              rowsPerPageOptions={[10, 15, 20, 25]}
              component="div"
              count={allNotification.length}
              rowsPerPage={rowsPerPage3}
              page={page3}
              onPageChange={handleChangePage3}
              onRowsPerPageChange={handleChangeRowsPerPage3}
            />
          </div>
        </div>
      </div>
    </LayOut>
  );
}
