import { TablePagination } from "@mui/material";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { BiExport } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { useMessage } from "../../../base/context/MessageProvider";
import Header from "../../../components/Header";
import LeaderLayOut from "../../../components/LeaderLayout/LeaderLayout";
import List from "../../../components/List";
import Spinner from "../../../components/Spinner";
import api from "../../../components/api";
const LeaderRejectedHost = () => {
  const message = useMessage();
  const [leaderRejectedHost, setLeaderRejectedHost] = useState([]);
  const [load, setLoad] = useState(false);
  const id = localStorage.getItem("leaderId");

  const getPost = async () => {
    setLoad(true);
    await axios
      .get(api + "/leader/getLeaderRejectedHost/" + id, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("leaderToken")}`,
        },
      })
      .then((res) => {
        setLeaderRejectedHost(res.data.getRejectHost.reverse());
        console.log(res.data.getRejectHost);
        setLoad(false);
      })
      .catch((error) => {
        setLoad(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getPost();
  }, []);

  let navigate = useNavigate();
  const handleAction = (id) => {
    navigate(`/rejectHostDetail/${id}`);
  };

  const rejectTableRef = useRef(null);

  //-----------------pagination here👇👇👇👇-----------------------------------//
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  //-----------------pagination End here👆👆👆👆👆-----------------------------------//

  //------------------------------------Filter Acc to Date Start---------------------------------------//

  const [todayDate, setTodayDate] = useState("");
  const [chooseDate, setChooseDate] = useState("");
  const [weekDate, setWeekDate] = useState("");
  const [monthDate, setMonthDate] = useState("");

  const handleTodayData = () => {
    setTodayDate(moment().format("YYYY-MM-DD"));
    setChooseDate("");
    setWeekDate("");
    setMonthDate("");
  };

  const Date1 = moment(new Date()).format();
  let preDate = moment().subtract(7, "days").format();
  let monthhDate = moment().subtract(1, "months").format();

  const handleWeekData = () => {
    setWeekDate(preDate);
    setChooseDate("");
    setTodayDate("");
    setMonthDate("");
  };

  const handleMonthData = () => {
    setMonthDate(monthhDate);
    setChooseDate("");
    setTodayDate("");
    setWeekDate("");
  };

  //------------------------------------Filter Acc to  Date End---------------------------------------//
  return (
    <LeaderLayOut>
      <div className="header">
        <Header title="Rejected Host" />
        <div className="Dash">
          <div className="container">
            <div className="expt">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={rejectTableRef.current}
              >
                <button className="export">
                  Export
                  <div className="expicon">
                    <BiExport size={20} />
                  </div>
                </button>
              </DownloadTableExcel>
            </div>
            <List
              Today={handleTodayData}
              setDate={setChooseDate}
              setToday={setTodayDate}
              setWeek={setWeekDate}
              setMonth={setMonthDate}
              Week={handleWeekData}
              month={handleMonthData}
            />
          </div>
        </div>
      </div>
      <div className="details">
        <div className="table-container">
          <table className="content-table" ref={rejectTableRef}>
            <thead className="table-head">
              <tr>
                <th>S.No.</th>
                <th>Id</th>
                <th>User ID</th>
                <th>Name</th>
                <th>DOB</th>
                <th>Email ID</th>
                <th>Phone</th>
                <th>Rejected At</th>
              </tr>
            </thead>
            <tbody>
              {leaderRejectedHost?.length === 0 ? (
                <tr>
                  <td colSpan="8">No rejected host</td>
                </tr>
              ) : (
                leaderRejectedHost
                  ?.filter((item) => {
                    if (!todayDate && !chooseDate && !weekDate && !monthDate) {
                      return item;
                    } else if (
                      moment(item.rejectedDate)
                        .format()
                        .split("T")[0]
                        .includes(todayDate) &&
                      !chooseDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.rejectedDate)
                        .format()
                        .split("T")[0]
                        .includes(chooseDate) &&
                      !todayDate &&
                      !weekDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.rejectedDate).format() >= weekDate &&
                      moment(item.rejectedDate).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !monthDate
                    ) {
                      return item;
                    } else if (
                      moment(item.rejectedDate).format() >= monthDate &&
                      moment(item.rejectedDate).format() <= Date1 &&
                      !todayDate &&
                      !chooseDate &&
                      !weekDate
                    ) {
                      return item;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((post, index) => (
                    <tr key={post.index}>
                      <td>{index + 1 + rowsPerPage * page}</td>
                      <td>{post?._id}</td>
                      <td>{post?.userId}</td>
                      <td>{post?.FirstName + " " + post?.LastName}</td>
                      <td>{post?.dateOfBirth.substring(0, 10)}</td>
                      <td>
                        {(post?.email || "").substring(0, 5) +
                          "*".repeat(
                            Math.max(0, (post?.email || "").length - 5)
                          )}
                      </td>
                      <td>
                        {post?.number
                          ? "*".repeat(
                              Math.max(0, (post.number + "").length - 2)
                            ) + post.number.toString().slice(-2)
                          : ""}
                      </td>

                      <td>
                        {moment(post.rejectedDate).format().split("T")[0]}
                      </td>
                    </tr>
                  ))
              )}
            </tbody>
          </table>
        </div>
        <div className="paginationAlign">
          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 25, 30]}
            component="div"
            count={leaderRejectedHost.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
      </div>
      {load ? (
        <>
          <div className="spinnerBg">
            <div>
              <Spinner />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </LeaderLayOut>
  );
};

export default LeaderRejectedHost;
